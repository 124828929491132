<section id="breadcrumbs" fxLayout="row" fxLayoutAlign="start center">
    <a routerLink="/">
        <h4>Home</h4>
    </a>
    <mat-icon *ngIf="category" aria-label="Next">chevron_right</mat-icon>
    <a [href]="categoryUrl"> <h4 class="bold" *ngIf="category">{{ category }} </h4></a> 
    <mat-icon *ngIf="pageTitle" aria-label="Next">chevron_right</mat-icon>
    <h4 class="bold" *ngIf="pageTitle">{{ pageTitle }}</h4>
    <ng-container *ngIf="property && property.building_name">
        <mat-icon aria-label="Next">chevron_right</mat-icon>
        <a (click)="onOpenBuildings()">
            <h4 class="bold">{{ property.building_name }}</h4>
        </a>
    </ng-container>
    <mat-icon *ngIf="property" aria-label="Next">chevron_right</mat-icon>
    <a *ngIf="property" [routerLink]="[ 
        '/' + propertyAvailability, 
        normalizeSendParams(property.city), 
        normalizeSendParams(property.neighborhood), 
        normalizeSendParams(property.property_type) ]">
        <h4 class="bold" *ngIf="property">{{ title }}</h4>
    </a>
</section>
