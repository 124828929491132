import { isPlatformBrowser } from '@angular/common';
import { AfterViewInit, Directive, ElementRef, HostBinding, Inject, Input, PLATFORM_ID } from '@angular/core';

@Directive({
    selector: 'img[imobziLazyLoadImage]'
})
export class LazyLoadImageDirective implements AfterViewInit {
    @HostBinding('attr.src') srcAttr = null;
    @Input() src: string;

    constructor(
        private elementRef: ElementRef,
        @Inject(PLATFORM_ID) private platformId: Object
    ) { }

    ngAfterViewInit() {
        this.canLazyLoad() ? this.lazyLoadImage() : this.loadImage();
    }

    private canLazyLoad() {
        if (isPlatformBrowser(this.platformId)) {
            return window && 'IntersectionObserver' in window;
        }
    }

    private lazyLoadImage() {
        const observer = new IntersectionObserver(entries => {
            entries.forEach(({ isIntersecting }) => {
                if (isIntersecting) {
                    this.loadImage();
                    observer.unobserve(this.elementRef.nativeElement);
                }
            });
        });
        observer.observe(this.elementRef.nativeElement);
    }

    private loadImage() {
        this.srcAttr = this.src;
    }
}
