import { Component, OnInit, Input, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { CalendarsList } from 'src/app/interface/calendar';
import { PropertyCalendar } from 'src/app/interface/property';
import { getStringDate } from 'src/app/utils/utils';

@Component({
    selector: 'imobzi-calendar-mobile',
    templateUrl: './calendar-mobile.component.html',
    styleUrls: ['./calendar-mobile.component.scss']
})
export class CalendarMobileComponent implements OnInit {

    @Input() public startDateSelected: string;
    @Input() public endDateSelected: string;
    @Input() public isSmallScreen: boolean;
    @Input() public propertyCalendar: Array<PropertyCalendar>;
    @Output() private searchDates = new EventEmitter();

    calendarAvailability: string;
    unavailableDates: Array<string> = [];
    selectedDates: Array<string> = [];
    today = new Date();
    currentMonth = this.today.getMonth();
    currentYear = this.today.getFullYear();
    calendarYear = this.today.getFullYear();
    calendarsList: Array<CalendarsList> = [];
    hoverDates: Array<string> = [];

    @ViewChild('currentMonthAndYear', { static: true }) currentMonthAndYear: ElementRef;

    days = ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'];
    months = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];

    title = `Disponibilidade`;
    subtitle = ``;

    constructor() { }

    ngOnInit() {
        if (this.propertyCalendar) {
            this.getPropertyCalendar();
        }
        (this.startDateSelected) = this.selectedDates[0] = this.startDateSelected;
        (this.endDateSelected) = this.selectedDates[1] = this.endDateSelected;

        for (let i = 0; (this.selectedDates[1]) ? this.selectedDates[1] : i < 3; i++) {
            this.calendarsList.push({ id: i + 1, year: this.calendarYear, month: (this.currentMonth + i) % 12 });
            this.calendarCreate(i + 1, this.calendarYear, (this.currentMonth + i) % 12);

            if (this.selectedDates[1]) {
                const currentyDate = new Date(this.calendarYear, (this.currentMonth + i) % 12);
                const finalDate = getStringDate(this.selectedDates[1]);
                if (currentyDate >= finalDate) {
                    break;
                }
            }

            if ((this.currentMonth + i) % 12 === 11) {
                this.calendarYear = this.calendarYear + 1;
            }
        }
        setTimeout(() => {
            this.onDateHover();
        }, 10);
    }

    private getPropertyCalendar() {
        this.propertyCalendar.forEach(calendarItem => {
            const dayInitial = new Date(calendarItem.initial_date);
            const dayFinal = new Date(calendarItem.final_date);

            while (dayInitial <= dayFinal) {
                this.unavailableDates.push(dayInitial.toISOString().slice(0, 10));
                dayInitial.setDate(dayInitial.getDate() + 1);
            }
        });
    }

    getDateString(year: number, month: number, day: number) {
        const getFieldFormatted = (field: number) => {
            if (field > 9) { return field; }
            return `0${field}`;
        };
        return `${year}-${getFieldFormatted(month + 1)}-${getFieldFormatted(day)}`;
    }

    calendarCreate(id: number, year: number, month: number) {
        const firstDay = (new Date(year, month)).getDay();
        const daysInMonth = 32 - new Date(year, month, 32).getDate();

        let date = 1;
        for (let i = 0; i < 6; i++) {
            const row = document.createElement('tr');

            for (let j = 0; j < 7; j++) {

                if (i === 0 && j < firstDay) {
                    const cell = document.createElement('td');
                    const cellText = document.createTextNode('');
                    cell.appendChild(cellText);
                    row.appendChild(cell);
                } else if (date > daysInMonth) {
                    break;
                } else {
                    const cell = document.createElement('td');
                    const cellDiv = document.createElement('div');
                    const cellText = document.createTextNode(date.toString());
                    const calendarDate = this.getDateString(year, month, date);
                    const currentDate = new Date();
                    const currentDateCalendar = new Date(year, month, date + 1);

                    if (this.selectedDates.indexOf(calendarDate) !== -1) {
                        cellDiv.classList.add('uniqueMarkDate');
                    }

                    if (currentDate > currentDateCalendar) {
                        cellDiv.classList.add('desactived');
                    } else if (this.propertyCalendar) {
                        let beforeUnavailableDate;
                        let afterUnavailableDate;
                        if (this.startDateSelected) {
                            const startDate = getStringDate(this.startDateSelected);
                            this.unavailableDates.sort().forEach(unavailableDate => {
                                const firstUnavailableDate = getStringDate(unavailableDate);

                                if (firstUnavailableDate < startDate && !beforeUnavailableDate && firstUnavailableDate > currentDateCalendar) {
                                    beforeUnavailableDate = firstUnavailableDate;
                                }
                                if (firstUnavailableDate > startDate && !afterUnavailableDate) {
                                    afterUnavailableDate = firstUnavailableDate;
                                }
                            });
                        }
                        if (this.unavailableDates.indexOf(calendarDate) !== -1 || beforeUnavailableDate > currentDateCalendar || afterUnavailableDate < currentDateCalendar) {
                            cellDiv.classList.add('unavailable');
                        } else {
                            cellDiv.addEventListener('click', (event) => {
                                this.selectDate(event, year, month);
                            });
                        }
                    } else {
                        cellDiv.addEventListener('click', (event) => {
                            this.selectDate(event, year, month);
                        });
                    }

                    if (date === 1) {
                        cell.classList.add('firtDay');
                    }

                    if (date === daysInMonth) {
                        cell.classList.add('lastDay');
                    }

                    cellDiv.appendChild(cellText);
                    cell.id = `${year + '-'}${(month <= 9) ? 0 + (month + 1).toString() + '-' : (month + 1) + '-'}${(date <= 9) ? 0 + date.toString() : date}`;
                    cell.appendChild(cellDiv);
                    row.appendChild(cell);
                    date++;
                }
            }
            setTimeout(() => {
                document.getElementById(`calendar-mobile-${id}`).appendChild(row);
            }, 10);
        }
    }

    addCalendar() {
        for (let i = 0; i < 3; i++) {
            this.calendarsList.push({ id: this.calendarsList.length + 1, year: this.calendarYear, month: ((this.currentMonth + this.calendarsList.length) % 12) });
            this.calendarCreate(this.calendarsList.length, this.calendarYear, (this.currentMonth + this.calendarsList.length - 1) % 12);
            if (((this.currentMonth + this.calendarsList.length - 1) % 12) === 11) {
                this.calendarYear = this.calendarYear + 1;
            }
        }
    }

    selectDate(event, year: number, month: number) {
        const day = event.srcElement.innerHTML;
        const selectedDate = new Date(year, month, day);
        let startDate = new Date();

        document.getElementById(`${year + '-'}${(month <= 9) ? 0 + (month + 1).toString() + '-' : (month + 1) + '-'}${(day <= 9) ? 0 + day.toString() : day}`).getElementsByTagName('div')[0].classList.add('uniqueMarkDate');

        (!this.selectedDates[0])
            ? this.calendarAvailability = 'start'
            : this.calendarAvailability = 'end';

        if (this.selectedDates[0] && this.selectedDates[1]) {
            document.getElementById(`${this.selectedDates[0]}`).getElementsByTagName('div')[0].classList.remove('uniqueMarkDate');
            document.getElementById(`${this.selectedDates[1]}`).getElementsByTagName('div')[0].classList.remove('uniqueMarkDate');
            this.calendarAvailability = 'start';
            this.hoverDates.forEach(hover => {
                if (document.getElementById(hover)) {
                    document.getElementById(hover).classList.remove('hoverBackground');
                }
                if (document.getElementById(hover) && hover === this.selectedDates[0]) {
                    document.getElementById(hover).classList.remove('markDateLeftBorder');
                }
                if (document.getElementById(hover) && hover === this.selectedDates[1]) {
                    document.getElementById(hover).classList.remove('markDateRightBorder');
                }
            });
            this.hoverDates = [];
            this.selectedDates[0] = '';
            this.selectedDates[1] = '';
        }

        if (this.selectedDates[0]) {
            startDate = getStringDate(this.selectedDates[0]);

            if (this.calendarAvailability === 'end' && selectedDate < startDate) {
                document.getElementById(`${this.selectedDates[0]}`).getElementsByTagName('div')[0].classList.remove('uniqueMarkDate');
                // this.selectedDates[0] = (selectedDate.toISOString().slice(0, 10));
                this.selectedDates[1] = '';
                this.calendarAvailability = 'start';
            }
        }

        (this.calendarAvailability === 'start')
            ? this.selectedDates[0] = selectedDate.toISOString().slice(0, 10)
            : this.selectedDates[1] = selectedDate.toISOString().slice(0, 10);

        this.startDateSelected = this.selectedDates[0];
        this.endDateSelected = this.selectedDates[1];

        if (this.propertyCalendar) {
            this.updateCalendars();
        }
        setTimeout(() => {
            this.onDateHover();
        }, 10);
    }

    onDateHover() {
        this.hoverDates = [];
        if (this.selectedDates[0] && this.selectedDates[1]) {
            const dayInitial = new Date(this.selectedDates[0]);
            const dayFinal = new Date(this.selectedDates[1]);

            while (dayInitial <= dayFinal) {
                this.hoverDates.push(dayInitial.toISOString().slice(0, 10));
                dayInitial.setDate(dayInitial.getDate() + 1);
            }

            this.hoverDates.forEach(hover => {
                if (document.getElementById(hover)) {
                    document.getElementById(hover).classList.add('hoverBackground');
                }
                if (document.getElementById(hover) && hover === this.selectedDates[0]) {
                    document.getElementById(hover).classList.add('markDateLeftBorder');
                }
                if (document.getElementById(hover) && hover === this.selectedDates[1]) {
                    document.getElementById(hover).classList.add('markDateRightBorder');
                }
            });
        }
    }

    updateCalendars() {
        let calendarLength: number;
        this.calendarsList.forEach(value => {
            document.getElementById(`calendar-mobile-${value.id}`).innerHTML = '';
            calendarLength = value.id;
        });
        this.calendarYear = this.currentYear;
        for (let i = 0; calendarLength - 1 >= i; i++) {
            this.calendarCreate(i + 1, this.calendarYear, (this.currentMonth + i) % 12);

            if ((this.currentMonth + i) % 12 === 11) {
                this.calendarYear = this.calendarYear + 1;
            }
        }
    }

    onSearchDates() {
        if (this.selectedDates[0] && this.selectedDates[1]) {
            this.searchDates.emit(this.selectedDates);
        }
    }

    clearDates() {
        this.hoverDates.forEach(hover => {
            if (document.getElementById(hover)) {
                document.getElementById(hover).classList.remove('hoverBackground');
            }
            if (document.getElementById(hover) && hover === this.selectedDates[0]) {
                document.getElementById(hover).classList.remove('markDateLeftBorder');
            }
            if (document.getElementById(hover) && hover === this.selectedDates[1]) {
                document.getElementById(hover).classList.remove('markDateRightBorder');
            }
        });
        this.hoverDates = [];
        if (this.selectedDates[0]) {
            document.getElementById(`${this.selectedDates[0]}`).getElementsByTagName('div')[0].classList.remove('uniqueMarkDate');
            this.selectedDates[0] = '';
        }
        if (this.selectedDates[1]) {
            document.getElementById(`${this.selectedDates[1]}`).getElementsByTagName('div')[0].classList.remove('uniqueMarkDate');
            this.selectedDates[1] = '';
        }
        this.searchDates.emit(this.selectedDates);
    }
}
