import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Observable } from 'rxjs';
import { Buildings } from '../interface/buildings';
import { Features } from '../interface/features';
import { PropertyNeighborhood } from '../interface/neighborhood';
import {
    AdvertisePropertyType, Property, PropertyHighlightsResult, PropertySearchResult,
    PropertySearchResultFields, SearchAvailabilityTypes, VacationValues
} from '../interface/property';
import { SearchParams } from '../interface/search-params';
import { SearchTags } from '../interface/search-tags';
import { PropertyTypeHighlight } from '../interface/typehighlight';
import { AppService } from './app.service';
import { RequestService } from './request.service';
import { isPlatformBrowser } from '@angular/common';

@Injectable()
export class PropertyService {

    public page: number;
    public grid: string;

    public propertyHomeHighlights: PropertyHighlightsResult;
    public propertyHomeNeighborhood: PropertyNeighborhood[];
    public searchHomeTags: SearchTags[];

    public onDetails: boolean;
    public propertyType: PropertyTypeHighlight[];
    public propertyFields: SearchAvailabilityTypes;
    public propertyBuildings: Buildings[];

    public searchFeatures: Features[];
    public searchResult: PropertySearchResult;
    public searchResultFields: PropertySearchResultFields;
    public simulator: boolean;
    public scroll: boolean;

    public loading: boolean;

    constructor(
        private appService: AppService,
        private requestService: RequestService,
        @Inject(PLATFORM_ID) private platformId: Object,
    ) { }

    public getHighlights(showMap = false): Observable<PropertyHighlightsResult> {
        const params = (showMap) ? '' : 'show_map=false';

        if (this.propertyHomeHighlights) {
            return new Observable<PropertyHighlightsResult>(
                observer => {
                    observer.next(this.propertyHomeHighlights);
                    this.propertyHomeHighlights = undefined;
                }
            );
        } else {
            return new Observable<PropertyHighlightsResult>(observer => {
                this.requestService.get<PropertyHighlightsResult>(
                    `${this.appService.apiUrl()}/${this.appService.namespace()}/site2/properties`, params).subscribe(
                        response => observer.next(response),
                        error => observer.error('Erro: ' + error)
                    );
            });
        }
    }

    public getTypeHighlights(): Observable<PropertyTypeHighlight[]> {
        if (this.propertyType) {
            return new Observable<PropertyTypeHighlight[]>(
                observer => {
                    observer.next(this.propertyType);
                    // this.propertyType = undefined;
                }
            );
        } else {
            return new Observable<PropertyTypeHighlight[]>(observer => {
                this.requestService.get<PropertyTypeHighlight[]>(
                    `${this.appService.apiUrl()}/${this.appService.namespace()}/site2/highlights/types`).subscribe(
                        response => observer.next(response),
                        error => observer.error('Erro: ' + error)
                    );
            });
        }
    }

    public getSearchtags(): Observable<SearchTags[]> {
        if (this.searchHomeTags) {
            return new Observable<SearchTags[]>(
                observer => {
                    observer.next(this.searchHomeTags);
                    this.searchHomeTags = undefined;
                }
            );
        } else {
            return new Observable<SearchTags[]>(observer => {
                this.requestService.get<SearchTags[]>(
                    `${this.appService.apiUrl()}/${this.appService.namespace()}/site2/search-tags`).subscribe(
                        response => observer.next(response),
                        error => observer.error('Erro: ' + error)
                    );
            });
        }
    }

    public getPropertyBuildings(): Observable<Buildings[]> {
        if (this.propertyBuildings) {
            return new Observable<Buildings[]>(
                observer => {
                    observer.next(this.propertyBuildings);
                    // this.propertyBuildings = undefined;
                }
            );
        } else {
            return new Observable<Buildings[]>(observer => {
                this.requestService.get<Buildings[]>(
                    `${this.appService.apiUrl()}/${this.appService.namespace()}/site2/property-buildings`).subscribe(
                        response => observer.next(response),
                        error => observer.error('Erro: ' + error)
                    );
            });
        }
    }

    public getPropertyTypes(): Observable<AdvertisePropertyType[]> {
        return new Observable<AdvertisePropertyType[]>(observer => {
            this.requestService.get<AdvertisePropertyType[]>(
                `${this.appService.apiUrl()}/${this.appService.namespace()}/site2/properties/search/property-type`).subscribe(
                    response => observer.next(response),
                    error => observer.error('Erro: ' + error)
                );
        });
    }

    public propertyFeatures(): Observable<Features[]> {
        if (this.searchFeatures) {
            return new Observable<Features[]>(
                observer => {
                    observer.next(this.searchFeatures);
                    // this.searchFeatures = undefined;
                }
            );
        } else {
            return new Observable<Features[]>(observer => {
                this.requestService.get<Features[]>(
                    `${this.appService.apiUrl()}/${this.appService.namespace()}/site2/property-features`).subscribe(
                        features => observer.next(features),
                        error => observer.error(error),
                        () => observer.complete()
                    );
            });
        }
    }

    public updateUrl(params: SearchParams, showHistory: boolean = true, newPath?: string) {
        const paramsString: string = this.getSearchParams(params);
        if (showHistory && isPlatformBrowser(this.platformId)) {
            const path = newPath || window.location.pathname;
            history.replaceState(null, null, `${path}?${paramsString}`);
        }
    }

    public searchProperties(params: SearchParams, showMap = true): Observable<PropertySearchResult> {
        let paramsString: string = this.getSearchParams(params);
        paramsString += `&with_photos=true`;
        paramsString += `&show_map=${showMap}`;
        paramsString += `&with_listing_broker_count=true`;
        paramsString += `&search_type=${params.search_type}`;
        paramsString += `&page=${params.page}`;

        if (this.searchResult !== undefined) {
            return new Observable<PropertySearchResult>(
                observer => {
                    observer.next(this.searchResult);
                    this.searchResult = undefined;
                }
            );
        } else {
            return new Observable<PropertySearchResult>(observer => {
                if (this.loading) {
                    observer.unsubscribe();
                    this.searchProperties(params);
                    this.loading = false;
                }
                this.loading = true;
                this.requestService.get<PropertySearchResult>(
                    `${this.appService.apiUrl()}/${this.appService.namespace()}/site2/properties/search`, paramsString).subscribe(
                        propertyResult => {
                            observer.next(propertyResult);
                            this.loading = false;
                        },
                        error => observer.error(error),
                        () => observer.complete()
                    );
            });
        }
    }

    public searchFields(params: SearchParams, filtersShowTotal: boolean, firstSearch = true): Observable<PropertySearchResultFields> {
        let paramsString: string = this.getSearchParams(params);
        if (firstSearch) { paramsString += '&all_fields=true'; }
        paramsString += `&filters_show_total=${filtersShowTotal}`;
        paramsString += '&search_type=filter_without_count';

        if (this.searchResultFields !== undefined) {
            return new Observable<PropertySearchResultFields>(
                observer => {
                    observer.next(this.searchResultFields);
                    this.searchResultFields = undefined;
                }
            );
        } else {
            return new Observable<PropertySearchResultFields>(observer => {
                this.requestService.get<PropertySearchResultFields>(
                    `${this.appService.apiUrl()}/${this.appService.namespace()}/site2/properties/search/fields`, paramsString).subscribe(
                        propertyResult => observer.next(propertyResult),
                        error => observer.error(error),
                        () => observer.complete()
                    );
            });
        }
    }

    private getSearchParams(searchParams: SearchParams): string {
        let params = searchParams.order
            ? `order=${searchParams.order.value}&direction=${searchParams.order.order}`
            : `order=created_at`;
        for (const searchParam in searchParams) {
            if (searchParam !== 'order' && searchParams[searchParam]) {
                switch (searchParam) {
                    case 'availability':
                    case 'stage':
                    case 'show_map':
                    case 'nickname':
                        if (searchParams[searchParam] !== '') {
                            params += `&${searchParam}=${searchParams[searchParam]}`;
                        }
                        break;
                    case 'page':
                        if (searchParams[searchParam] !== undefined) {
                            params += `&${searchParam}=${searchParams[searchParam]}`;
                        }
                        break;
                    case 'value_to_simulator':
                        if (searchParam === 'value_to_simulator' && searchParams.value_to_simulator > 0) {
                            params += `&value_to_simulator=${searchParams.value_to_simulator}`;
                        }
                        break;
                    case 'values':
                        if (searchParam === 'values' && searchParams.values.length === 2) {
                            params += `&value_min=${searchParams.values[0]}`;
                            params += `&value_max=${searchParams.values[1]}`;
                        }
                        break;
                    case 'neighborhoods':
                        if (searchParams.neighborhoods.length > 0) {
                            searchParams.neighborhoods.forEach(neighborhood => params += `&neighborhood=${neighborhood}`);
                        }
                        break;
                    case 'cities':
                        if (searchParams.cities.length > 0) {
                            searchParams.cities.forEach(city => params += `&city=${city}`);
                        }
                        break;
                    case 'buildings':
                        if (searchParams.buildings !== '') {
                            params += `&building_name=${searchParams.buildings}`;
                        }
                        break;
                    case 'dates':
                        if (searchParam === 'dates' && searchParams.dates.length === 2) {
                            params += `&start_at=${searchParams.dates[0]}`;
                            params += `&end_at=${searchParams.dates[1]}`;
                        } else if (searchParam === 'dates' && searchParams.dates.length === 1 && searchParams.dates[0][0] !== '' && searchParams.dates[0][1] !== '') {
                            params += `&start_at=${searchParams.dates[0][0]}`;
                            params += `&end_at=${searchParams.dates[0][1]}`;
                        }
                        break;
                    case 'adults':
                    case 'children':
                        if (searchParams[searchParam] > 0) {
                            params += `&${searchParam}=${searchParams[searchParam]}`;
                        }
                        break;
                    case 'propertyTypes':
                        if (searchParams.propertyTypes.length > 0) {
                            searchParams.propertyTypes.forEach(propertyType => params += `&property_type=${propertyType}`);
                        }
                        break;
                    case 'areas':
                        if (searchParam === 'areas' && searchParams.areas.length === 2) {
                            params += `&area_min=${searchParams.areas[0]}`;
                            params += `&area_max=${searchParams.areas[1]}`;
                        }
                        break;
                    case 'bedrooms':
                        if (searchParams.bedrooms.length > 0) {
                            searchParams.bedrooms.forEach(bedroom => params += `&bedroom=${bedroom}`);
                        }
                        break;
                    case 'suites':
                        if (searchParams.suites.length > 0) {
                            searchParams.suites.forEach(suite => params += `&suite=${suite}`);
                        }
                        break;
                    case 'garages':
                        if (searchParams.garages.length > 0) {
                            searchParams.garages.forEach(garage => params += `&garage=${garage}`);
                        }
                        break;
                    case 'bedroom_gte':
                        if (searchParams.bedroom_gte > 0) {
                            params += `&bedroom_gte=${searchParams.bedroom_gte}`;
                        }
                        break;
                    case 'bathroom_gte':
                        if (searchParams.bathroom_gte > 0) {
                            params += `&bathroom_gte=${searchParams.bathroom_gte}`;
                        }
                        break;
                    case 'suite_gte':
                        if (searchParams.suite_gte > 0) {
                            params += `&suite_gte=${searchParams.suite_gte}`;
                        }
                        break;
                    case 'garage_gte':
                        if (searchParams.garage_gte === 'no_garage' || Number(searchParams.garage_gte) > 0) {
                            params += `&garage_gte=${searchParams.garage_gte}`;
                        }
                        break;
                    case 'feature':
                        if (searchParams.feature.length > 0) {
                            searchParams.feature.forEach(feature => params += `&feature=${feature}`);
                        }
                        break;
                    case 'finality':
                        if (searchParams.finality.length > 0) {
                            searchParams.finality.forEach(finality => params += `&finality=${finality}`);
                        }
                        break;
                }
            }
        }
        return params;
    }


    public searchPropertyTypes(): Observable<SearchAvailabilityTypes> {
        if (this.propertyFields) {
            return new Observable<SearchAvailabilityTypes>(
                observer => {
                    observer.next(this.propertyFields);
                    this.propertyFields = undefined;
                }
            );
        } else {
            return new Observable<SearchAvailabilityTypes>(observer => {
                this.requestService.get<SearchAvailabilityTypes>(
                    `${this.appService.apiUrl()}/${this.appService.namespace()}/site2/properties/search/fields`, 'search_type=property_types&with_finality=true&with_neighborhoods=true').subscribe(
                        propertyResult => observer.next(propertyResult),
                        error => observer.error(error),
                        () => observer.complete()
                    );
            });
        }
    }

    public getPropertyNeighborhoods(): Observable<PropertyNeighborhood[]> {
        const propertiesCount = '&with_properties_count=true';

        if (this.propertyHomeNeighborhood) {
            return new Observable<PropertyNeighborhood[]>(
                observer => {
                    observer.next(this.propertyHomeNeighborhood);
                }
            );
        } else {
            return new Observable<PropertyNeighborhood[]>(observer => {
                this.requestService.get<PropertyNeighborhood[]>(
                    `${this.appService.apiUrl()}/${this.appService.namespace()}/site2/property-neighborhoods`, propertiesCount).subscribe(
                        neighborhoods => observer.next(neighborhoods),
                        error => observer.error(error),
                        () => observer.complete()
                    );
            });
        }
    }

    public getProperty(propertyCode: string): Observable<Property> {
        const paramsCount = `&listing_broker_properties_count=true`;
        return new Observable<Property>(observer => {
            this.requestService.get<Property>(
                `${this.appService.apiUrl()}/${this.appService.namespace()}/site2/properties/${propertyCode}`, paramsCount).subscribe(
                    property => observer.next(property),
                    error => observer.error(error),
                    () => observer.complete()
                );
        });
    }

    public getPropertyByUrl(propertyUrl: string): Observable<Property> {
        const paramsCount = `&listing_broker_properties_count=true`;
        return new Observable<Property>(observer => {
            this.requestService.get<Property>(
                `${this.appService.apiUrl()}/${this.appService.namespace()}/site2/properties`, `url=${propertyUrl}${paramsCount}`).subscribe(
                    property => observer.next(property),
                    error => observer.error(error),
                    () => observer.complete()
                );
        });
    }

    public getPropertiesRelated(propertyCode: string): Observable<Array<Property>> {
        return new Observable<Array<Property>>(observer => {
            this.requestService.get<Array<Property>>(
                `${this.appService.apiUrl()}/${this.appService.namespace()}/site2/properties/${propertyCode}/related`).subscribe(
                    properties => observer.next(properties),
                    error => observer.error(error),
                    () => observer.complete()
                );
        });
    }

    public updateSiteCount(propertyCode: string) {
        this.requestService.get(`${this.appService.apiUrl()}/${this.appService.namespace()}/site/properties/${propertyCode}/views`).subscribe(
            () => { }
        );
    }

    public getVacationValues(startAt: string, endAt: string, propertyID: string): Observable<VacationValues> {
        const params = `start_at=${startAt}&end_at=${endAt}&property_id=${propertyID}`;
        return new Observable<VacationValues>(observer => {
            this.requestService.get<VacationValues>(`${this.appService.apiUrl()}/${this.appService.namespace()}/site/vacation-values-calculate`, params).subscribe(
                response => observer.next(response),
                error => observer.error(error),
                () => observer.complete()
            );
        });
    }
}
