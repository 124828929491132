<ng-container [ngSwitch]="buttonHome.button_type">
    <ng-template *ngSwitchCase="'external_link'" [ngTemplateOutlet]="external_link"></ng-template>
    <ng-template *ngSwitchCase="'linked_content'" [ngTemplateOutlet]="linked_content"></ng-template>
    <ng-template *ngSwitchCase="'property_filter'" [ngTemplateOutlet]="property_filter"></ng-template>
</ng-container>

<ng-template #external_link>
    <a class="bold" [href]="buttonHome.url" target="_blank" [attr.aria-label]="buttonHome.label" rel="noopener">
        {{ buttonHome.label | uppercase }}
    </a>
</ng-template>
<ng-template #linked_content>
    <a class="bold" [routerLink]="buttonHome.url" [attr.aria-label]="buttonHome.label" rel="noopener">
        {{ buttonHome.label | uppercase }}
    </a>
</ng-template>
<ng-template #property_filter>
    <a class="bold" [routerLink]="buttonHome.url" [queryParams]="buttonHome.query_params" [attr.aria-label]="buttonHome.label" rel="noopener">
        {{ buttonHome.label }}
    </a>
</ng-template>