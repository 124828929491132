import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'imobzi-success-form',
  templateUrl: './success-form.component.html',
  styleUrls: ['./success-form.component.scss']
})
export class SuccessFormComponent {

  @Input() msgSuccessTitle: string;
  @Input() msgSuccessSubtitle: string;
  @Input() btnSuccessActive: boolean;
  @Input() btnSuccessTitle: string;
  @Output() btnAction = new EventEmitter();

  btnChanged(value: boolean) {
    this.btnAction.emit(value);
  }
}
