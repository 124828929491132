import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { isPlatformBrowser } from '@angular/common';
import { Privacy } from '../interface/privacy';


@Injectable({
    providedIn: 'root'
})
export class PrivacyService {

    browserName: string;
    plataform: string;
    cookieEnable: boolean;
    ipAddress: string;
    myDate = new Date();

    public privacyData: Array<Privacy> = [];
    public privacy: Privacy;
    private storage: Storage;

    constructor(
        public http: HttpClient,
        @Inject(PLATFORM_ID) private platformId: Object
    ) {
        if (isPlatformBrowser(this.platformId)) {
            this.storage = window.localStorage;
        }
    }

    setItem(key: string, value: any) {
        if (key === 'Imobzi' || key === 'Sent') {
            localStorage.setItem(key, value);
        } else {
            this.getItem(key);
        }
    }

    getItem(key: string): any {
        if (key === 'Imobzi') {
            return JSON.parse(this.storage.getItem(key));
        } else {
            return JSON.parse(this.storage.getItem(key));
        }
    }
}
