import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { PropertyCalendar } from 'src/app/interface/property';
import { CalendarAvailabilityDialogComponent } from './dialog/calendar-availability-dialog.component';

@Component({
    selector: 'imobzi-calendar-availability',
    templateUrl: './calendar-availability.component.html',
    styleUrls: ['./calendar-availability.component.scss']
})

export class CalendarAvailabilityComponent implements OnInit, OnDestroy {
    @Input() public startDateSelected: string;
    @Input() public endDateSelected: string;
    @Input() public calendarAvailability: string;
    @Input() public singleCalendar: boolean;
    @Input() public propertyCalendar: Array<PropertyCalendar>;
    @Input() public showBorder = false;
    @Output() private closeDialog = new EventEmitter();
    @Output() private searchDates = new EventEmitter();

    public isSmallScreen: boolean;
    public openDialog: boolean;
    private breakpoint: any;
    private dialogRef: MatDialogRef<CalendarAvailabilityDialogComponent>;

    constructor(
        private dialog: MatDialog,
        private breakpointObserver: BreakpointObserver
    ) { }

    ngOnInit() {
        this.openDialog = false;
        this.getBreakpoint();
    }

    ngOnDestroy() {
        this.breakpoint.unsubscribe();
    }

    private getBreakpoint() {
        this.breakpoint = this.breakpointObserver.observe([
            Breakpoints.Handset,
            Breakpoints.Small,
            Breakpoints.XSmall
        ]).subscribe(result => {
            this.isSmallScreen = result.matches;

            if (this.isSmallScreen && !this.openDialog) {
                this.openRequestPropertyDialog();
            }

            if (!this.isSmallScreen && this.openDialog) {
                this.dialogRef.close();
            }
        });
    }

    private openRequestPropertyDialog(): void {
        this.dialogRef = this.dialog.open(CalendarAvailabilityDialogComponent, {
            panelClass: 'custom-dialog-container',
            data: {
                startDateSelected: this.startDateSelected,
                endDateSelected: this.endDateSelected,
                propertyCalendar: this.propertyCalendar
            }
        });

        this.dialogRef.componentInstance.searchDates.subscribe((date) => {
            this.onSearchDates(date);
        });

        this.dialogRef.afterOpened().subscribe(() => {
            this.openDialog = true;
        });

        this.dialogRef.afterClosed().subscribe(() => {
            this.closeDialog.emit();
            this.openDialog = false;
        });
    }

    public onSearchDates = (date: Array<string>) => this.searchDates.emit(date);

}
