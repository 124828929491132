
<ng-container *ngIf="showDateVisit">
    <imobzi-date-visit
        *ngIf="!nextButton || isSmallScreen"
        (day)="onDayChange($event)"
        (schedule)="onScheduleChange($event)"
        (nextButton)="onNextChange($event)">
    </imobzi-date-visit>
</ng-container>

<imobzi-step-form
    *ngIf="formType === 'reserve' || nextButton || isSmallScreen"
    [formContent]="formContent"
    [buttonTitle]="buttonTitle"
    [property]="property"
    [dates]="dates"
    [class.reserve-form]="!showDateVisit"
    (formSubmit)="onFormSubmit($event)"
    (exportDates)="calculateIntervalDates($event)">
</imobzi-step-form>
