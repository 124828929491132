import { Component, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { isPlatformBrowser } from '@angular/common';
import { CookieService } from 'ngx-cookie-service';
import { Privacy } from 'src/app/interface/privacy';
import { PrivacyService } from 'src/app/service/privacy.service';

@Component({
    selector: 'imobzi-privacy-policy',
    templateUrl: './privacy-policy.component.html',
    styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent implements OnInit, OnDestroy {

    public privacyData: Privacy;
    myDate = new Date();
    ipAddress: string;
    privacityTitle: string;
    privacityLink: string;
    showBanner: string;
    hideBanner: boolean;
    browserName: string;
    plataform: string;
    cookieEnable: boolean;
    privacyService: PrivacyService;
    open: any;

    constructor(
        private http: HttpClient,
        private cookieService: CookieService,
        privacyService: PrivacyService,
        @Inject(PLATFORM_ID) private platformId: Object
    ) {
        this.privacyService = privacyService;
    }

    ngOnInit() {
        this.privacityTitle = `Ao prosseguir, você concorda com a nossa <a id="innerHtmlClick" class="learn-more">Política de Privacidade</a>
      e com o uso dos cookies que nos permitem melhorar nossos serviços e recomendar conteúdos do seu interesse. `;

        this.privacityLink = '/politica-de-privacidade';
        if (isPlatformBrowser(this.platformId)) {
            this.getCookie();
            this.getBrowserInformations();
            this.setPrivacyData();
            this.getIPAddress();
        }
    }

    ngOnDestroy(): void {
        this.hideBanner = false;
    }

    public showPrivacy() {
        window.open(`${this.privacityLink}`);
    }

    public setCookie() {
        this.cookieService.set('Imobzi-cookies', 'dismiss');
        this.hideBanner = true;
        this.privacyService.setItem('Imobzi', JSON.stringify(this.privacyData));
    }

    public getCookie() {
        this.showBanner = this.cookieService.get('Imobzi-cookies');
        (this.showBanner)
            ? this.hideBanner = true
            : this.hideBanner = false;
    }

    public setPrivacyData() {
        this.privacyData = {
            browser: `${this.browserName}`,
            cookieEnabled: true,
            date: `${this.myDate.toISOString()}`,
            ipAddress: `${this.ipAddress}`,
            operacional_system: `${this.plataform}`,
        };
    }


    private getBrowserInformations() {
        this.browserName = navigator.vendor;
        this.plataform = navigator.platform;
        this.cookieEnable = navigator.cookieEnabled;
        this.ipAddress = this.ipAddress;
    }

    private getIPAddress() {
        this.http.get('https://api.ipify.org/?format=json').subscribe(value => {
            return this.privacyData.ipAddress = JSON.stringify(value);
        });
    }

}
