<ng-container *ngIf="parameters">
    <ul class="social-container" fxLayout="row" fxLayoutAlign="start center" fxLayoutAlign.lt-md="start start">
        <li class="social-content" *ngFor="let socialNetwork of parameters.social_networks">
            <a class="social-link" [class.svg-object-link]="['x', 'threads'].includes(socialNetwork.name.toLowerCase())"  [href]="getSocialNetworkUrl(socialNetwork)" target="_blank" rel="noopener"
                title="{{ socialNetwork.name }}" aria-label="Rede Sociais">
                <ng-container [ngTemplateOutlet]="icon" [ngTemplateOutletContext]="{ socialNetwork: socialNetwork }"></ng-container>
            </a>
        </li>
    </ul>
</ng-container>

<ng-template #icon let-socialNetwork="socialNetwork">
    <ng-container [ngSwitch]="socialNetwork.name | lowercase">
        <ng-container *ngSwitchCase="'x'">
            <fa-icon class="svg-object" [imobziSvgObjectLink]="layoutBucketPath + '/icone-x-twitter.svg'" type="image/svg+xml"></fa-icon>
        </ng-container>
        <ng-container *ngSwitchCase="'threads'">
            <fa-icon class="svg-object" [imobziSvgObjectLink]="layoutBucketPath + '/icone-threads.svg'" type="image/svg+xml"></fa-icon>
        </ng-container>
        <ng-container *ngSwitchDefault>
            <fa-icon [icon]="['fab', socialNetwork.name.replace('Facebook', 'Facebook-f') | lowercase ]"></fa-icon>
        </ng-container>
    </ng-container>
</ng-template>
