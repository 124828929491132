import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSidenav } from '@angular/material/sidenav';
import { MenuDialogTypes, MenusResponse } from 'src/app/interface/menu';
import { Parameters } from 'src/app/interface/parameters';
import { FinancingSimulatorDialogComponent } from '../../modal/financing-simulator/financing-simulator-dialog.component';
import { onPhoneClicked } from 'src/app/utils/utils';
import { ContactRealEstateDialogComponent } from '../../modal/contact-real-estate/contact-real-estate-dialog.component';
import { Router } from '@angular/router';
import { HttpParams } from '@angular/common/http';

@Component({
    selector: 'imobzi-menu-list',
    templateUrl: './menu-list.component.html',
    styleUrls: ['./menu-list.component.scss']
})
export class MenuListComponent implements OnInit {

    @Input() sidenav: MatSidenav;
    @Input() parameters: Parameters;
    @Input() menuType: 'main-menu' | 'footer-menu' = 'main-menu';
    public menuItems: MenusResponse = {} as MenusResponse;

    constructor(
        private dialog: MatDialog,
        private router: Router
    ) { }

    ngOnInit() {
        this.setUpMenus();
    }

    setUpMenus() {
        this.menuItems = this.parameters.site_menus;
        this.menuItems[this.menuType].sort((a, b) => a.position - b.position);
        this.menuItems[this.menuType].forEach(menu => {
            menu.submenus.sort((a, b) => a.position - b.position);
        });
    }

    public openDialog(dialogType: MenuDialogTypes): void {
        switch (dialogType) {
            case 'simulator':
                this.openSimulatorDialog();
                break;
            case 'contact':
                this.openContactDialog();
                break;
            case 'whatsapp':
                this.openWhatsappDialog();
                break;
        }
    }

    private openSimulatorDialog() {
        this.dialog.open(FinancingSimulatorDialogComponent, {
            panelClass: 'custom-dialog-container',
            data: { parameters: this.parameters }
        });
    }

    private openContactDialog() {
        this.dialog.open(ContactRealEstateDialogComponent, {
            panelClass: 'custom-dialog-container'
        });
    }

    private openWhatsappDialog() {
        const whatsapp = this.parameters.phone.find(phone => phone.type.toLowerCase() === 'whatsapp');
        if (whatsapp) {
            onPhoneClicked(whatsapp, this.dialog, true);
        }
    }

    public checkIfUrlFilterIsActive(url: string, query_params: { [key: string]: any }): boolean {
        const httpParams = new HttpParams({ fromObject: query_params });
        const httpURLParams = new HttpParams({ fromString: this.router.url.split('buscar?')[1] });
        return this.router.isActive(url + '?' + httpParams.toString(), true) || httpURLParams.toString() === httpParams.toString();
    }

    public close() {
        if (this.sidenav) {
            this.sidenav.toggle();
        }
    }
}
