<mat-nav-list>
    <ul id="main-menu">

        <li *ngFor="let menu of menuItems[menuType]">
            <h2 class="item_menu">{{ menu.menu_name | uppercase}}</h2>

            <ul class="submenusItems" *ngIf="menu.submenus">
                <ng-container *ngFor="let submenu of menu.submenus">
                    <ng-container [ngSwitch]="submenu.menu_type">
                        <li
                            *ngSwitchCase="'property_filter'"
                            class="submenu"
                            [class.active]="checkIfUrlFilterIsActive(submenu.url, submenu.query_params)"
                            [title]="submenu.group_item"
                            [attr.aria-label]="'link para filtro de imóveis - ' + submenu.group_item">
                            <a
                                [routerLink]="submenu.url"
                                [queryParams]="submenu.query_params">
                                {{ submenu.group_item }}
                            </a>
                        </li>
                        <li
                            *ngSwitchCase="'external_link'"
                            [title]="submenu.group_item"
                            [attr.aria-label]="'link externo'"
                            class="submenu">
                            <a
                                [href]="submenu.url"
                                target="_blank"
                                [attr.aria-label]="submenu.group_item">
                                {{ submenu.group_item }}
                            </a>
                        </li>
                        <li
                            *ngSwitchCase="'linked_content'"
                            [title]="submenu.group_item"
                            [routerLinkActive]="'active'"
                            [attr.aria-label]="'link de conteúdo - ' + submenu.group_item"
                            class="submenu">
                            <a
                                [routerLink]="submenu.url">
                                {{ submenu.group_item }}
                            </a>
                        </li>
                        <li
                            *ngSwitchCase="'internal_link'"
                            [title]="submenu.group_item"
                            [routerLinkActive]="'active'"
                            [attr.aria-label]="'link interno - ' + submenu.group_item"
                            class="submenu">
                            <a
                                [routerLink]="submenu.url">
                                {{ submenu.group_item }}
                            </a>
                        </li>
                        <li
                            *ngSwitchCase="'modal'"
                            [title]="submenu.group_item"
                            [attr.aria-label]="'Abrir modal - ' + submenu.group_item"
                            (click)="openDialog(submenu.modal)"
                            class="submenu">
                            <a>
                                {{ submenu.group_item }}
                            </a>
                        </li>
                    </ng-container>
                </ng-container>
            </ul>
        </li>
    </ul>
</mat-nav-list>
