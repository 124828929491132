import { Component } from '@angular/core';

@Component({
  selector: 'imobzi-photo-error',
  template: '<img src="https://storage.googleapis.com/imobzi/image/layout13/erro-img-building.png">'})
export class PhotoErrorComponent {

  constructor() { }

}
