import { Component, Input } from '@angular/core';
import { SiteHomeButton } from 'src/app/interface/parameters';

@Component({
    selector: 'imobzi-custom-button',
    templateUrl: './custom-button.component.html',
    styleUrls: ['./custom-button.component.scss']
})
export class CustomButtonComponent {

    @Input() buttonHome: SiteHomeButton;

    constructor() { }

}
