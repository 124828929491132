import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Parameters } from 'src/app/interface/parameters';

@Component({
    selector: 'imobzi-contact',
    templateUrl: './contact.component.html',
    styleUrls: ['./contact.component.scss']
})
export class ContactComponent {

    @Input() parameters: Parameters;

    constructor() { }

}
