import { Component } from '@angular/core';

@Component({
    selector: 'imobzi-popover',
    templateUrl: './popover.component.html',
    styleUrls: ['./popover.component.scss']
})
export class PopoverComponent {

    constructor() { }

}
