import { Component, OnInit, Input, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { PropertyCalendar } from 'src/app/interface/property';
import { getStringDate } from 'src/app/utils/utils';

@Component({
    selector: 'imobzi-calendar',
    templateUrl: './calendar.component.html',
    styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent implements OnInit {

    @Input() public startDateSelected: string;
    @Input() public endDateSelected: string;
    @Input() public calendarAvailability: string;
    @Input() public singleCalendar: boolean;
    @Input() public propertyCalendar: Array<PropertyCalendar>;
    @Input() public showBorder = false;
    @Output() private searchDates = new EventEmitter();

    selectedDates: Array<string> = [];
    unavailableDates: Array<string> = [];
    today = new Date();
    currentMonth = this.today.getMonth();
    currentYear = this.today.getFullYear();
    hoverDates: Array<string> = [];

    nextYear = (this.currentMonth === 11) ? this.currentYear + 1 : this.currentYear;
    nextMonth = (this.currentMonth === 11) ? 0 : this.currentMonth + 1;

    @ViewChild('nextMonthAndYear', { static: true }) nextMonthAndYear: ElementRef;
    @ViewChild('nextCalendarBody', { static: true }) nextCalendarBody: ElementRef;
    @ViewChild('currentMonthAndYear', { static: true }) currentMonthAndYear: ElementRef;
    @ViewChild('currentCalendarBody', { static: true }) currentCalendarBody: ElementRef;

    days = ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'];
    months = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];

    title = `Disponibilidade`;
    subtitle = ``;

    constructor() { }

    ngOnInit() {
        if (this.propertyCalendar) {
            this.getPropertyCalendar();
        }

        (this.startDateSelected) = this.selectedDates[0] = this.startDateSelected;
        (this.endDateSelected) = this.selectedDates[1] = this.endDateSelected;
        if (this.startDateSelected) {
            this.currentYear = parseInt(this.startDateSelected.split('-')[0], 10);
            this.currentMonth = (parseInt(this.startDateSelected.split('-')[1], 10) - 1);
            this.nextYear = parseInt(this.startDateSelected.split('-')[0], 10) + ((this.currentMonth === 11) ? 1 : 0);
            this.nextMonth = parseInt(this.startDateSelected.split('-')[1], 10) % 12;
        }
        this.currentCalendar(this.currentMonth, this.currentYear);
        if (!this.singleCalendar) {
            this.nextCalendar(this.nextMonth, this.nextYear);
        }

        if (this.startDateSelected && this.endDateSelected) {
            this.onDateHover();
        }
    }

    private getPropertyCalendar() {
        this.propertyCalendar.forEach(calendarItem => {
            const dayInitial = new Date(calendarItem.initial_date);
            const dayFinal = new Date(calendarItem.final_date);

            while (dayInitial <= dayFinal) {
                this.unavailableDates.push(dayInitial.toISOString().slice(0, 10));
                dayInitial.setDate(dayInitial.getDate() + 1);
            }
        });
    }

    buttonActions(value: string) {
        (value === 'previous') ? this.previous() : this.next();
    }

    getDateString(year: number, month: number, day: number) {
        const getFieldFormatted = (field: number) => {
            if (field > 9) { return field; }
            return `0${field}`;
        };
        return `${year}-${getFieldFormatted(month + 1)}-${getFieldFormatted(day)}`;
    }

    previous() {
        this.currentYear = (this.currentMonth === 0) ? this.currentYear - 1 : this.currentYear;
        this.currentMonth = (this.currentMonth === 0) ? 11 : this.currentMonth - 1;
        this.nextYear = (this.nextMonth === 0) ? this.nextYear - 1 : this.nextYear;
        this.nextMonth = (this.nextMonth === 0) ? 11 : this.nextMonth - 1;
        this.currentCalendar(this.currentMonth, this.currentYear);
        if (!this.singleCalendar) {
            this.nextCalendar(this.nextMonth, this.nextYear);
        }
    }

    next() {
        this.currentYear = (this.currentMonth === 11) ? this.currentYear + 1 : this.currentYear;
        this.currentMonth = (this.currentMonth + 1) % 12;
        this.nextYear = (this.nextMonth === 11) ? this.nextYear + 1 : this.nextYear;
        this.nextMonth = (this.nextMonth + 1) % 12;
        this.currentCalendar(this.currentMonth, this.currentYear);
        if (!this.singleCalendar) {
            this.nextCalendar(this.nextMonth, this.nextYear);
        }
    }

    currentCalendar(month: number, year: number) {

        const firstDay = (new Date(year, month)).getDay();
        const daysInMonth = 32 - new Date(year, month, 32).getDate();

        this.currentCalendarBody.nativeElement.innerHTML = '';
        this.currentMonthAndYear.nativeElement.innerHTML = this.months[month] + ' ' + year;

        let date = 1;
        for (let i = 0; i < 6; i++) {
            const row = document.createElement('tr');

            for (let j = 0; j < 7; j++) {

                if (i === 0 && j < firstDay) {
                    const cell = document.createElement('td');
                    const cellText = document.createTextNode('');
                    cell.appendChild(cellText);
                    row.appendChild(cell);
                } else if (date > daysInMonth) {
                    break;
                } else {
                    const cell = document.createElement('td');
                    const cellDiv = document.createElement('div');
                    const cellText = document.createTextNode(date.toString());
                    const calendarDate = this.getDateString(this.currentYear, this.currentMonth, date);
                    const currentDate = new Date();
                    const currentDateCalendar = new Date(this.currentYear, this.currentMonth, date + 1);

                    if (this.selectedDates.indexOf(calendarDate) !== -1) {
                        cellDiv.classList.add('uniqueMarkDate');
                    }

                    if (currentDate > currentDateCalendar) {
                        cellDiv.classList.add('desactived');
                    } else if (this.propertyCalendar) {
                        let beforeUnavailableDate;
                        let afterUnavailableDate;
                        if (this.startDateSelected) {
                            const startDate = getStringDate(this.startDateSelected);
                            this.unavailableDates.sort().forEach(unavailableDate => {
                                const firstUnavailableDate = getStringDate(unavailableDate);

                                if (firstUnavailableDate < startDate && !beforeUnavailableDate && firstUnavailableDate > currentDateCalendar) {
                                    beforeUnavailableDate = firstUnavailableDate;
                                }
                                if (firstUnavailableDate > startDate && !afterUnavailableDate) {
                                    afterUnavailableDate = firstUnavailableDate;
                                }
                            });
                        }
                        if (this.unavailableDates.indexOf(calendarDate) !== -1) {
                            cellDiv.classList.add('unavailable');
                        } else {
                            cellDiv.addEventListener('click', (event) => {
                                this.selectDate(event, this.currentYear, this.currentMonth);
                            });
                            cellDiv.addEventListener('mouseover', (event) => {
                                this.onDateHover(event, this.currentYear, this.currentMonth);
                            });
                            cellDiv.addEventListener('mouseout', () => {
                                this.clearDatesHover();
                            });
                        }
                    } else {
                        cellDiv.addEventListener('click', (event) => {
                            this.selectDate(event, this.currentYear, this.currentMonth);
                        });
                        cellDiv.addEventListener('mouseover', (event) => {
                            this.onDateHover(event, this.currentYear, this.currentMonth);
                        });
                        cellDiv.addEventListener('mouseout', () => {
                            this.clearDatesHover();
                        });
                    }

                    cellDiv.appendChild(cellText);
                    cell.id = `${year + '-'}${(month <= 9) ? 0 + (month + 1).toString() + '-' : (month + 1) + '-'}${(date <= 9) ? 0 + date.toString() : date}`;
                    cell.appendChild(cellDiv);
                    row.appendChild(cell);
                    date++;
                }
            }
            this.currentCalendarBody.nativeElement.appendChild(row);
        }
        if (this.startDateSelected && this.endDateSelected) {
            this.onDateHover();
        }
    }

    nextCalendar(month: number, year: number) {

        const firstDay = (new Date(year, month)).getDay();
        const daysInMonth = 32 - new Date(year, month, 32).getDate();

        this.nextCalendarBody.nativeElement.innerHTML = '';
        this.nextMonthAndYear.nativeElement.innerHTML = this.months[month] + ' ' + year;

        let date = 1;
        for (let i = 0; i < 6; i++) {
            const row = document.createElement('tr');

            for (let j = 0; j < 7; j++) {

                if (i === 0 && j < firstDay) {
                    const cell = document.createElement('td');
                    const cellText = document.createTextNode('');
                    cell.appendChild(cellText);
                    row.appendChild(cell);
                } else if (date > daysInMonth) {
                    break;
                } else {
                    const cell = document.createElement('td');
                    const cellDiv = document.createElement('div');
                    const cellText = document.createTextNode(date.toString());
                    const calendarDate = this.getDateString(this.nextYear, this.nextMonth, date);
                    const currentDate = new Date();
                    const nextDateCalendar = new Date(this.nextYear, this.nextMonth, date + 1);

                    if (this.selectedDates.indexOf(calendarDate) !== -1) {
                        cellDiv.classList.add('uniqueMarkDate');
                    }

                    if (currentDate > nextDateCalendar) {
                        cellDiv.classList.add('desactived');
                    } else if (this.propertyCalendar) {
                        let beforeUnavailableDate;
                        let afterUnavailableDate;
                        if (this.startDateSelected) {
                            const startDate = getStringDate(this.startDateSelected);
                            this.unavailableDates.sort().forEach(unavailableDate => {
                                const firstUnavailableDate = getStringDate(unavailableDate);

                                if (firstUnavailableDate < startDate && !beforeUnavailableDate && firstUnavailableDate > nextDateCalendar) {
                                    beforeUnavailableDate = firstUnavailableDate;
                                }
                                if (firstUnavailableDate > startDate && !afterUnavailableDate) {
                                    afterUnavailableDate = firstUnavailableDate;
                                }
                            });
                        }
                        if (this.unavailableDates.indexOf(calendarDate) !== -1) {
                            cellDiv.classList.add('unavailable');
                        } else {
                            cellDiv.addEventListener('click', (event) => {
                                this.selectDate(event, this.nextYear, this.nextMonth);
                            });
                            cellDiv.addEventListener('mouseover', (event) => {
                                this.onDateHover(event, this.nextYear, this.nextMonth);
                            });
                            cellDiv.addEventListener('mouseout', () => {
                                this.clearDatesHover();
                            });
                        }
                    } else {
                        cellDiv.addEventListener('click', (event) => {
                            this.selectDate(event, this.nextYear, this.nextMonth);
                        });
                        cellDiv.addEventListener('mouseover', (event) => {
                            this.onDateHover(event, this.nextYear, this.nextMonth);
                        });
                        cellDiv.addEventListener('mouseout', () => {
                            this.clearDatesHover();
                        });
                    }

                    cellDiv.appendChild(cellText);
                    cell.id = `${year + '-'}${(month <= 9) ? 0 + (month + 1).toString() + '-' : (month + 1) + '-'}${(date <= 9) ? 0 + date.toString() : date}`;
                    cell.appendChild(cellDiv);
                    row.appendChild(cell);
                    date++;
                }
            }
            this.nextCalendarBody.nativeElement.appendChild(row);
        }
        if (this.startDateSelected && this.endDateSelected) {
            this.onDateHover();
        }
    }

    onDateHover(event?, year?: number, month?: number) {
        if (this.selectedDates[0]) {
            const day = (event) ? event.srcElement.innerHTML : '';
            const dayInitial = new Date(this.selectedDates[0]);
            let dayFinal: Date;

            (this.selectedDates[1])
                ? dayFinal = new Date(this.selectedDates[1])
                : dayFinal = new Date(year, month, day);

            while (dayInitial <= dayFinal) {
                this.hoverDates.push(dayInitial.toISOString().slice(0, 10));
                dayInitial.setDate(dayInitial.getDate() + 1);
            }

            this.hoverDates.forEach(hover => {
                if (document.getElementById(hover)) {
                    document.getElementById(hover).classList.add('hoverBackground');
                }
                if (document.getElementById(hover) && hover === this.selectedDates[0]) {
                    document.getElementById(hover).classList.add('markDateLeftBorder');
                }
                if (document.getElementById(hover) && hover === this.selectedDates[1]) {
                    document.getElementById(hover).classList.add('markDateRightBorder');
                }
            });
        }
    }

    clearDatesHover() {
        if (this.selectedDates[0] && !this.selectedDates[1]) {
            this.hoverDates.forEach(hover => {
                if (document.getElementById(hover)) {
                    document.getElementById(hover).classList.remove('hoverBackground');
                    document.getElementById(hover).classList.remove('markDateLeftBorder');
                    document.getElementById(hover).classList.remove('markDateRightBorder');
                }
            });
            this.hoverDates = [];
        }
    }

    checkSelectedDateIsUnavailable(startDate: string, endDate: string) {
        const selectedStartDate = new Date(startDate);
        const selectedEndDate = new Date(endDate);

        for (const unavailableDate of this.unavailableDates) {
            const date = new Date(unavailableDate);
            if (selectedStartDate <= date && selectedEndDate >= date) {
                return true;
            }
        }
        return false;
    }

    selectDate(event, year: number, month: number) {
        const day = event.srcElement.innerHTML;
        const selectedDate = new Date(year, month, day);
        let startDate = new Date();
        let endDate = new Date();

        if (this.calendarAvailability === 'start') {
            this.selectedDates[0] = selectedDate.toISOString().slice(0, 10);
        }
        else if (this.selectedDates[0] && this.selectedDates[0] !== selectedDate.toISOString().slice(0, 10)) {
            this.selectedDates[1] = selectedDate.toISOString().slice(0, 10);
        }

        if (this.startDateSelected) {
            startDate = getStringDate(this.startDateSelected);

            if (this.checkSelectedDateIsUnavailable(this.selectedDates[0], this.selectedDates[1])) {
                this.selectedDates[0] = selectedDate.toISOString().slice(0, 10);
                delete this.selectedDates[1];
            }
            if (this.endDateSelected) {
                endDate = getStringDate(this.endDateSelected);
                if (this.calendarAvailability === 'start' && selectedDate > endDate) {
                    this.selectedDates[1] = '';
                }
            }

            if (this.calendarAvailability === 'end' && selectedDate < startDate) {
                this.selectedDates[0] = (selectedDate.toISOString().slice(0, 10));
                this.selectedDates[1] = '';
            }
        }

        if (this.selectedDates[1] && !this.selectedDates[0]) {
            this.selectedDates[0] = (selectedDate.toISOString().slice(0, 10));
            this.selectedDates[1] = '';
        }

        if (this.selectedDates[0] && this.selectedDates[1] && this.selectedDates[0] !== this.selectedDates[1]) {
            this.onDateHover(event, this.nextYear, this.nextMonth);
        }
        this.currentCalendar(this.currentMonth, this.currentYear);
        if (!this.singleCalendar) {
            this.nextCalendar(this.nextMonth, this.nextYear);
        }
        this.searchDates.emit(this.selectedDates);
    }

    clearDates() {
        this.selectedDates[0] = '';
        this.selectedDates[1] = '';
        this.calendarAvailability = 'start';
        this.currentCalendar(this.currentMonth, this.currentYear);
        this.searchDates.emit(this.selectedDates);
    }
}
