import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'search',
    pure: false
})
export class SearchPipe implements PipeTransform {

    transform(value: any): Array<string> {
        return value.replace('_to_', ' a ');
    }

}

@Pipe({
    name: 'decodeURIComponent',
})
export class DecodeURIComponentPipe implements PipeTransform {
    transform(input: any) {
        if (typeof input !== 'string') {
            return input;
        }

        return decodeURIComponent(input);
    }
}
