<div id="step-form" class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
    fxLayout="column" fxLayoutAlign="center center">

    <ng-container *ngFor="let form of masterForm; let index = index; trackBy: trackByFn">
        <form
            class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 grid-12"
            *ngIf="index === activeStepIndex || masterForm.length === 1"
            [formGroup]="masterForm[index]"
            [class]="'step-' + activeStepIndex">

            <div *ngFor="let field of formFields[index]; trackBy: trackByFn"
                [ngSwitch]="currentFormContent[index][field].type"
                class="relative {{currentFormContent[index][field].class}}">

                <p class="validation-error"
                    *ngIf="(masterForm[index].get(field).dirty || masterForm[index].get(field).touched) && masterForm[index].get(field).invalid && masterForm[index].get(field).errors">
                    {{ getValidationMessage(index, field) }}
                </p>

                <ng-container *ngSwitchCase="'textarea'">
                    <textarea class="textarea col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                        [class]="field"
                        [formControlName]="field"
                        [class.textarea-error]="(masterForm[index].get(field).dirty || masterForm[index].get(field).touched) && masterForm[index].get(field).invalid && masterForm[index].get(field).errors"
                        [placeholder]="currentFormContent[index][field].placeholder">
                    </textarea>
                </ng-container>

                <ng-container *ngSwitchCase="'select'">
                    <mat-accordion class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                        [class.select-error]="(masterForm[index].get(field).dirty || masterForm[index].get(field).touched) && masterForm[index].get(field).invalid && masterForm[index].get(field).errors" >
                        <mat-expansion-panel class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                            #mapanel="matExpansionPanel">
                            <mat-expansion-panel-header>
                                <mat-panel-title class="h4">
                                    <input type="radio" [formControlName]="field" [id]="field" [value]="masterForm[index].get(field).value">
                                    {{masterForm[index].get(field).value || currentFormContent[index][field].placeholder}}
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <ng-container *ngIf="currentFormContent[index][field]['grouped']; else default">
                                <ul>
                                    <ng-container *ngFor="let option of currentFormContent[index][field].options; trackBy: trackByFn">
                                        <li class="finality bold">{{option.finality}}</li>
                                        <li
                                            *ngFor="let type of option.types; trackBy: trackByFn"
                                            class="type"
                                            [value]="type"
                                            (click)="getSelectValue(index, field, type)"
                                            (click)="emitSelectValue(index, 'finality', option.finality, currentFormContent[index][field].emitSelected)"
                                            (click)="mapanel.close()">
                                            <a>{{ type }}</a>
                                        </li>
                                    </ng-container>
                                </ul>
                            </ng-container>
                            <ng-template #default>
                                <ul>
                                    <li *ngFor="let option of currentFormContent[index][field].options; trackBy: trackByFn"
                                        [value]="option.name"
                                        (click)="getSelectValue(index, field, option.name)"
                                        (click)="emitSelectValue(index, field, option.name, currentFormContent[index][field].emitSelected)"
                                        (click)="mapanel.close()">
                                        <a>{{ option.name }}</a>
                                    </li>
                                </ul>
                            </ng-template>
                        </mat-expansion-panel>
                    </mat-accordion>
                    <!-- <div class="accordion-dismiss" *ngIf="mapanel" (click)="mapanel.close()"></div> -->
                </ng-container>

                <ng-container *ngSwitchCase="'calendar'">
                    <input class="input h4 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" [class]="field"
                        type="text" [formControlName]="field"
                        [placeholder]="currentFormContent[index][field].placeholder"
                        (click)="showCalendar(field)"
                        [readonly]="true"
                        [ngModel]="(field === 'start') ? calendarDatesExtensive[0] : calendarDatesExtensive[1]" (ngModelChange)="(field === 'start') ? calendarDatesExtensive[0] : calendarDatesExtensive[1] = $event"
                        [class.input-error]="(masterForm[index].get(field).dirty || masterForm[index].get(field).touched) && masterForm[index].get(field).invalid && masterForm[index].get(field).errors">
                    <imobzi-calendar-availability *ngIf="calendarVisible === field"
                        [startDateSelected]="calendarDates[0]"
                        [endDateSelected]="calendarDates[1]"
                        [calendarAvailability]="field"
                        [singleCalendar]="singleCalendar"
                        [propertyCalendar]="property.calendar"
                        (searchDates)="onChangedDate($event)">
                    </imobzi-calendar-availability>
                </ng-container>

                <ng-container *ngSwitchDefault>
                    <ng-container *ngIf="currentFormContent[index][field].mask; else withoutMask">
                        <input class="input h4 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" [class]="field"
                            [type]="'text'" [formControlName]="field"
                            [placeholder]="currentFormContent[index][field].placeholder"
                            [mask]="currentFormContent[index][field].mask"
                            [dropSpecialCharacters]="currentFormContent[index][field].hasOwnProperty('dropSpecialCharacters') ? currentFormContent[index][field]['dropSpecialCharacters'] : true"
                            thousandSeparator="."
                            [class.input-error]="(masterForm[index].get(field).dirty || masterForm[index].get(field).touched) && masterForm[index].get(field).invalid && masterForm[index].get(field).errors" />
                    </ng-container>

                    <ng-template #withoutMask>
                        <input class="input h4 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" [class]="field"
                            [type]="currentFormContent[index][field].type" [formControlName]="field"
                            [placeholder]="currentFormContent[index][field].placeholder"
                            (change)="onChangeValue($event)"
                            id={{field}}
                            [class.input-error]="(masterForm[index].get(field).dirty || masterForm[index].get(field).touched) && masterForm[index].get(field).invalid && masterForm[index].get(field).errors" />
                    </ng-template>
                </ng-container>
            </div>

            <div class="files-container gd-xs-2-11 gd-sm-2-11 gd-md-5-8 gd-lg-5-8 gd-xl-5-8"
                *ngIf="this.stepItems.length > 1 && activeStepIndex === stepItems.length - 1">
                <div class="files-content grid-xs-12 grid-sm-4 grid-md-4 grid-lg-4 grid-xl-4">
                    <img class="gd-xs-1-12 gd-sm-2-3 gd-md-2-3 gd-lg-2-3 gd-xl-2-3" [src]="layoutBucketPath + 'picture.svg'" alt="">
                    <button class="gd-xs-2-11 gd-sm-2-3 gd-md-2-3 gd-lg-2-3 gd-xl-2-3 btn btn-primary-1 btn-md" aria-label="Enviar Imagens" (click)="openUploader();">Enviar
                        Imagens</button>
                    <input id="uploader" type="file" ng2FileSelect [uploader]="uploader" multiple style="display:none;" />
                </div>

                <p class="margin-top-8">* Máximo de 50 Arquivos com até 30mb</p>

                <div class="itens-anexo grid-6 margin-top-25" fxLayout="row wrap" fxLayoutAlign="space-evenly center">
                    <div *ngFor="let item of uploader.queue" class="btn btn-secondary-2 btn-md padding-10 margin-bottom-10"
                        fxLayout="row" fxLayoutAlign="space-evenly center">
                        <label title="{{ item?.file?.name }}">{{ item?.file?.name.slice(0, 10) + '...' }}</label>
                        <a class="anexo_remover" aria-label="Remover Anexos" title="remover" (click)="item.remove();">x</a>
                    </div>
                </div>
            </div>

            <div [class.loading-container]="loading" class="button-container gd-xs-1-12 gd-sm-1-12 gd-md-1-12 gd-lg-1-12 gd-xl-1-12 grid-12">
                <button class="gd-xs-1-5 gd-sm-1-5 gd-md-8-9 gd-lg-8-9 gd-xl-8-9 btn btn-disabled-2 btn-md" type="button"
                    *ngIf="stepItems.length > 1 && !loading"
                    [class.disabled]="activeStepIndex < 1 || activeStepIndex > 3"
                    (click)="goToStep('prev')">Voltar
                </button>
                <button class="gd-xs-8-12 gd-sm-8-12 gd-md-10-11 gd-lg-10-11 gd-xl-10-11 btn btn-primary-1 btn-md" type="button"
                    *ngIf="stepItems.length > 1 && activeStepIndex < 3 && !loading"
                    [disabled]="masterForm[index].invalid"
                    [class.btn-disabled-1]="masterForm[index].invalid"
                    (click)="goToStep('next')">Avançar
                </button>
                <button class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 btn btn-md"
                    [class]="(buttonClass && !masterForm[index].invalid) ? buttonClass : 'btn-primary-1'"
                    type="button"
                    *ngIf="activeStepIndex === stepItems.length - 1  && !loading"
                    [disabled]="masterForm[index].invalid"
                    [class.step-request]="stepItems.length <= 1"
                    [class.step-advertise]="stepItems.length > 1"
                    [class.btn-disabled-1]="masterForm[index].invalid"
                    (click)="goToStep('next')"
                    (click)="onFormSubmit()">{{ buttonTitle || 'Enviar' }}
                </button>
                <div *ngIf="loading" class="loader"></div>
            </div>
        </form>
    </ng-container>
</div>
<div class="accordion-dismiss" *ngIf="calendarVisible !== ''" (click)="closeCalendar()"></div>
