<section id="calendar">
    <div class="calendar-container">

        <mat-card [class.border-none]="!showBorder"[class.full-width]="singleCalendar" class="calendar_current">

            <imobzi-section-title
                [title]="title"
                [subtitle]="subtitle">
            </imobzi-section-title>

            <mat-card-header fxLayout="row" fxLayoutAlign="center center">
                <button class="swiper-prev" type="button" aria-label="Button Prev Page"
                    mat-icon-button (click)="buttonActions('previous')">
                    <mat-icon class="material-icons-round" aria-label="Prev">chevron_left</mat-icon>
                </button>
                <h3 class="title-mobile" #currentMonthAndYear></h3>
                <button *ngIf="singleCalendar" class="swiper-next" type="button" aria-label="Button Next Page"
                    mat-icon-button (click)="buttonActions('next')">
                    <mat-icon class="material-icons-round" aria-label="Next">chevron_right</mat-icon>
                </button>
            </mat-card-header>

            <mat-card-content>
                <table>
                    <thead>
                        <tr>
                            <th *ngFor="let day of days">{{day}}</th>
                        </tr>
                    </thead>

                    <tbody #currentCalendarBody></tbody>
                </table>
            </mat-card-content>

            <div *ngIf="propertyCalendar" (click)="clearDates()" class="bold clear-dates">Limpar Datas</div>

        </mat-card>
        <mat-card [class.border-none]="!showBorder"[class.display-none]="singleCalendar" class="calendar_next">
            <mat-card-header fxLayout="row" fxLayoutAlign="center center">
                <h3 #nextMonthAndYear></h3>
                <button class="swiper-next" type="button" aria-label="Button Next Page"
                    mat-icon-button (click)="buttonActions('next')">
                    <mat-icon class="material-icons-round" aria-label="Next">chevron_right</mat-icon>
                </button>
            </mat-card-header>
            <mat-card-content>
                <table>
                    <thead>
                        <tr>
                            <th *ngFor="let day of days">{{day}}</th>
                        </tr>
                    </thead>

                    <tbody #nextCalendarBody></tbody>
                </table>
            </mat-card-content>
        </mat-card>

    </div>
</section>
