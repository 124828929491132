<div class="container" style="display: flex; width: 100%;">

    <section id="search">
        <form class="form-search col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" [formGroup]="searchForm"
            *ngIf="propertyTypesSearch" (ngSubmit)="onSubmitForm()">

            <div *ngIf="searchButtons !== 'banner'" class="row"
                [class.buttons-content]="searchButtons === 'ungrouped_buttons'"
                [class.buttons-content-grouped-buttons]="searchButtons === 'grouped_buttons'">
                <mat-button-toggle-group #availabilityGroup="matButtonToggleGroup" fxLayout="row"
                    [fxLayoutAlign]="searchButtons === 'grouped_buttons' ? 'center center' : 'space-evenly center'"
                    (change)="onSearchAvailability(availabilityGroup.value)">
                    <mat-button-toggle class="btn-search col-xs-3 col-sm-3 col-md-3 col-lg-3 col-xl-3"
                        [class.background-trans]="searchButtons === 'ungrouped_buttons'"
                        [class.background-light]="searchButtons === 'grouped_buttons'"
                        *ngFor="let finality of propertyTypesSearch | objectgroups" [checked]="finality === 'buy'"
                        [value]="finality">
                        <span>{{ translateAvailability(finality) }}</span>
                    </mat-button-toggle>
                    <button
                        *ngIf="parameters.site_home_button.active && parameters.site_home_button.button_type === 'property_filter'"
                        class="btn-search col-xs-3 col-sm-3 col-md-3 col-lg-3 col-xl-3" type="button"
                        [class.background-trans]="searchButtons === 'ungrouped_buttons'"
                        [class.background-light]="searchButtons === 'grouped_buttons'">
                        <imobzi-custom-button [buttonHome]="parameters.site_home_button">
                        </imobzi-custom-button>
                    </button>
                </mat-button-toggle-group>
            </div>

            <div class="row group-search">
                <div class="container-search col-md-12 col-lg-12 col-xl-12"
                    [class.container-search-grouped-buttons]="searchButtons === 'grouped_buttons'">
                    <ng-container *ngIf="searchButtons === 'banner'">
                        <mat-accordion class="col-xs-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">
                            <mat-expansion-panel #availabilityPanel="matExpansionPanel"
                                (opened)="panelOpenState1 = true" (closed)="panelOpenState1 = false">
                                <mat-expansion-panel-header>
                                    <mat-panel-title class="h3">
                                        {{ translateAvailability(availabilitySelected) || "Disponibilidade" }}
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <ul>
                                    <ng-container *ngFor="let finality of propertyTypesSearch | objectgroups">
                                        <li class="h3" *ngIf="availabilitySelected !== finality"
                                            (click)="onSearchAvailability(finality)">
                                            <a>{{ translateAvailability(finality) }}</a>
                                        </li>
                                    </ng-container>
                                </ul>
                            </mat-expansion-panel>
                        </mat-accordion>

                        <span class="divisor"></span>
                    </ng-container>


                    <ng-container *ngIf="availabilitySelected !== 'vacation_rental'">
                        <mat-accordion class="col-xs-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">
                            <mat-expansion-panel #typePanel="matExpansionPanel" (opened)="panelOpenState2 = true"
                                (closed)="panelOpenState2 = false">
                                <mat-expansion-panel-header>
                                    <mat-panel-title class="h3">
                                        {{ typeSelected || 'Tipo' }}
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <ul>
                                    <ng-container *ngFor="let finalitys of propertyTypesSearch[availabilitySelected]">
                                        <li class="h3 property-finality bold bold">
                                            <a>{{ translateFinality(finalitys.property_finality) }}</a>
                                        </li>
                                        <ng-container *ngFor="let type of finalitys.types">
                                            <li *ngIf="typeSelected !== type.property_type"
                                                (click)="onSearchType(type.property_type)" class="h3 property-type">
                                                {{ type.property_type }}
                                            </li>
                                        </ng-container>
                                    </ng-container>
                                </ul>
                            </mat-expansion-panel>
                        </mat-accordion>

                        <span class="divisor"></span>
                    </ng-container>

                    <div
                        [class]="(availabilitySelected !== 'vacation_rental') ? 'group-input_autocomplete col-md-7 col-lg-7 col-xl-7' : 'group-input_autocomplete padding-left-22 padding-right-22 col-md-4 col-lg-4 col-xl-4'">

                        <input #searchInput class="h3 input-search col-md-12 col-lg-12 col-xl-12"
                            [placeholder]="(availabilitySelected !== 'vacation_rental') ? 'Procure por uma cidade, bairro, condomínio ou código' : 'Para onde você vai?'"
                            formControlName="locationGroup" autocomplete="off" (blur)="hideDropdownList()"
                            (focus)="showDropdownList()" (input)="reloadListInDelay($event)"
                            (keydown)="inputElKeyHandler($event)" [(ngModel)]="searchLabel" type="text">

                        <div [class.baloon-options]="availabilitySelected === 'vacation_rental'"
                            class="option-group col-md-12 col-lg-12 col-xl-12" *ngIf="dropdownVisible">
                            <ul>
                                <div id="overflow" class="overflow-scroll">
                                    <ng-container
                                        *ngFor="let suggestion of locationGroupOptions | async; let index = index">
                                        <li *ngIf="suggestion.label.length > 0" class="option-category bold h3 bold">
                                            {{suggestion.category}}
                                        </li>
                                        <li *ngFor="let label of suggestion.label; let i = index; trackBy: trackByIndex"
                                            class="option-item h3"
                                            [ngClass]="{selected: i === itemIndex && index === groupIndex}"
                                            (mousedown)="selectOne(suggestion.category, label)">
                                            {{ label }}
                                        </li>
                                    </ng-container>
                                </div>
                            </ul>
                        </div>
                    </div>

                    <ng-container *ngIf="availabilitySelected === 'vacation_rental'">

                        <span class="divisor"></span>

                        <div class="vacation-rental-option-search col-md-2 col-lg-2 col-xl-2">
                            <div (click)="showCalendar('start')" class="h3 clickable-option">
                                <div>
                                    Chegada<br>
                                    <span *ngIf="startDateSelected">{{ convertDateStringToFullDate(startDateSelected,
                                        'abbreviatedDate') }}</span>
                                </div>
                                <mat-icon *ngIf="!startCalendarVisible">expand_more</mat-icon>
                                <mat-icon *ngIf="startCalendarVisible">close</mat-icon>
                            </div>
                        </div>

                        <span class="divisor"></span>

                        <div class="vacation-rental-option-search col-md-2 col-lg-2 col-xl-2">
                            <div (click)="showCalendar('end')" class="h3 clickable-option">
                                <div>
                                    Saída<br>
                                    <span *ngIf="endDateSelected">{{ convertDateStringToFullDate(endDateSelected,
                                        'abbreviatedDate') }}</span>
                                </div>
                                <mat-icon *ngIf="!endCalendarVisible">expand_more</mat-icon>
                                <mat-icon *ngIf="endCalendarVisible">close</mat-icon>
                            </div>
                        </div>

                        <imobzi-calendar-availability *ngIf="startCalendarVisible || endCalendarVisible"
                            [startDateSelected]="startDateSelected" [endDateSelected]="endDateSelected"
                            [calendarAvailability]="calendarAvailability" [singleCalendar]="singleCalendar"
                            (searchDates)="onsearchDates($event)">
                        </imobzi-calendar-availability>

                        <span class="divisor"></span>

                        <div class="vacation-rental-option-search col-md-2 col-lg-2 col-xl-2">
                            <div (click)="showGuestsCounter()" class="h3 clickable-option">
                                <div>
                                    Hóspedes<br>
                                    <span *ngIf="adultsSelected + childrenSelected > 0">{{ adultsSelected +
                                        childrenSelected }} {{(adultsSelected + childrenSelected > 1) ? 'hóspedes':
                                        'hóspede'}}</span>
                                </div>
                                <mat-icon *ngIf="!guestCounterVisible">expand_more</mat-icon>
                                <mat-icon *ngIf="guestCounterVisible">close</mat-icon>
                            </div>
                        </div>
                        <imobzi-guests-counter [adultsSelected]="adultsSelected" [childrenSelected]="childrenSelected"
                            (searchAdults)="onSearchAdults($event)" (searchChildren)="onSearchChildren($event)"
                            *ngIf="guestCounterVisible">
                        </imobzi-guests-counter>
                    </ng-container>

                    <button type="submit"
                        class="btn-search-form background-trans border-trans-1 col-md-1 col-lg-1 col-xl-1">
                        <mat-icon>search</mat-icon>
                    </button>
                </div>
            </div>

        </form>
    </section>
    <section class="map-button" *ngIf="searchButtons === 'banner'">
        <button (click)="onSearchMap(true)" class="btn-icon btn-disabled-2 btn-md-48 map" matTooltip="Exibir Mapa">
            <img [src]="layoutBucketPath + 'icon-google-maps.svg'" alt="Mapa">
        </button>
    </section>
</div>
<div class="accordion-dismiss" *ngIf="panelOpenState1 || panelOpenState2 || guestCounterVisible" (click)="closePanel()">
</div>
