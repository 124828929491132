import { Component, Input } from '@angular/core';
import { Parameters } from 'src/app/interface/parameters';

@Component({
    selector: 'imobzi-address',
    templateUrl: './address.component.html',
    styleUrls: ['./address.component.scss']
})
export class AddressComponent {

    @Input() parameters: Parameters;

    constructor() { }

}
