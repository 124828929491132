import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { Parameters, Phone } from 'src/app/interface/parameters';
import { Realtor } from 'src/app/interface/realtor';
import { AppService } from 'src/app/service/app.service';
import { onPhoneClicked } from 'src/app/utils/utils';
import { TalkBrokerDialogComponent } from './talk-broker-dialog/talk-broker-dialog.component';
import { Property } from 'src/app/interface/property';

@Component({
    selector: 'imobzi-talk-broker-mobile',
    templateUrl: './talk-broker-mobile.component.html',
    styleUrls: ['./talk-broker-mobile.component.scss']
})
export class TalkBrokerMobileComponent implements OnInit {

    @Input() data: any;
    @Output() closeDialog = new EventEmitter();

    public listingBroker: Realtor;
    public parameters: Parameters;
    public property: Property;
    public clientBucketPath: string = this.appService.clientBucketPath();
    public layoutBucketPath: string = this.appService.layoutBucketPath();

    constructor(
        private appService: AppService,
        private dialog: MatDialog,
        public library: FaIconLibrary
    ) { library.addIcons(faWhatsapp); }

    ngOnInit() {
        this.listingBroker = this.data.property.listing_broker;
        this.parameters = this.data.parameters;
        this.property = this.data.property;
    }

    public openTalkBrokerDialog() {
        this.dialog.open(TalkBrokerDialogComponent, {
            panelClass: 'custom-dialog-container',
            data: this.data.property
        });
    }

    public onBtnChanged(value: boolean) {
        this.removeLocalStorage();
        this.closeDialog.emit(value);
    }

    public onPhoneClicked(phone: Phone, type?: string) {
        return onPhoneClicked(phone, this.dialog, this.parameters.realtor.show_whatsapp_modal, type);
    }

    public realtorImgErrorHandler(event) {
        event.target.onerror = null;
        event.target.src = `${this.appService.layoutBucketPath()}realtor.png`;
    }

    private removeLocalStorage() {
        localStorage.removeItem('search_params');
    }
}
