<section id="search-mobile">
    <form
        class="form-search col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
        [formGroup]="searchForm"
        *ngIf="propertyTypesSearch"
        (ngSubmit)="onSubmitForm()">

        <div class="row group-search">
            <div [class.vacation-rental-container-search]="availabilitySelected === 'vacation_rental'" class="container-search">
                <mat-accordion class="col-xs-12 col-sm-12 col-md-12 item-1"
                    [class.open]="panelOpenState1">
                    <mat-expansion-panel #availabilityPanel="matExpansionPanel"
                        [class.border-primary-2]="panelOpenState1"
                        (opened)="panelOpenState1 = true"
                        (closed)="panelOpenState1 = false">
                        <mat-expansion-panel-header>
                            <mat-panel-title class="h3">
                                {{ translateAvailability(availabilitySelected) || "Disponibilidade" }}
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <ul>
                            <ng-container *ngFor="let finality of propertyTypesSearch | objectgroups">
                                <li class="h3"
                                    *ngIf="availabilitySelected !== finality"
                                    (click)="onSearchAvailability(finality)">
                                    {{ translateAvailability(finality) }}
                                </li>
                            </ng-container>
                        </ul>
                    </mat-expansion-panel>
                </mat-accordion>

                <mat-accordion *ngIf="availabilitySelected !== 'vacation_rental'" class="col-xs-12 col-sm-12 col-md-12 item-2"
                    [class.open]="panelOpenState2">
                    <mat-expansion-panel #typePanel="matExpansionPanel"
                        [class.border-primary-2]="panelOpenState2"
                        (opened)="panelOpenState2 = true"
                        (closed)="panelOpenState2 = false">
                        <mat-expansion-panel-header>
                            <mat-panel-title class="h3">
                                {{ typeSelected || "Tipo" }}
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <ul>
                            <ng-container *ngFor="let finalitys of propertyTypesSearch[availabilitySelected]">
                                <li class="h3 property-finality bold">
                                    <a>{{ translateFinality(finalitys.property_finality) }}</a>
                                </li>
                                <ng-container *ngFor="let type of finalitys.types">
                                    <li
                                        *ngIf="typeSelected !== type.property_type"
                                        (click)="onSearchType(type.property_type)"
                                        (click)="closePanel()"
                                        class="h3 property-type">
                                        {{ type.property_type }}
                                    </li>
                                </ng-container>
                            </ng-container>
                        </ul>
                    </mat-expansion-panel>
                </mat-accordion>
            </div>


            <div class="group-input_autocomplete col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">

                <input #searchInput id="searchInput" class="h3 color-input-color padding-10 input-search col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                    placeholder="Cidade, bairro, condomínio ou código"
                    autocomplete="off"
                    formControlName="locationGroup"
                    [class.open]="open"
                    (input)="reloadListInDelay($event)"
                    (blur)="hideDropdownList()"
                    (focus)="showDropdownList()"
                    (click)="$event.stopPropagation()"
                    [(ngModel)]="searchLabel"
                    type="text">

                <div class="option-group col-md-12 col-lg-12 col-xl-12" *ngIf="dropdownVisible">
                    <ul>
                        <div id="overflow" class="overflow-scroll">
                            <ng-container *ngFor="let suggestion of locationGroupOptions | async; let index = index">
                                <li *ngIf="suggestion.label.length > 0" class="option-category bold h3 bold">
                                    {{suggestion.category}}
                                </li>
                                <li *ngFor="let label of suggestion.label; let i = index; trackBy: trackByIndex"
                                    class="option-item h3"
                                    [ngClass]="{selected: i === itemIndex}"
                                    (mousedown)="selectOne(suggestion.category, label)">
                                    {{ label }}
                                </li>
                            </ng-container>
                        </div>
                    </ul>
                </div>
            </div>

            <ng-container *ngIf="availabilitySelected === 'vacation_rental'">
                <div class="vacation-rental-option-search col-xs-12 col-sm-12 col-md-12">
                    <div (click)="showCalendar()" class="h3 clickable-option">
                        <div>
                            <span *ngIf="!startDateSelected && !endDateSelected">Chegada e Saída</span>
                            <span *ngIf="startDateSelected && endDateSelected">{{ convertDateStringToFullDate(startDateSelected, 'abbreviatedDate') + ' à ' + convertDateStringToFullDate(endDateSelected, 'abbreviatedDate') }}</span>
                        </div>
                        <mat-icon>expand_more</mat-icon>
                    </div>
                </div>

                <div class="vacation-rental-option-search col-xs-12 col-sm-12 col-md-12">
                    <div (click)="showshowGuestsCounter()" class="h3 clickable-option">
                        <div>
                            <span *ngIf="!adultsSelected && !childrenSelected">Hóspedes</span>
                            <span *ngIf="adultsSelected + childrenSelected > 0">{{ adultsSelected + childrenSelected }} {{(adultsSelected + childrenSelected > 1) ? 'hóspedes': 'hóspede'}}</span>
                        </div>
                        <mat-icon *ngIf="!guestCounterVisible">expand_more</mat-icon>
                        <mat-icon *ngIf="guestCounterVisible">close</mat-icon>
                    </div>
                </div>
            </ng-container>


            <button [class.btn-submit-search-mobile]="searchButtons === 'banner'" class="col-xs-12 col-sm-12 btn-submit" id="submit-button" type="submit">Buscar</button>

            <button
                *ngIf="parameters && parameters.site_home_button.active && parameters.site_home_button.button_type === 'property_filter'"
                type="button"
                class="col-xs-8 col-sm-8 btn-custom">
                <imobzi-custom-button
                    [buttonHome]="parameters.site_home_button">
                </imobzi-custom-button>
            </button>
        </div>
    </form>
</section>

<imobzi-guests-counter-mobile
    [adultsSelected]="adultsSelected"
    [childrenSelected]="childrenSelected"
    (searchAdults)="onSearchAdults($event)"
    (searchChildren)="onSearchChildren($event)"
    (closeCounter)="guestCounterVisible = false"
    *ngIf="guestCounterVisible">
</imobzi-guests-counter-mobile>

<div class="accordion-dismiss" *ngIf="panelOpenState1 === true || panelOpenState2 === true" (click)="closePanel()"></div>
