import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Property } from 'src/app/interface/property';

@Component({
  selector: 'imobzi-schedule-visit',
  templateUrl: './schedule-visit.component.html',
  styleUrls: ['./schedule-visit.component.scss']
})
export class ScheduleVisitComponent implements OnInit {

  @Input() data: Property;
  @Input() dates: Array<string>;
  @Input() stepVisit: boolean;
  @Input() formType: string;
  @Output() stepNext = new EventEmitter();
  @Output() closeDialog = new EventEmitter();

  displaySuccess: boolean;
  msgSuccessTitle = ``;
  msgSuccessSubtitle = ``;

  btnSuccessActive: boolean;
  btnSuccessTitle = `Fechar`;

  msgContactSuccess: string;

  intervalDays: number;

  constructor() { }

  ngOnInit() {
    if (this.formType === 'reserve') {
      this.msgSuccessTitle = `<h3 class="display-h3 text-center">A sua reserva foi <br> efetuada com sucesso!<h3>`;
      this.msgSuccessSubtitle = `<h2 class="text-center normal">Aguarde nosso contato em 24 horas. <br> Após contatá-lo, enviaremos <br> a forma de pagamento.</h2>`;
    } else {
      this.msgSuccessTitle = `<h3 class="display-h3 text-center">Visita agendada. <br> Aguarde confirmação! <h3>`;
      this.msgSuccessSubtitle = `<h2 class="text-center normal"> Nas próximas horas, um de nossos corretores <br> irá confirmar com você o melhor <br> horário e ponto de encontro.</h2>`;
    }
  }

  onScheduleVisitChanged(value: boolean) {
    this.stepVisit = value;
    this.stepNext.emit(value);
  }

  onDisplaySuccess(value: boolean) {
    this.displaySuccess = value;
  }

  onBtnSuccessActive(value: boolean) {
    this.btnSuccessActive = value;
  }

  onBtnChanged(value: boolean) {
    this.closeDialog.emit(value);
  }

  onMsgContactSuccess(value: string) {
    this.msgContactSuccess = value;
  }

  onIntervalDays(intervalDays: number) {
    this.intervalDays = intervalDays;
  }

}
