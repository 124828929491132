import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SitePopup } from 'src/app/interface/parameters';

@Component({
    selector: 'imobzi-popup',
    templateUrl: './popup.component.html',
    styleUrls: ['./popup.component.scss']
})
export class PopupComponent {

    public sitePopup: SitePopup;

    constructor(
        public dialogRef: MatDialogRef<PopupComponent>,
        @Inject(MAT_DIALOG_DATA) public popup: any
    ) { this.sitePopup = popup['site_popup']; }

    closePopup() {
        this.dialogRef.close();
    }
}
