<section id="banner" [class.full-search-form-banner]="searchButtons === 'banner'">

    <ng-container *ngIf="!isVideo; else video">
        <lazy-loading-image *ngIf="searchButtons !== 'banner'">
            <img (error)="imgErrorHandler($event)" *ngIf [src]="landUrl" alt="Imagem Principal" title="Imagem Principal"/>
        </lazy-loading-image>
    </ng-container>

    <ng-template #video>
        <video poster autoplay oncanplay="this.play()" onloadedmetadata="this.muted = true" playsinline loop>
            <source [src]="landUrl" type="video/mp4">
        </video>
    </ng-template>

    <div class="home-search" [class.full-search-form]="searchButtons === 'banner'">
        <div class="container home-search-container">
            <div *ngIf="searchButtons !== 'banner' && parameters && siteConfiguration.main_title" class="title-content">
                <h1 class="title display-h3">{{ siteConfiguration.main_title }}</h1>
            </div>
            <div class="background-search-form">
                <imobzi-search-form
                    [searchButtons]="searchButtons"
                    [propertyTypesSearch]="propertyTypesSearch"
                    [availabilitySelected]="availabilitySelected"
                    [typeSelected]="typeSelected"
                    [adultsSelected]="adultsSelected"
                    [childrenSelected]="childrenSelected"
                    [startDateSelected]="startDateSelected"
                    [endDateSelected]="endDateSelected"
                    [locationGroups]="locationGroups"
                    [searchLabel]= "searchLabel"
                    [parameters]="parameters"
                (selectedLocation)="selectedLocation($event)"
                    (searchAvailability)="searchAvailability($event)"
                    (searchType)="searchType($event)"
                    (searchDates)="searchDates($event)"
                    (searchAdults)="searchAdults($event)"
                    (searchChildren)="searchChildren($event)"
                    (searchMap)="searchMap($event)"
                    (submitForm)="submitForm($event)">
                </imobzi-search-form>
            </div>
            <imobzi-search-form-mobile
                [searchButtons]="searchButtons"
                [propertyTypesSearch]="propertyTypesSearch"
                [availabilitySelected]="availabilitySelected"
                [typeSelected]="typeSelected"
                [adultsSelected]="adultsSelected"
                [childrenSelected]="childrenSelected"
                [startDateSelected]="startDateSelected"
                [endDateSelected]="endDateSelected"
                [locationGroups]="locationGroups"
                [searchLabel]= "searchLabel"
                [parameters]="parameters"
                (selectedLocation)="selectedLocation($event)"
                (searchAvailability)="searchAvailability($event)"
                (searchType)="searchType($event)"
                (searchDates)="searchDates($event)"
                (searchAdults)="searchAdults($event)"
                (searchChildren)="searchChildren($event)"
                (submitForm)="submitForm($event)">
            </imobzi-search-form-mobile>
        </div>
    </div>

</section>
