import { Component, Input } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Parameters } from 'src/app/interface/parameters';

@Component({
    selector: 'imobzi-menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.scss']
})
export class MenuComponent {

    @Input() sidenav: MatSidenav;
    @Input() parameters: Parameters;

}
