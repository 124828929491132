import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'imobzi-guests-counter',
    templateUrl: './guests-counter.component.html',
    styleUrls: ['./guests-counter.component.scss']
})

export class GuestsCounterComponent {
    @Input() public adultsSelected = 0;
    @Input() public childrenSelected = 0;
    @Input() public maxGuests = 30;
    @Output() private searchAdults = new EventEmitter();
    @Output() private searchChildren = new EventEmitter();

    public checkAddMoreGuests(count: number, guests: number): boolean {
        guests = Number(guests);
        const totalGuests = Number(this.adultsSelected) + Number(this.childrenSelected);

        return (
            ((totalGuests + count) <= this.maxGuests) &&
            ((totalGuests + count) >= 1) &&
            ((guests + count) >= 0));
    }

    counterGuests(type: string, value: number) {
        this.adultsSelected = Number(this.adultsSelected);
        this.childrenSelected = Number(this.childrenSelected);

        if (type === 'adults') {
            this.adultsSelected += (this.checkAddMoreGuests(value, this.adultsSelected)) ? value : 0;
            this.searchAdults.emit(this.adultsSelected);
        } else {
            this.childrenSelected += (this.checkAddMoreGuests(value, this.childrenSelected)) ? value : 0;
            this.searchChildren.emit(this.childrenSelected);
        }
    }
}
