import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Parameters } from '../interface/parameters';
import { AppService } from './app.service';
import { RequestService } from './request.service';

@Injectable({
  providedIn: 'root'
})
export class ParametersService {

  public parameters: Parameters;

  constructor(
    private appService: AppService,
    private requestService: RequestService
  ) { }

  public getParameters(): Observable<Parameters> {
    if (this.parameters) {
      return new Observable<Parameters>(
        observer => observer.next(this.parameters)
      );
    } else {
      return new Observable<Parameters>(observer => {
        this.requestService.get<Parameters>(`${this.appService.apiUrl()}/${this.appService.namespace()}/site/parameters`).subscribe(
          response => {
            this.parameters = response;
            observer.next(response);
          },
          error => observer.error('Erro: ' + error),
          () => observer.complete()
        );
      });
    }
  }
}
