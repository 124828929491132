import { isPlatformBrowser } from '@angular/common';
import { AfterViewInit, Directive, ElementRef, Inject, Input, PLATFORM_ID } from '@angular/core';
import { SwiperContainer } from 'swiper/element';

@Directive({
    selector: '[appSwiper]'
})
export class SwiperDirective implements AfterViewInit {
    @Input() config?: any;

    constructor(
        private el: ElementRef<SwiperContainer>,
        @Inject(PLATFORM_ID) private platformId: Object
    ) { }

    ngAfterViewInit(): void {
        if (isPlatformBrowser(this.platformId)) {
            Object.assign(this.el.nativeElement, this.config);
            this.el.nativeElement.initialize();
        }
    }
}
