<div [class.privacy-policy]="!hideBanner"
    [class.hide-banner]="hideBanner"
    class="">
    <span class="privacity-title">
        Ao prosseguir, você concorda com a nossa
            <a class="learn-more" (click)="showPrivacy()">Política de Privacidade</a>
        e com o uso dos cookies que nos permitem melhorar nossos serviços e recomendar conteúdos do seu interesse.
    </span>
    <div class="button-container">
        <a class="button-privacity" (click)="setCookie()">Ok, entendi</a>
    </div>
</div>

