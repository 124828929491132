import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Crisp } from 'crisp-sdk-web';

@Component({
    selector: 'imobzi-guests-counter-mobile',
    templateUrl: './guests-counter-mobile.component.html',
    styleUrls: ['./guests-counter-mobile.component.scss']
})

export class GuestsCounterMobileComponent implements OnInit, OnDestroy {
    @Input() public adultsSelected: number;
    @Input() public childrenSelected: number;
    @Input() public maxGuests = 30;
    @Output() private searchAdults = new EventEmitter();
    @Output() private searchChildren = new EventEmitter();
    @Output() private closeCounter = new EventEmitter();

    ngOnInit(): void {
        if (!this.adultsSelected) { this.adultsSelected = 0; }
        if (!this.childrenSelected) { this.childrenSelected = 0; }
        if (Crisp.isCrispInjected()) { Crisp.chat.hide(); }
    }

    ngOnDestroy() {
        if (Crisp.isCrispInjected()) { Crisp.chat.show(); }
    }

    public checkAddMoreGuests(count: number, guests: number): boolean {
        guests = Number(guests);
        const totalGuests = Number(this.adultsSelected) + Number(this.childrenSelected);

        return (
            ((totalGuests + count) <= this.maxGuests) &&
            ((totalGuests + count) >= 1) &&
            ((guests + count) >= 0));
    }

    counterGuests(type: string, value: number) {
        this.adultsSelected = Number(this.adultsSelected);
        this.childrenSelected = Number(this.childrenSelected);

        if (type === 'adults') {
            this.adultsSelected += (this.checkAddMoreGuests(value, this.adultsSelected)) ? value : 0;
            this.onSearchAdults(this.adultsSelected);
        } else {
            this.childrenSelected += (this.checkAddMoreGuests(value, this.childrenSelected)) ? value : 0;
            this.onSearchChildren(this.childrenSelected);
        }
    }

    clearGuests() {
        this.adultsSelected = 0;
        this.childrenSelected = 0;
        this.onSearchAdults(0);
        this.onSearchChildren(0);
    }

    applyChanges() {
        this.onSearchAdults(this.adultsSelected);
        this.onSearchChildren(this.childrenSelected);
        this.onClose();
    }

    onSearchAdults = (adults: number) => this.searchAdults.emit(adults);

    onSearchChildren = (adults: number) => this.searchChildren.emit(adults);

    onClose = () => this.closeCounter.emit();

}
