<div class="section-title container col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
    <imobzi-section-title
        [title]="title"
        [subtitle]="subtitle"
        [class.display_success]="displaySuccess">
    </imobzi-section-title>
</div>

<imobzi-step-second
    class="container padding-bottom-25 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
    *ngIf="!displaySuccess"
    [property]="data"
    [formType]="formType"
    [dates]="dates"
    (displaySuccess)="onDisplaySuccess($event)"
    (msgContactSuccess)="onMsgContactSuccess($event)">
</imobzi-step-second>

<imobzi-success-form 
    fxLayout="column" fxLayoutAlign="center center"
    class="container col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
    *ngIf="displaySuccess"
    [msgSuccessTitle]="msgSuccessTitle" 
    [msgSuccessSubtitle]="msgSuccessSubtitle"
    [btnSuccessActive]="btnSuccessActive"
    [btnSuccessTitle]="btnSuccessTitle"
    (btnAction)="onBtnChanged($event)">
</imobzi-success-form>