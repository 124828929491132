import { ConnectionPositionPair, Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { Directive, ElementRef, HostListener, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PopoverService } from '../service/popover.service';

@Directive({
    selector: '[popoverTrigger]'
})
export class PopoverDirective implements OnInit, OnDestroy {
    @Input() popoverTrigger!: TemplateRef<object>;
    @HostListener('click') clicked() {
        this.attachOverlay();
    }

    private unsubscribe = new Subject();
    private overlayRef!: OverlayRef;

    constructor(
        private elementRef: ElementRef,
        private overlay: Overlay,
        private vcr: ViewContainerRef,
        private popoverService: PopoverService
    ) { }

    ngOnInit(): void {
        this.createOverlay();
        this.popoverService.getState().subscribe(resp => {
            if (resp) {
                this.detachOverlay();
            }
        });
    }

    ngOnDestroy(): void {
        this.detachOverlay();
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    private attachOverlay(): void {
        if (!this.overlayRef.hasAttached()) {
            const periodSelectorPortal = new TemplatePortal(
                this.popoverTrigger,
                this.vcr
            );

            this.overlayRef.attach(periodSelectorPortal);
        }
    }

    private detachOverlay(): void {
        if (this.overlayRef.hasAttached()) {
            this.overlayRef.detach();
        }
    }

    private createOverlay(): void {
        const scrollStrategy = this.overlay.scrollStrategies.close();
        const positionStrategy = this.overlay.position().flexibleConnectedTo(this.elementRef)
            .withPositions([
                new ConnectionPositionPair(
                    { originX: 'start', originY: 'top' },
                    { overlayX: 'start', overlayY: 'bottom' },
                    0, 0, 'popover-directive-container'
                )
            ]);


        this.overlayRef = this.overlay.create({
            positionStrategy,
            scrollStrategy,
            hasBackdrop: true,
            backdropClass: ''
        });

        this.overlayRef
            .backdropClick()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(() => {
                this.detachOverlay();
            });
    }

}
