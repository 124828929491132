<imobzi-menu-list *ngIf="parameters" [parameters]="parameters" [sidenav]="sidenav"></imobzi-menu-list>
<ng-container *ngIf="parameters">
    <imobzi-area-cliente *ngIf="parameters.client_area" class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 area-cliente btn btn-light-1 btn-md"></imobzi-area-cliente>
    <imobzi-custom-button
        *ngIf="parameters.site_home_button.active && parameters.site_home_button.button_type !== 'property_filter'"
        class="margin-top-20 col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 area-cliente btn btn-light-1 btn-md"
        [buttonHome]="parameters.site_home_button">
    </imobzi-custom-button>
</ng-container>
<imobzi-contact [parameters]="parameters"></imobzi-contact>
