import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PropertyCalendar } from 'src/app/interface/property';

@Component({
    selector: 'imobzi-calendar-availability-dialog',
    templateUrl: './calendar-availability-dialog.component.html',
    styleUrls: ['./calendar-availability-dialog.component.scss']
})
export class CalendarAvailabilityDialogComponent implements OnInit {
    @Output() public searchDates = new EventEmitter();

    public startDateSelected: string;
    public endDateSelected: string;
    public isSmallScreen: boolean;
    public days = ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'];
    public propertyCalendar: Array<PropertyCalendar>;

    constructor(
        public dialogRef: MatDialogRef<CalendarAvailabilityDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) { }

    ngOnInit(): void {
        this.startDateSelected = this.data.startDateSelected;
        this.endDateSelected = this.data.endDateSelected;
        this.isSmallScreen = this.data.isSmallScreen;
        this.propertyCalendar = this.data.propertyCalendar;
    }

    public closeModal(event) {
        this.dialogRef.close();
    }

    public onSearchDates(date: Array<string>) {
        this.dialogRef.close();
        this.searchDates.emit(date);
    }

}
