import { Parameters, PropertyOrder, FinancingSimulator, Section } from 'src/app/interface/parameters';
import { CurrencyPipe, isPlatformBrowser } from '@angular/common';
import { HomeService } from 'src/app/service/home.service';
import { Component, EventEmitter, Inject, Input, OnInit, Output, PLATFORM_ID } from '@angular/core';
import { MatSliderChange } from '@angular/material/slider';
import { ChartData, ChartOptions, ChartType } from 'chart.js';
import { SearchParams } from 'src/app/interface/search-params';
import { PropertyService } from 'src/app/service/property.service';

@Component({
    selector: 'imobzi-financing-simulator',
    templateUrl: './financing-simulator.component.html',
    styleUrls: ['./financing-simulator.component.scss'],
    providers: [CurrencyPipe]
})
export class FinancingSimulatorComponent implements OnInit {

    @Output() closeModal = new EventEmitter();
    @Output() saleValueHistory = new EventEmitter();
    @Output() buildSearchResult = new EventEmitter();

    public doughnutChartData: ChartData<'doughnut'>;
    public doughnutChartType: ChartType = 'doughnut';
    public doughnutChartOptions: ChartOptions<'doughnut'>;
    public isBrowser: boolean;

    @Input() public saleValue: number;
    @Input() public parameters: Parameters;
    public financingSection: Section;
    public priceValue: number;
    public priceValueMin: number;
    public priceValueMax: number;
    public realValue: number;
    public downPayment: number;
    public downPaymentValue: number;
    public interestRate: number;
    public financingTime: number;
    public financingValues: FinancingSimulator;

    public valueAmortization: number;
    public valueInterestRate: number;
    public valueFlatRate: number;
    public valueInsuranceFee: number;
    public valueInstallments: number;
    public valueInsuranceRate: number;
    public valueMinimumIncome: number;

    public propertyCount: number;

    public nameUrl: string;
    public queryParams: object;
    public params: SearchParams;

    constructor(
        private propertyService: PropertyService,
        private homeService: HomeService,
        @Inject(PLATFORM_ID) private platformId: Object

    ) { }

    ngOnInit() {
        this.isBrowser = isPlatformBrowser(this.platformId);
        this.getFinancingValues();
        this.onChangeValues();
        this.setChartConfig();
        this.getSimulatorCount();
    }

    private initialValuesSlider() {
        this.setPriceValue();
        this.valueFlatRate = 25;
        this.valueInsuranceFee = 64;
        this.valueInsuranceRate = this.valueFlatRate + this.valueInsuranceFee;
        this.downPayment = this.financingValues.standard_entry / 100;
        this.interestRate = this.financingValues.interest_rate / 100;
        this.financingTime = this.financingValues.years;
    }

    private onChangeValues() {
        this.downPaymentValue = this.priceValue * this.downPayment;
        this.realValue = this.priceValue - this.downPaymentValue;
        this.valueAmortization = (this.realValue) / (this.financingTime * 12);
        this.valueInterestRate = this.realValue * this.compoundInterest(this.interestRate);
        this.valueInstallments = this.valueAmortization + this.valueInterestRate + this.valueFlatRate + this.valueInsuranceFee;
        this.valueMinimumIncome = this.valueInstallments / 0.35;
        this.setChartConfig();
        this.searchQueryParams();
    }

    public getSimulatorCount() {
        this.saleValueHistory.emit(this.priceValue);
        this.params = {
            availability: 'buy',
            order: {} as PropertyOrder,
            value_to_simulator: this.priceValue,
            page: 1,
            search_type: 'properties',
            show_map: false
        };
        this.propertyService
            .searchProperties(this.params).subscribe(
                result => {
                    this.buildSearchResult.emit(result);
                    this.propertyCount = result.properties.count;
                });
    }

    public searchQueryParams() {
        this.removeLocalStorage();
        this.nameUrl = 'buscar';
        this.queryParams = {
            cursor: '',
            availability: 'buy',
            value_to_simulator: this.priceValue
        };
    }

    private setPriceValue() {
        if (this.saleValue) {
            this.priceValue = this.saleValue;
            this.priceValueMin = this.saleValue * 50 / 100;
            this.priceValueMax = this.saleValue * 150 / 100;
        } else {
            this.priceValue = this.financingValues.default_price;
            this.priceValueMin = this.financingValues.minimum_price;
            this.priceValueMax = this.financingValues.maximum_price;
        }
    }

    private getFinancingValues() {
        const siteConfiguration = this.homeService.siteHomeConfiguration;
        const sections = siteConfiguration.sections;
        this.financingSection = sections.find(section => section.type === 'financing_simulator');
        this.financingValues = this.financingSection.financing_simulator;
        this.initialValuesSlider();
    }

    private compoundInterest = (interestRate: number) => {
        return Math.pow(1 + interestRate, 1 / 12) - 1;
    }

    public updatePrice(price: MatSliderChange) {
        this.priceValue = price.value;
        this.onChangeValues();
    }

    public updateDownPayment(downPayment: MatSliderChange) {
        this.downPayment = downPayment.value;
        console.log(downPayment);

        this.onChangeValues();
    }

    public updateInterestRate(interestRate: MatSliderChange) {
        this.interestRate = interestRate.value;
        this.onChangeValues();
    }

    public updateFinancingTime(financingTime: MatSliderChange) {
        this.financingTime = financingTime.value;
        this.onChangeValues();
    }

    private setChartConfig() {
        this.doughnutChartData = {
            labels: ['Valor Amortização: R$', 'Valor dos Juros: R$', 'Taxa Fixa - Banco: R$', 'Taxa de Seguro: R$'],
            datasets: [{
                clip: {
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 0,
                },
                data: [
                    Number(this.valueAmortization.toFixed(2)),
                    Number(this.valueInterestRate.toFixed(2)),
                    Number(this.valueFlatRate.toFixed(2)),
                    Number(this.valueInsuranceFee.toFixed(2))
                ],
                backgroundColor: ['#059BFF', '#22CECE', '#FF9124', '#FF3D67']
            }]
        };

        this.doughnutChartOptions = {
            responsive: true,
            cutout: '92%',
            plugins: {
                legend: {
                    display: false
                }
            }
        };
    }

    public removeLocalStorage() {
        if (this.isBrowser) {
            localStorage.removeItem('search_params');
        }
    }

    public onCloseModal() {
        this.closeModal.emit('close');
    }

}
