import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { SiteContact } from 'src/app/interface/contact';
import { Parameters } from 'src/app/interface/parameters';
import { AppService } from 'src/app/service/app.service';
import { FormService } from 'src/app/service/form.service';
import { ParametersService } from 'src/app/service/parameters.service';
import { PATTERN } from 'src/app/utils/constants';

import { PrivacyService } from 'src/app/service/privacy.service';
import { HomeService } from 'src/app/service/home.service';
import { SiteConfiguration } from 'src/app/interface/parameters';

@Component({
    selector: 'imobzi-contact-contact-real-estate-dialog',
    templateUrl: './contact-real-estate-dialog.component.html',
    styleUrls: ['./contact-real-estate-dialog.component.scss'],
})
export class ContactRealEstateDialogComponent implements OnInit {

    public privacyService: PrivacyService;
    public privacyData: any;
    public data: any;

    title = `Contato`;
    subtitle = `Faça uma simples simulação de financiamento e saiba mais detalhes sobre prazos e condições`;

    buttonTitle = '';

    displaySuccess = false;
    loading = false;
    msgSuccessTitle = `<h3 class="display-h3 color-title text-center">Cadastro efetuado <br> com sucesso!<h3>`;
    msgSuccessSubtitle = `<h2 class="color-subtitle text-center normal">Um de nossos corretores <br> entrará em contato em breve.</h2>`;

    messageRequired = `É necessário preencher o campo!`;

    siteConfiguration: SiteConfiguration;
    parameters: Parameters;
    formContent: any;
    formData: SiteContact;
    STEP_ITEMS = [];
    DATA_STEP_1: {};

    constructor(
        privacyService: PrivacyService,
        public appService: AppService,
        private titlePage: Title,
        private parametersService: ParametersService,
        private homeService: HomeService,
        private formService: FormService,
        public dialogRef: MatDialogRef<ContactRealEstateDialogComponent>,
        @Inject(MAT_DIALOG_DATA) data: any) {
            this.privacyService = privacyService;
            this.data = data;
        }

    ngOnInit() {
        this.getFooterLogoUrl();
        this.titlePage.setTitle(this.title + ' - ' + this.titlePage.getTitle());
        this.stepItens();
        this.formContent = this.STEP_ITEMS;

        this.parametersService.getParameters().subscribe(parameters => {
            this.parameters = parameters;
        });
    }

    stepItens() {
        this.STEP_ITEMS = [
            { label: '1', data: this.dataStep1() }
        ];
    }

    dataStep1() {
        return this.DATA_STEP_1 = {
            name: {
                type: 'text',
                placeholder: 'Nome',
                validations: {
                    required: true,
                    minLength: 2
                },
                class: 'contact-form gd-xs-1-12 gd-sm-2-11 gd-md-2-11 gd-lg-2-11 gd-xl-2-11',
                errors: {
                    required: this.messageRequired,
                    minlength: 'Digite no minimo de 2 caracteres'
                }
            },
            phone: {
                type: 'phone',
                placeholder: 'Telefone',
                validations: {
                    required: true,
                    pattern: PATTERN.phone
                },
                class: 'contact-form gd-xs-1-12 gd-sm-2-11 gd-md-2-11 gd-lg-2-11 gd-xl-2-11',
                errors: {
                    required: this.messageRequired,
                    pattern: 'Por favor adicione um Telefone valido!'
                }
            },
            email: {
                type: 'email',
                placeholder: 'E-mail',
                validations: {
                    required: true,
                    email: true
                },
                class: 'contact-form gd-xs-1-12 gd-sm-2-11 gd-md-2-11 gd-lg-2-11 gd-xl-2-11',
                errors: {
                    required: this.messageRequired,
                    email: `Email invalido`
                }
            },
            message: {
                type: 'textarea',
                placeholder: 'Mensagem',
                validations: {
                    required: true,
                },
                class: 'contact-form gd-xs-1-12 gd-sm-2-11 gd-md-2-11 gd-lg-2-11 gd-xl-2-11',
                errors: {
                    required: this.messageRequired
                }
            }
        };
    }

    onFormSubmit(formData: any): void {
        this.loading = true;
        this.formData = formData;

        if (!this.privacyService.getItem('Sent') && this.privacyService.getItem('Imobzi')) {
            this.privacyData = this.privacyService.getItem('Imobzi');
            Object.keys(this.privacyData).forEach(key => {
                this.formData[key] = this.privacyData[key];
            });
            this.privacyService.setItem('Sent', true);
        }

        if (this.data && this.data.hasOwnProperty('nickname')) {
            this.formData['nickname'] = this.data.nickname;
        }

        if (this.formData) {
            this.formService.contact(this.formData).subscribe(
                () => {
                    this.loading = false;
                    this.displaySuccess = true;
                },
                error => {
                    this.loading = false;
                    this.displaySuccess = false;
                }
            );
        }
    }

    onBtnChanged(value: any) {
        this.closeDialog(value);
    }

    closeDialog(value: any): void {
        this.dialogRef.close();
    }

    private getFooterLogoUrl() {
        this.siteConfiguration = this.homeService.siteHomeConfiguration;
    }
}
