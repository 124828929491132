<div class="fab-container">
  <button mat-fab class="fab-toggler" (click)="onToggleFab()">
      <img class="fab_toggler_icons" [src]="layoutBucketPath + 'contact.svg'" alt="contato">
  </button>
  <div [@speedDialStagger]="buttons.length">
    <button
      *ngFor="let btn of buttons"
      [class.whatsapp]="btn.icon === 'whatsapp'"
      (click)="openDialog(btn.icon)"
      (click)="onToggleFab()"
      mat-mini-fab class="fab-secondary"
      color="secondary">
      <fa-icon class="fab-icon" [icon]="[btn.iconType, btn.icon]"></fa-icon>
    </button>
  </div>
</div>
<div id="fab-dismiss" *ngIf="fabTogglerState === 'active'" (click)="onToggleFab()"></div>
