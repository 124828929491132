import { ChangeDetectionStrategy, Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faFacebookF, faInstagram, faLinkedin, faPinterest, faSkype, faTiktok, faTwitter, faWhatsapp, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { Parameters, Phone, SocialNetwork } from 'src/app/interface/parameters';
import { AppService } from 'src/app/service/app.service';
import { onPhoneClicked } from 'src/app/utils/utils';
import { MatDialog } from '@angular/material/dialog';

@Component({
    selector: 'imobzi-social-network',
    templateUrl: './social-network.component.html',
    styleUrls: ['./social-network.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SocialNetworkComponent implements OnInit {

    @Input() parameters: Parameters;
    public layoutBucketPath = `${this.appService.layoutBucketPath()}redes-sociais`;
    public imgSocial: string;
    public layoutBlack = false;
    public icon: string;

    constructor(
        private appService: AppService,
        private library: FaIconLibrary,
        private dialog: MatDialog
    ) {
        library.addIcons(faWhatsapp, faFacebookF, faInstagram, faTwitter, faSkype, faTiktok, faPinterest, faYoutube, faLinkedin);
    }

    ngOnInit(): void {
        (this.layoutBlack)
            ? this.icon = '_white.svg'
            : this.icon = '.svg';
    }


    getSocialNetworkUrl(socialNetwork: SocialNetwork) {
        const validUrl: RegExp = /((https?|http?):\/\/)/;

        if (validUrl.test(socialNetwork.account)) {
            return socialNetwork.account;
        } else {
            return `https://${socialNetwork.name.toLocaleLowerCase()}.com/${socialNetwork.account}`;
        }
    }

    onPhoneClicked(phone: Phone) {
        return onPhoneClicked(phone, this.dialog, this.parameters.realtor.show_whatsapp_modal);
    }

}
