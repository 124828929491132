import { HttpClient } from '@angular/common/http';
import { Directive, ElementRef, Input, OnInit } from '@angular/core';

@Directive({
    selector: '[imobziSvgObjectLink]'
})
export class SvgObjectLinkDirective implements OnInit {

    @Input() imobziSvgObjectLink: string;
    constructor(
        private elementRef: ElementRef,
        private http: HttpClient
    ) { }

    ngOnInit() {
        this.http.get(this.imobziSvgObjectLink, { responseType: 'text' }).subscribe(data => {
            this.elementRef.nativeElement.innerHTML = data;
        });
    }
}
