import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, Input, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { Parameters, SiteConfiguration } from 'src/app/interface/parameters';
import { AppService } from 'src/app/service/app.service';

@Component({
    selector: 'imobzi-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, OnDestroy {

    @Input() parameters: Parameters;
    @Input() siteConfiguration: SiteConfiguration;
    public layoutBucketPath: string = this.appService.layoutBucketPath();
    private breakpoint: any;
    public hostname: string;
    public googleCertificate: string;
    public sslCertificate: string;


    constructor(
        public appService: AppService,
        private breakpointObserver: BreakpointObserver,
        @Inject(PLATFORM_ID) private platformId: Object
    ) { }

    ngOnInit(): void {
        this.getBreakpoint();
        if (isPlatformBrowser(this.platformId)) {
            this.hostname = document.location.hostname.replace('www.', '');
        }
        this.googleCertificate = `https://transparencyreport.google.com/safe-browsing/search?url=${this.hostname}&hl=pt_BR`;
        this.sslCertificate = `https://www.sslshopper.com/ssl-checker.html#hostname=${this.hostname}`;
    }

    ngOnDestroy(): void {
        this.breakpoint.unsubscribe();
    }

    private getBreakpoint() {
        this.breakpoint = this.breakpointObserver.observe([
            Breakpoints.HandsetLandscape
        ]).subscribe(result => {
            if (isPlatformBrowser(this.platformId)) {
                (result.matches)
                    ? document.documentElement.style.setProperty('--height-banner', '170vh')
                    : document.documentElement.style.setProperty('--height-banner', '100vh');

                (result.matches && this.breakpointObserver.isMatched('(min-width: 812px) and (max-width: 823px'))
                    ? document.documentElement.style.setProperty('--height-banner-ipad', '136vh')
                    : document.documentElement.style.setProperty('--height-banner-ipad', '100vh');

            }
        });
    }

}
