import { CurrencyPipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { SiteContact } from 'src/app/interface/contact';
import { Property } from 'src/app/interface/property';
import { FormService } from 'src/app/service/form.service';
import { PATTERN } from 'src/app/utils/constants';

import { PrivacyService } from 'src/app/service/privacy.service';

@Component({
    selector: 'imobzi-talk-broker',
    templateUrl: './talk-broker.component.html',
    styleUrls: ['./talk-broker.component.scss'],
    providers: [CurrencyPipe]
})
export class TalkBrokerComponent implements OnInit {

    public privacyService: PrivacyService;
    public privacyData: any;

    @Input() data: any;
    property: Property;
    loading = false;

    title = `Fale com <br> o corretor`;
    subtitle = ``;

    displaySuccess = false;
    msgSuccessTitle = `<h3 class="display-h3 color-title text-center">O seu contato foi <br> enviado com sucesso!<h3>`;
    msgSuccessSubtitle = `<h2 class="color-subtitle text-center normal">Um de nossos corretores <br> entrará em contato em breve.</h2>`;

    formContent: any;
    formData: SiteContact;
    STEP_ITEMS = [];
    DATA_STEP_1: {};
    buttonTitle = '';
    messageRequired = `É necessário preencher o campo!`;

    constructor(
        privacyService: PrivacyService,
        private titlePage: Title,
        private formService: FormService,
        private currencyPipe: CurrencyPipe,
    ) { this.privacyService = privacyService; }

    ngOnInit() {
        this.titlePage.setTitle(this.titlePage.getTitle());
        this.property = this.data;
        this.formContent = this.stepItens();
    }

    private stepItens() {
        return this.STEP_ITEMS = [
            { label: '1', data: this.dataStep1() }
        ];
    }

    private dataStep1() {
        return this.DATA_STEP_1 = {
            name: {
                type: 'text',
                placeholder: 'Nome',
                validations: {
                    required: true,
                    minLength: 2
                },
                class: 'contact-form gd-xs-2-11 gd-sm-2-11 gd-md-2-11 gd-lg-2-11 gd-xl-2-11',
                errors: {
                    required: this.messageRequired,
                    minlength: 'Digite no minimo de 2 caracteres'
                }
            },
            phone: {
                type: 'phone',
                placeholder: 'Telefone',
                validations: {
                    required: true,
                    pattern: PATTERN.phone
                },
                class: 'contact-form gd-xs-2-11 gd-sm-2-11 gd-md-2-11 gd-lg-2-11 gd-xl-2-11',
                errors: {
                    required: this.messageRequired,
                    pattern: 'Por favor adicione um Telefone valido!'
                }
            },
            email: {
                type: 'email',
                placeholder: 'E-mail',
                validations: {
                    required: true,
                    email: true
                },
                class: 'contact-form gd-xs-2-11 gd-sm-2-11 gd-md-2-11 gd-lg-2-11 gd-xl-2-11',
                errors: {
                    required: this.messageRequired,
                    email: `Email invalido`
                }
            },
            message: {
                value: `Olá, Gostaria de ter mais informações do imóvel de Cód. ${this.property.code}; \n` +
                    `${this.property.property_type} ${(this.property.tags.indexOf('sale') !== -1) ? `à Venda de ` +
                        `${this.currencyPipe.transform(Number(this.property.sale_value), 'BRL', 'symbol', '1.0-0')}` : ''} ${(this.property.tags.length === 2) ? ' ou ' : ''}` +
                    `${(this.property.tags.indexOf('rent') !== -1) ? `para Locação de ` +
                        `${this.currencyPipe.transform(Number(this.property.rental_value), 'BRL', 'symbol', '1.0-0')}` : ''}` +
                    `${(this.property.tags.indexOf('vacation_rental') !== -1) ? `para Temporada de ` +
                        `${this.currencyPipe.transform(Number(this.property.rental_value), 'BRL', 'symbol', '1.0-0')}` : ''}` +
                    ` em ${this.property.neighborhood}, que encontrei no seu site. \nAguardo seu contato. Obrigado!`,
                type: 'textarea',
                placeholder: 'Observações',
                validations: {
                    required: true,
                },
                class: 'contact-form gd-xs-2-11 gd-sm-2-11 gd-md-2-11 gd-lg-2-11 gd-xl-2-11',
                errors: {
                    required: this.messageRequired
                }
            }
        };
    }

    public onFormSubmit(formData: SiteContact) {
        this.loading = true;
        this.formData = formData;

        if (!this.privacyService.getItem('Sent') && this.privacyService.getItem('Imobzi')) {
            this.privacyData = this.privacyService.getItem('Imobzi');
            Object.keys(this.privacyData).forEach(key => {
                this.formData[key] = this.privacyData[key];
            });
            this.privacyService.setItem('Sent', true);
        }
        this.formData.property_code = this.property.code;

        if (this.formData) {
            this.loading = true;
            this.formService.contact(this.formData).subscribe(
                () => {
                    this.loading = false;
                    this.displaySuccess = true;
                },
                error => {
                    this.loading = false;
                    this.displaySuccess = false;
                }
            );
        }
    }
}
