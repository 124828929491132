<imobzi-section-title 
    [title]="title" 
    [subtitle]="subtitle" 
    [class.step_success]="displaySuccess">
</imobzi-section-title>

<imobzi-step-form
    [loading]="loading" 
    *ngIf="!displaySuccess" 
    [formContent]="formContent" 
    [buttonTitle]="buttonTitle"
    [loading]="loading"
    (formSubmit)="onFormSubmit($event)">
</imobzi-step-form>

<imobzi-success-form 
    *ngIf="displaySuccess"            
    [msgSuccessTitle]="msgSuccessTitle"
    [msgSuccessSubtitle]="msgSuccessSubtitle">
</imobzi-success-form>