import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { Parameters, Phone } from 'src/app/interface/parameters';
import { AppService } from 'src/app/service/app.service';
import { onPhoneClicked } from 'src/app/utils/utils';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'imobzi-phone',
  templateUrl: './phone.component.html',
  styleUrls: ['./phone.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PhoneComponent {

  @Input() parameters: Parameters;
  layoutBucketPath: string = this.appService.layoutBucketPath();

  constructor(
    private appService: AppService,
    private library: FaIconLibrary,
    private dialog: MatDialog
  ) { library.addIcons(faWhatsapp); }

  onPhoneClicked(phone: Phone) {
    return onPhoneClicked(phone, this.dialog, this.parameters.realtor.show_whatsapp_modal);
  }
}
