<section id="listingBroker"  class="container" fxLayout="column" fxLayoutAlign="space-between center">
    <ng-container *ngIf="listingBroker && listingBroker.license; else realEstate">
        <img class="fundo_img" [src]="layoutBucketPath + 'city-background.svg'" alt="fundo">
        <div class="listingBroker-container col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
            fxLayout="column" fxLayoutAlign="space-between center">
            <figure>
                <img *ngIf="listingBroker"
                class="img-circle img-responsive" 
                [src]="listingBroker.profile_image_url" 
                (error)="realtorImgErrorHandler($event)"
                alt="Corretor {{ listingBroker.name }}"
                title="Corretor {{ listingBroker.name }}">
            </figure>
    
            <h2 class="text-center" *ngIf="listingBroker.name">{{ listingBroker.name }}</h2>
            <h3 class="text-center" *ngIf="listingBroker.license">CRECI {{ listingBroker.license }}</h3>
    
        </div>
        <button
            (click)="openTalkBrokerDialog()"
            class="margin-10 relative btn btn-md btn-primary-1 col-xs-12 col-sm-6 col-md-12 col-lg-12 col-xl-12">
            {{ 'Enviar mensagem' | uppercase }}
        </button>
        <button
            [routerLink]="['corretor/' + listingBroker.nickname]"
            [queryParams]="{ nickname: listingBroker.nickname }"
            (click)="onBtnChanged(true)"
            class="margin-10 relative btn btn-md btn-disabled-2 col-xs-12 col-sm-6 col-md-12 col-lg-12 col-xl-12">
            {{ 'Imóveis deste corretor (' + listingBroker.count + ')' | uppercase }}
        </button>
    </ng-container>

    <ng-template #realEstate>
        <img class="fundo_img" [src]="layoutBucketPath + 'city-background.svg'" alt="fundo">
        <div class="listingBroker-container col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
            fxLayout="column" fxLayoutAlign="space-between center">
            <figure>
                <img class="img-circle img-responsive" 
                [src]="layoutBucketPath + 'realtor.png'"
                (error)="realtorImgErrorHandler($event)"
                alt="Imobiliaria"
                title="Imobiliaria">
            </figure>

            <h2 class="text-center" 
                *ngIf="parameters.company_name">
                {{ parameters.company_name }}
            </h2>
            <h3 class="text-center"
                *ngIf="parameters.creci">
                {{ 'CRECI ' + parameters.creci }}
            </h3>
    
        </div>
        <button
            (click)="openTalkBrokerDialog()"
            class="margin-10 relative btn btn-md btn-primary-1 col-xs-12 col-sm-6 col-md-12 col-lg-12 col-xl-12">
            {{ 'Enviar mensagem' | uppercase }}
        </button>
    </ng-template>

    <imobzi-phone-numbers
        class="col-xs-12 col-sm-6 col-md-12 col-lg-12 col-xl-12"
        [parameters]="parameters"
        [property]="property"
        [showWhatsapp]="true"
        [showPhone]="true">
    </imobzi-phone-numbers>
</section>