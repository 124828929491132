import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { of } from 'rxjs';
import { Database } from '../interface/parameters';

@Injectable({
    providedIn: 'root',
})
export class AppInitService {
    private databaseString: string;
    private origin: string;

    constructor(
        private http: HttpClient,
        @Optional() @Inject('ORIGIN') origin: string
    ) {
        this.origin = origin;
    }

    public loadDatabase(): Promise<void> {
        let headers = new HttpHeaders();

        if (this.origin) {
            headers = new HttpHeaders({
                'Origin': this.origin,
                'Referer': this.origin
            });
        }
        return this.http.get<Database>(`https://api2.imobzi.app/v1/site/database`, { headers }).pipe(
            map(response => {
                this.databaseString = response.database;
            }),
            catchError(error => {
                return of(null);
            })
        ).toPromise();
    }

    public getDatabaseString(): string {
        return this.databaseString;
    }
}
