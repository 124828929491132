import { CurrencyPipe } from '@angular/common';
import { Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Property } from 'src/app/interface/property';
import { AppService } from 'src/app/service/app.service';
import { ContactBrokerModalComponent } from '../modal/contact-broker/contact-broker-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { Parameters } from 'src/app/interface/parameters';

@Component({
    selector: 'imobzi-leaflet',
    templateUrl: './leaflet.component.html',
    styleUrls: ['./leaflet.component.scss'],
    providers: [CurrencyPipe],
})
export class LeafletComponent implements OnInit, OnChanges {
    @Input() address: string;
    @Input() neighborhood: string;
    @Input() city: string;
    @Input() state: string;
    @Input() longitude: number;
    @Input() latitude: number;
    @Input() loading: boolean;
    @Input() propertiesMap: { [key: string]: Array<Property> };
    @Input() parameters: Parameters;
    public layoutBucketPath: string = this.appService.layoutBucketPath();
    private map;
    public property: Property;
    public markers = [{}];
    public circles = [{}];

    constructor(
        private el: ElementRef,
        private currencyPipe: CurrencyPipe,
        private appService: AppService,
        private dialogContactBroker: MatDialog,
    ) { }

    ngOnInit() {
        if (this.latitude && this.longitude) {
            this.mapToHeaderDetail();
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (!this.map) {
            import('leaflet').then(L => {
                this.map = L.map(this.el.nativeElement.querySelector('#map'));
            });
        }
        if (changes.propertiesMap) {
            this.loading = !Boolean(Object.keys(changes.propertiesMap));
            if (!this.loading) {
                this.mapToSearch();
            }
        }
    }

    private mapToHeaderDetail() {
        import('leaflet').then(L => {
            this.map.setView([this.latitude, this.longitude], 16);

            L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
                attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors',
                maxZoom: 18
            }).addTo(this.map);

            if (this.address) {
                this.markers.forEach(() => {
                    L.marker([this.latitude, this.longitude]).addTo(this.map)
                        .bindPopup(`<b>${this.neighborhood}</b><br>${this.address}`);
                });
            } else {
                this.circles.forEach(() => {
                    L.circle([this.latitude, this.longitude], {
                        color: '#C0C0C0',
                        fillColor: '#C0C0C0',
                        fillOpacity: 0.5,
                        radius: 500
                    }).addTo(this.map).bindPopup(`<b>${this.city}</b><br>${this.state}`);
                });
            }
        });
    }

    private mapToSearch() {
        import('leaflet').then(L => {
            const keys = Object.keys(this.propertiesMap);
            const coordinates = keys.map(coord => coord.split(','));
            if (keys.length === 0) {
                return;
            }
            const [latitude, longitude] = coordinates[0];
            this.map.setView([latitude, longitude], 13);
            L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
                attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors',
            }).addTo(this.map);
            this.removeMarkers();
            coordinates.forEach(([lat, lng], index) => {
                const property = this.propertiesMap[keys[index]];
                const image = document.createElement('img');
                image.className = 'popup-image';
                image.src = property[0].cover_photo.url ? property[0].cover_photo.url : this.layoutBucketPath + 'image-not-found.png';
                const city = document.createElement('p');
                city.textContent = property[0].city;
                const price = document.createElement('h4');
                if (property[0].rental_value || property[0].sale_value) {
                    if (property[0].rental_value) {
                        price.textContent = this.currencyPipe.transform(property[0].rental_value, 'BRL', 'symbol', '1.0-0') + ` `;
                    } else if (property[0].sale_value) {
                        price.textContent = this.currencyPipe.transform(property[0].sale_value, 'BRL', 'symbol', '1.0-0') + ` `;
                    }
                }
                const neighborhood = document.createElement('h2');
                neighborhood.textContent = property[0].neighborhood;
                const area = document.createElement('h4');
                area.textContent = property[0].property_type + `, ` + property[0].useful_area + 'm²';
                const info = document.createElement('div');
                info.className = 'info';
                info.appendChild(city);
                info.appendChild(neighborhood);
                info.appendChild(area);
                info.appendChild(price);
                const button = document.createElement('a');
                button.classList.add('btn', 'btn-md', 'btn-details');
                button.innerText = '+ DETALHES';
                button.href = property[0].site_url;
                const email = document.createElement('img');
                email.src = this.layoutBucketPath + 'Icon-Envelope.svg';
                const contact = document.createElement('a');
                contact.classList.add('btn', 'btn-md', 'btn-contact');
                contact.addEventListener('click', () => {
                    this.openContactBrokerDialog(property[0]);
                });
                contact.appendChild(email);
                const content = document.createElement('div');
                content.className = 'card-container';
                content.appendChild(image);
                content.appendChild(info);
                content.appendChild(button);
                content.appendChild(contact);
                const marker = L.marker([lat, lng]).addTo(this.map)
                    .bindPopup(content);
                this.markers.push(marker);
            });
        });
    }

    private removeMarkers() {
        this.markers.forEach(marker => {
            this.map.removeLayer(marker);
        });
        this.markers = [];
    }

    public openContactBrokerDialog(property: Property): void {
        this.dialogContactBroker.open(ContactBrokerModalComponent, {
            panelClass: 'custom-dialog-container',
            id: 'contact_broker',
            data: {
                property,
                parameters: this.parameters
            }
        });
    }


}
