import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FieldsPipe } from './fields.pipe';
import { SanitizedHtmlPipe } from './sanitized-html.pipe';
import { SearchPipe } from './search.pipe';
import { FormatTitlePipe } from './format-title.pipe';


@NgModule({
    declarations: [SearchPipe, SanitizedHtmlPipe, FieldsPipe, FormatTitlePipe],
    imports: [CommonModule],
    exports: [SearchPipe, SanitizedHtmlPipe, FieldsPipe, FormatTitlePipe]
})
export class PipesModule { }
