import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ImgSizeDirective } from './img-size.directive';
import { LazyLoadImageDirective } from './lazy-load-image.directive';
import { LazyLoadingImage } from './lazy-loading-image';
import { SvgObjectLinkDirective } from './svg-object-link.directive';
import { PopoverDirective } from './popover.directive';
import { SwiperDirective } from './swiper.directive';

@NgModule({
    declarations: [
        LazyLoadImageDirective,
        ImgSizeDirective,
        LazyLoadingImage,
        SvgObjectLinkDirective,
        PopoverDirective,
        SwiperDirective
    ],
    imports: [
        CommonModule
    ],
    exports: [
        CommonModule,
        LazyLoadImageDirective,
        ImgSizeDirective,
        LazyLoadingImage,
        SvgObjectLinkDirective,
        PopoverDirective,
        SwiperDirective
    ]
})
export class DirectiveModule { }
