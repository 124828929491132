import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'imobzi-talk-broker-dialog',
    templateUrl: './talk-broker-dialog.component.html',
    styleUrls: ['./talk-broker-dialog.component.scss']
})
export class TalkBrokerDialogComponent {

    constructor(
        public dialogRef: MatDialogRef<TalkBrokerDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) { }

    closeDialog(value: boolean): void {
        this.dialogRef.close();
    }

}
