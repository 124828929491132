import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, EventEmitter, Input, OnInit, Output, OnDestroy } from '@angular/core';
import { SiteContact } from 'src/app/interface/contact';
import { Property } from 'src/app/interface/property';
import { FormService } from 'src/app/service/form.service';
import { PATTERN } from 'src/app/utils/constants';
import { CurrencyPipe } from '@angular/common';
import { convertDateStringToFullDate } from 'src/app/utils/utils';

import { PrivacyService } from 'src/app/service/privacy.service';

@Component({
    selector: 'imobzi-step-second',
    templateUrl: './step-second.component.html',
    styleUrls: ['./step-second.component.scss'],
    providers: [CurrencyPipe]
})
export class StepSecondComponent implements OnInit, OnDestroy {

    public privacyService: PrivacyService;
    public privacyData: any;

    @Input() property: Property;
    @Input() formType: string;
    @Input() dates: Array<string>;
    @Output() displaySuccess = new EventEmitter();
    @Output() msgContactSuccess = new EventEmitter();
    @Output() exportIntervalDays = new EventEmitter<number>();

    day: string;
    schedule: string;
    nextButton: boolean;
    isSmallScreen: boolean;
    preText: string;
    showDateVisit: boolean;
    loading = false;
    intervalDays = 0;

    buttonTitle = '';
    messageRequired = `É necessário preencher o campo!`;

    formContent: any;
    formData: SiteContact;
    STEP_ITEMS = [];
    DATA_VISIT: {};

    contactMessage: string;
    private breakpoint: any;

    constructor(
        privacyService: PrivacyService,
        private formService: FormService,
        private currencyPipe: CurrencyPipe,
        public breakpointObserver: BreakpointObserver,
    ) {
        this.getBreakpoint(),
            this.privacyService = privacyService;
    }

    ngOnInit() {
        this.nextButton = false;
        this.formContent = this.stepItens();
    }

    ngOnDestroy(): void {
        this.breakpoint.unsubscribe();
    }

    private stepItens() {
        return this.STEP_ITEMS = [
            { label: '1', data: this.dataVisit() }
        ];
    }

    private dataVisit() {
        if (this.formType === 'reserve' || (this.property.vacation_rental && this.formType !== 'schedule_visit')) {
            this.showDateVisit = false;
            return this.DATA_VISIT = {
                start: {
                    type: 'calendar',
                    placeholder: 'Data de chegada',
                    validations: {
                        required: true
                    },
                    class: 'contact-form gd-xs-1-12 gd-sm-1-12 gd-md-2-11 gd-lg-2-11 gd-xl-2-11',
                    errors: {
                        required: 'Selecione uma Data'
                    }
                },
                end: {
                    type: 'calendar',
                    placeholder: 'Data de saída',
                    validations: {
                        required: true
                    },
                    class: 'contact-form gd-xs-1-12 gd-sm-1-12 gd-md-2-11 gd-lg-2-11 gd-xl-2-11',
                    errors: {
                        required: 'Selecione uma Data'
                    }
                },
                name: {
                    type: 'text',
                    placeholder: 'Nome',
                    validations: {
                        required: true,
                        minLength: 2
                    },
                    class: 'contact-form gd-xs-1-12 gd-sm-1-12 gd-md-2-11 gd-lg-2-11 gd-xl-2-11',
                    errors: {
                        required: this.messageRequired,
                        minlength: 'Digite no minimo de 2 caracteres'
                    }
                },
                phone: {
                    type: 'phone',
                    placeholder: 'Telefone',
                    validations: {
                        required: true,
                        pattern: PATTERN.phone
                    },
                    class: 'contact-form gd-xs-1-12 gd-sm-1-12 gd-md-2-11 gd-lg-2-11 gd-xl-2-11',
                    errors: {
                        required: this.messageRequired,
                        pattern: 'Por favor adicione um Telefone valido!'
                    }
                },
                email: {
                    type: 'email',
                    placeholder: 'E-mail',
                    validations: {
                        required: true,
                        email: true
                    },
                    class: 'contact-form gd-xs-1-12 gd-sm-1-12 gd-md-2-11 gd-lg-2-11 gd-xl-2-11',
                    errors: {
                        required: this.messageRequired,
                        email: `Email invalido`
                    }
                }
            };
        } else {
            this.showDateVisit = true;
            return this.DATA_VISIT = {
                name: {
                    type: 'text',
                    placeholder: 'Nome',
                    validations: {
                        required: true,
                        minLength: 2
                    },
                    class: 'contact-form gd-xs-1-12 gd-sm-1-12 gd-md-2-11 gd-lg-2-11 gd-xl-2-11',
                    errors: {
                        required: this.messageRequired,
                        minlength: 'Digite no minimo de 2 caracteres'
                    }
                },
                phone: {
                    type: 'phone',
                    placeholder: 'Telefone',
                    validations: {
                        required: true,
                        pattern: PATTERN.phone
                    },
                    class: 'contact-form gd-xs-1-12 gd-sm-1-12 gd-md-2-11 gd-lg-2-11 gd-xl-2-11',
                    errors: {
                        required: this.messageRequired,
                        pattern: 'Por favor adicione um Telefone valido!'
                    }
                },
                email: {
                    type: 'email',
                    placeholder: 'E-mail',
                    validations: {
                        required: true,
                        email: true
                    },
                    class: 'contact-form gd-xs-1-12 gd-sm-1-12 gd-md-2-11 gd-lg-2-11 gd-xl-2-11',
                    errors: {
                        required: this.messageRequired,
                        email: `Email invalido`
                    }
                }
            };
        }
    }

    onDayChange(day: string) {
        this.day = day;
        (this.day === 'domingo' || this.day === 'sábado')
            ? this.preText = 'no'
            : this.preText = 'na';
    }

    onScheduleChange(schedule: string) {
        this.schedule = schedule.toLowerCase();
    }

    onNextChange(value: boolean) {
        this.nextButton = value;
    }

    private getBreakpoint() {
        this.breakpoint = this.breakpointObserver.observe([
            Breakpoints.Handset,
        ]).subscribe(result => {
            if (result.matches) {
                this.isSmallScreen = false;
            } else {
                this.isSmallScreen = true;
            }
        });
    }

    calculateIntervalDates(dates: Array<string>) {
        const dayInitial = new Date(dates[0]);
        const dayFinal = new Date(dates[1]);

        this.intervalDays = 0;
        while (dayInitial <= dayFinal) {
            this.intervalDays = this.intervalDays + 1;
            dayInitial.setDate(dayInitial.getDate() + 1);
        }
        this.intervalDays = this.intervalDays;
        this.exportIntervalDays.emit(this.intervalDays);
    }

    onFormSubmit(formData: SiteContact): void {
        this.formData = formData;

        if (this.showDateVisit) {
            this.contactMessage = `${this.day} na parte da ${this.schedule}`;
            this.formData.message = `Olá, Gostaria de visitar ${this.preText} ${this.day} na parte da ${this.schedule} o imóvel de Cód. ${this.property.code}; ` +
                `${this.property.property_type} ${(this.property.tags.indexOf('sale') !== -1) ? `à Venda de ` +
                    `${this.currencyPipe.transform(Number(this.property.sale_value), 'BRL', 'symbol', '1.0-0')}` : ''} ${(this.property.tags.length === 2) ? ' ou ' : ''}` +
                `${(this.property.tags.indexOf('rent') !== -1) ? `para Locação de ` +
                    `${this.currencyPipe.transform(Number(this.property.rental_value), 'BRL', 'symbol', '1.0-0')}` : ''}` +
                `${(this.property.tags.indexOf('vacation_rental') !== -1) ? `para Temporada de ` +
                    `${this.currencyPipe.transform(Number(this.property.rental_value), 'BRL', 'symbol', '1.0-0')}` : ''}` +
                ` em ${this.property.neighborhood}, que encontrei no seu site. Aguardo seu contato. Obrigado!`;
            this.formData.subject = 'Agendar Visita';
        } else {
            this.formData.max_value = this.intervalDays * this.property.rental_value;
            this.contactMessage = `Reservado do dia ${this.formData.start} à ${this.formData.end}`;
            this.formData.message = `${this.property.property_type}` +
                ` ${(this.property.vacation_rental_guests >= 1) ? 'para ' + this.property.vacation_rental_guests : ''}` +
                ` ${(this.property.vacation_rental_guests >= 1) ? (this.property.vacation_rental_guests >= 2) ? 'hóspedes' : 'hóspede' : ''}
      R$ ${this.property.rental_value} ${(this.intervalDays >= 1) ? (this.intervalDays >= 2) ? 'x ' + this.intervalDays + ' noites' : 'x ' + this.intervalDays + ' noite' : ''}` +
                `${(this.intervalDays) ? ' = R$ ' + this.intervalDays * this.property.rental_value + ' / total' : ''}`;
            this.formData.start_at = convertDateStringToFullDate(this.formData.start, 'date');
            this.formData.end_at = convertDateStringToFullDate(this.formData.end, 'date');
            this.formData.start = '';
            this.formData.end = '';
            this.formData.subject = 'Reserva de Imóvel de Temporada';
        }
        this.formData.property_code = this.property.code;

        if (this.formData) {
            this.loading = true;

            if (!this.privacyService.getItem('Sent') && this.privacyService.getItem('Imobzi')) {
                this.privacyData = this.privacyService.getItem('Imobzi');
                Object.keys(this.privacyData).forEach(key => {
                    this.formData[key] = this.privacyData[key];
                });
                this.privacyService.setItem('Sent', true);
            }
            this.formService.contact(this.formData).subscribe(
                () => {
                    this.loading = false;
                    this.displaySuccess.emit(true);
                    this.msgContactSuccess.emit(this.contactMessage);
                },
                error => {
                    this.displaySuccess.emit(false);
                    this.loading = false;
                    console.error(error);
                }
            );
        }
    }
}
