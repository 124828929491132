import { Injectable } from '@angular/core';
import { AppService } from './app.service';
import { RequestService } from './request.service';
import { Observable } from 'rxjs';
import { IIntegration } from '../interface/integration';

@Injectable({
    providedIn: 'root'
})
export class IntegrationsService {

    constructor(
        private appService: AppService,
        private requestService: RequestService
    ) { }

    getIntegrationByName(integrationName: string): Observable<IIntegration> {
        return new Observable<IIntegration>(observer => {
            this.requestService.get(
                `${this.appService.apiUrl()}/${this.appService.namespace()}/site/integration/${integrationName}`).subscribe(
                    response => observer.next(response[this.integration()]),
                    () => observer.error('error_on_get_integration'),
                    () => observer.complete()
                );
        });
    }

    private integration() {
        return 'integration';
    }
}
