import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Property } from 'src/app/interface/property';
import { normalizeSendParams } from 'src/app/utils/utils';

@Component({
  selector: 'imobzi-breadcrumb',
  templateUrl: './breadcrumb.html',
  styleUrls: ['./breadcrumb.scss']
})
export class BreadcrumbComponent implements OnInit {

  @Input() pageTitle?: string = null;
  @Input() category?: string = null;
  @Input() property?: Property;
  @Output() openBuildings: EventEmitter<any> = new EventEmitter<any>();

  public propertyAvailability: string;
  public categoryUrl: string;
  public title: string;
  public availabilityTitle: string;

  ngOnInit() {
    if (this.property) {
      this.translateAvailability();
      this.mountTitle();
    }
    if (this.category === 'imoveis' || this.category === 'Imóveis') {
      this.categoryUrl = 'buscar';
    } else if (this.category) {
      this.categoryUrl = this.category.replace(/ /g, '-').toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    }
  }

  onOpenBuildings() {
    this.openBuildings.emit();
  }

  translateAvailability() {
    this.property.tags.forEach(tag => {
      if (tag === 'sale') {
        this.propertyAvailability = 'comprar';
      } else if (tag === 'vacation_rental') {
        this.propertyAvailability = 'temporada';
      } else {
        this.propertyAvailability = 'alugar';
      }
    });
  }

  mountTitle() {
    this.property.tags.forEach(tag => {
      if (tag === 'sale') {
        this.availabilityTitle = 'à venda';
      } else if (tag === 'vacation_rental') {
        this.availabilityTitle = 'para Temporada';
      } else {
        this.availabilityTitle = 'para locação';
      }
    });

    this.title = `${this.property.property_type} ${this.availabilityTitle} em ${this.property.neighborhood} - ${this.property.city}`;
  }

  public normalizeSendParams(params: string): string {
    return normalizeSendParams(params);
  }
}
