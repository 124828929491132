import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'objectgroups',
  pure: false
})
export class FieldsPipe implements PipeTransform {

  transform(value: any): Array<string> {
    const fieldGroups: Array<string> = [];
    for (const key in value) {
      if (value.hasOwnProperty(key)) {
        const element = value[key];
        fieldGroups.push(key);
      }
    }
    return fieldGroups;
  }
}
