import { ChangeDetectorRef, Component, ElementRef, EventEmitter, HostBinding, Inject, Input, OnDestroy, OnInit, Output, PLATFORM_ID, ViewChild } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Parameters } from 'src/app/interface/parameters';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatExpansionPanel, MAT_EXPANSION_PANEL_DEFAULT_OPTIONS } from '@angular/material/expansion';
import { Observable, Subject } from 'rxjs';
import { translateFinality } from 'src/app/utils/utils';
import { debounceTime, map, startWith } from 'rxjs/operators';
import { ICityNeighborhoodSearch, LocationGroup } from 'src/app/interface/autocomplete-search';
import { SearchAvailabilityTypes } from 'src/app/interface/property';
import { convertDateStringToFullDate, isNumber, translateAvailability, normalizeSearch } from 'src/app/utils/utils';
import { AppService } from 'src/app/service/app.service';

@Component({
    selector: 'imobzi-search-form',
    templateUrl: './search-form.component.html',
    styleUrls: ['./search-form.component.scss'],
    providers: [
        {
            provide: MAT_EXPANSION_PANEL_DEFAULT_OPTIONS,
            useValue: {
                expandedHeight: '48px',
                collapsedHeight: '48px',
            }
        }
    ]
})
export class SearchFormComponent implements OnInit, OnDestroy {
    @ViewChild('availabilityPanel', { static: false }) availabilityPanel: MatExpansionPanel;
    @ViewChild('typePanel', { static: false }) typePanel: MatExpansionPanel;
    @ViewChild('searchInput', { static: false }) public inputSearch: ElementRef<HTMLInputElement>;
    @Input() public propertyTypesSearch: SearchAvailabilityTypes;
    @Input() public typeSelected: string;
    @Input() public availabilitySelected: string;
    @Input() public adultsSelected: number;
    @Input() public childrenSelected: number;
    @Input() public startDateSelected: string;
    @Input() public endDateSelected: string;
    @Input() public locationGroups: LocationGroup[];
    @Input() public parameters: Parameters;
    @Input() public searchLabel: string;
    @Input() public searchButtons: string;
    @Output() private selectedLocation = new EventEmitter();
    @Output() private searchAvailability = new EventEmitter();
    @Output() private searchType = new EventEmitter();
    @Output() private searchDates = new EventEmitter();
    @Output() private searchAdults = new EventEmitter();
    @Output() private searchChildren = new EventEmitter();
    @Output() private searchMap = new EventEmitter();
    @Output() private submitForm = new EventEmitter();

    public layoutBucketPath = this.appService.layoutBucketPath();
    public destroyInput: Subject<boolean> = new Subject<boolean>();
    public locationGroupOptions: Observable<LocationGroup[]>;
    public panelOpenState1 = false;
    public panelOpenState2 = false;
    public singleCalendar = false;

    public valueLocation: ICityNeighborhoodSearch;
    public searchForm: UntypedFormGroup = this.formBuilder.group({
        locationGroup: '',
    });

    public labelSuggestion: string;
    private selectOnEnter = false;
    public itemIndex = null;
    public groupIndex = 0;
    public selectOnKeyDown: boolean;
    public tabToSelect: boolean;
    public filteredList: any[] = [];

    public calendarDates: Array<string> = [];
    public calendarAvailability: string;
    public startCalendarVisible = false;
    public endCalendarVisible = false;
    public guestCounterVisible = false;

    constructor(
        private formBuilder: UntypedFormBuilder,
        private appService: AppService,
        protected changeDetectorRef: ChangeDetectorRef,
        @Inject(PLATFORM_ID) private platformId: Object
    ) { }
    @HostBinding('class.dropdownVisible') dropdownVisible = false;

    ngOnInit() {
        this.locationGroupOptions = this.searchForm.get('locationGroup').valueChanges
            .pipe(
                debounceTime(300),
                startWith(''),
                map(value => this.filterLocation(value))
            );

        if (isPlatformBrowser(this.platformId)) {
            const style = getComputedStyle(document.body);
            const primaryColor = style.getPropertyValue('--primary-background') + '33';
            document.documentElement.style.setProperty('--background-list-color', primaryColor);
        }
    }

    ngOnDestroy(): void {
        this.destroyInput.unsubscribe();
    }

    public showDropdownList() {
        this.endCalendarVisible = false;
        this.startCalendarVisible = false;
        this.dropdownVisible = true;
    }

    public hideDropdownList() {
        this.selectOnEnter = false;
        this.dropdownVisible = false;
    }

    public showGuestsCounter() {
        this.endCalendarVisible = false;
        this.startCalendarVisible = false;
        (this.guestCounterVisible)
            ? this.guestCounterVisible = false
            : this.guestCounterVisible = true;
    }

    public showCalendar(type: string) {
        if (type === 'start') {
            (this.startCalendarVisible)
                ? this.startCalendarVisible = false
                : this.startCalendarVisible = true;

            (this.endCalendarVisible) = this.endCalendarVisible = false;
            this.calendarAvailability = 'start';
        } else {
            if (this.startDateSelected) {
                (this.endCalendarVisible)
                    ? this.endCalendarVisible = false
                    : this.endCalendarVisible = true;

                (this.startCalendarVisible) = this.startCalendarVisible = false;
                this.calendarAvailability = 'end';
            } else {
                this.startCalendarVisible = true;
                this.calendarAvailability = 'start';
            }
        }
    }

    private filterLocation(value: string): LocationGroup[] {
        const normalizedValue = normalizeSearch(value);

        if (value) {
            return this.locationGroups
                .map(group => ({
                    category: group.category, label: group.label.filter(label =>
                        normalizeSearch(label).includes(normalizedValue))
                }))
                .filter(group => group.label.length > 0);
        }
        return this.locationGroups;
    }

    public onSearchType = (type: string) => {
        this.searchType.emit(type);
        this.closePanel();
    }

    public closePanel = () => {
        this.guestCounterVisible = false;
        this.startCalendarVisible = false;
        this.endCalendarVisible = false;
        if (this.typePanel) {
            this.typePanel.close();
        }
        if (this.availabilityPanel) {
            this.availabilityPanel.close();
        }
    }

    public onSearchAvailability = (availability: string) => {
        this.searchAvailability.emit(availability);
        this.closePanel();
    }

    public onsearchDates(date: Array<string>) {
        this.calendarDates[0] = date[0];
        this.calendarDates[1] = date[1];

        if (!date[1]) {
            this.calendarAvailability = 'end';
            this.startCalendarVisible = false;
            this.endCalendarVisible = true;
        } else {
            this.startCalendarVisible = false;
            this.endCalendarVisible = false;
        }
        this.searchDates.emit(this.calendarDates);
    }

    public translateAvailability = (availability: string) => translateAvailability(availability);

    public convertDateStringToFullDate = (date: string, dateFormat: string) => convertDateStringToFullDate(date, dateFormat);

    public onSearchAdults = (adults: number) => this.searchAdults.emit(adults);

    public onSearchChildren = (adults: number) => this.searchChildren.emit(adults);

    public onSearchMap = (mapSearch: boolean) => this.searchMap.emit(mapSearch);

    public onSubmitForm = () => this.submitForm.emit(this.searchForm.get('locationGroup').value);

    public onSelectedLocation = (value: ICityNeighborhoodSearch) => this.selectedLocation.emit(value);

    public translateFinality(finality: string) {
        return translateFinality(finality);
    }

    public findItemFromSelectValue(selectText: string): any {
        const matchingItems = this.filteredList.filter((item) => item.value === selectText);
        return matchingItems.length ? matchingItems[0] : null;
    }

    public inputElKeyHandler($event: KeyboardEvent) {
        const totalNumItem = this.locationGroups[this.groupIndex].label.length;
        switch ($event.key) {
            case 'Escape':
                this.selectOnEnter = false;
                this.selectOne(undefined, undefined);
                this.hideDropdownList();
                $event.preventDefault();
                break;

            case 'ArrowUp':
                if (0 === totalNumItem) {
                    return;
                }
                this.selectOnEnter = true;
                if (this.itemIndex === 0 && this.groupIndex > 0) {
                    this.groupIndex -= 1;
                    this.itemIndex = this.locationGroups[this.groupIndex].label.length - 1;
                } else {
                    this.itemIndex = (totalNumItem + this.itemIndex - 1) % totalNumItem;
                    if (this.selectOnKeyDown) {
                        this.selectOne(this.locationGroups[this.groupIndex].category,
                            this.locationGroups[this.groupIndex].label[this.itemIndex]);
                    }
                }
                this.scrollToView(this.itemIndex);
                break;

            case 'ArrowDown':
                if (0 === totalNumItem) {
                    return;
                }

                this.selectOnEnter = true;
                this.dropdownVisible = true;
                if (this.itemIndex === totalNumItem - 1 && this.groupIndex + 1 < this.locationGroups.length) {
                    this.groupIndex += 1;
                    this.itemIndex = 0;
                } else {
                    let sum: number = this.itemIndex;
                    sum = (this.itemIndex === null) ? 0 : sum + 1;
                    this.itemIndex = (totalNumItem + sum) % totalNumItem;
                    if (this.selectOnKeyDown) {
                        this.selectOne(this.locationGroups[this.groupIndex].category,
                            this.locationGroups[this.groupIndex].label[this.itemIndex]);
                    }
                }
                this.scrollToView(this.itemIndex);
                break;

            case 'Enter':
                if (isNumber(this.searchForm.get('locationGroup').value)) {
                    this.onSubmitForm();
                }
                if (this.selectOnEnter) {
                    this.selectOne(this.locationGroups[this.groupIndex].category,
                        this.locationGroups[this.groupIndex].label[this.itemIndex]);
                    this.hideDropdownList();
                }
                $event.preventDefault();
                break;

            case 'Tab':
                if (this.tabToSelect) {
                    this.selectOne(this.locationGroups[this.groupIndex].category,
                        this.locationGroups[this.groupIndex].label[this.itemIndex]);
                    this.hideDropdownList();
                }
                break;
        }
    }

    public trackByIndex(index, item) {
        return index;
    }

    public selectOne(category: string, label: string) {
        if (category !== undefined && label !== undefined) {
            this.valueLocation = {
                category,
                label
            };
            this.onSelectedLocation(this.valueLocation);
        }

        this.hideDropdownList();
    }

    public reloadListInDelay(evt: any) {
        const keyword = evt.target.value;
        this.onInput(keyword);
    }

    public onInput(value: any) {
        if (isNumber(value)) {
            this.hideDropdownList();
        }
        if (!this.dropdownVisible) {
            this.dropdownVisible = true;
        }
    }

    public scrollToView(index: number) {
        this.locationGroups.forEach((group, groupIndex) => {
            if (groupIndex < this.groupIndex) {
                index += group.label.length;
            }
        });
        const div = document.getElementById('overflow');
        const li = document.querySelector('.option-item') as HTMLElement;
        const liHeight = li.offsetHeight + 10;
        const scrollTop = div.scrollTop;
        const viewport = scrollTop + div.offsetHeight;
        const scrollOffset = (liHeight * index) + (liHeight * this.groupIndex);
        if (scrollOffset < scrollTop || (scrollOffset + liHeight) > viewport) {
            div.scrollTop = scrollOffset;
        }
    }
}
