import { Injectable } from '@angular/core';
import { AppInitService } from './app-init.service';

@Injectable({
    providedIn: 'root'
})
export class AppService {

    public logoUrl: string = this.defaultLogoUrl();
    public internalLogoUrl: string = this.defaultInternalLogoUrl();
    public footerLogoUrl: string = this.defaultFooterLogoUrl();
    public landUrl: string = this.defaultLandUrl();

    public currentNamespace = '';

    constructor(
        private appInitService: AppInitService
    ) { }

    apiUrl(): string {
        return 'https://api2.imobzi.app/v1';
    }

    namespace(): string {
        return this.appInitService.getDatabaseString();
    }

    clientBucketPath(): string {
        return `https://imobzi.storage.googleapis.com/accounts/${this.namespace()}/public`;
    }

    layoutBucketPath(): string {
        return 'https://imobzi.storage.googleapis.com/image/layout13/';
    }

    defaultLogoUrl(): string {
        return `${this.clientBucketPath()}/logo.png`;
    }

    defaultInternalLogoUrl(): string {
        return `${this.clientBucketPath()}/logo-interno.png`;
    }

    defaultFooterLogoUrl(): string {
        return `${this.clientBucketPath()}/logo-rodape.png`;
    }

    defaultLandUrl(): string {
        return `${this.clientBucketPath()}/land.png`;
    }

}
