import { LocationStrategy } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';
import { Parameters } from 'src/app/interface/parameters';
import { onPhoneClicked } from 'src/app/utils/utils';
import { fabButtonAnimations } from './fab-button.animations';
import { ContactRealEstateDialogComponent } from '../modal/contact-real-estate/contact-real-estate-dialog.component';
import { AppService } from 'src/app/service/app.service';

@Component({
  selector: 'imobzi-fab-button',
  templateUrl: './fab-button.component.html',
  styleUrls: ['./fab-button.component.scss'],
  animations: fabButtonAnimations
})
export class FabButtonComponent {

  @Input() parameters: Parameters;
  fabButtons = [
    {
      iconType: 'fab',
      icon: 'whatsapp'
    },
    {
      iconType: 'far',
      icon: 'envelope'
    }
  ];
  buttons = [];
  fabTogglerState = 'inactive';
  public clientBucketPath: string = this.appService.clientBucketPath();
  public layoutBucketPath: string = this.appService.layoutBucketPath();

  constructor(
    public library: FaIconLibrary,
    private dialog: MatDialog,
    private location: LocationStrategy,
    private appService: AppService
    ) {
    library.addIcons(faWhatsapp, faEnvelope);
  }

  openDialog(value): void {
    (value !== 'whatsapp')
    ? this.dialog.open(ContactRealEstateDialogComponent, {
      panelClass: 'custom-dialog-container'
    })
    : this.onPhoneClicked();
  }

  showItems() {
    this.fabTogglerState = 'active';
    this.buttons = this.fabButtons;
  }

  hideItems() {
    this.fabTogglerState = 'inactive';
    this.buttons = [];
  }

  onToggleFab() {
    this.buttons.length ? this.hideItems() : this.showItems();
  }

  onPhoneClicked() {
    for (const phone of this.parameters.phone) {
      if (phone.type.toLowerCase() === 'whatsapp') {
        return onPhoneClicked(phone, this.dialog, this.parameters.realtor.show_whatsapp_modal);
      }
    }
  }
}
