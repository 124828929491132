import { Component, Input } from '@angular/core';
import { Parameters } from 'src/app/interface/parameters';

@Component({
  selector: 'imobzi-email',
  templateUrl: './email.component.html',
  styleUrls: ['./email.component.scss']
})
export class EmailComponent {

  @Input() parameters: Parameters;

}
