<section id="guests-counter">
    <div class="close-button">
        <mat-icon (click)="onClose()">close</mat-icon>
    </div>
    <div class="counter-container">
        <div class="h3 bold col-xs-5 col-sm-5">
            Adultos<br>
            <span class="h4"> 13 anos ou mais </span>
        </div>
        <div class="counter grid-xs-3 grid-sm-3 grid-md-3 grid-lg-3 grid-xl-3 col-xs-7 col-sm-7">
            <button 
                [class.btn-disabled-1]="!checkAddMoreGuests(-1, adultsSelected)" 
                [class.btn-disabled-2]="checkAddMoreGuests(-1, adultsSelected)" 
                [disabled]="!checkAddMoreGuests(-1, adultsSelected)"
                (click)="counterGuests('adults', -1)"   
                class="btn-disabled-1 btn-icon btn-md-48 gd-xs-1-1 gd-sm-1-1 gd-md-1-1 gd-lg-1-1 gd-xl-1-1" type="button">
                <mat-icon>remove</mat-icon>
            </button>
            <span class="gd-xs-2-2 gd-sm-2-2 gd-md-2-2 gd-lg-2-2 gd-xl-2-2">{{ adultsSelected }}</span>
            <button 
                [class.btn-disabled-1]="!checkAddMoreGuests(1, adultsSelected)" 
                [class.btn-disabled-2]="checkAddMoreGuests(1, adultsSelected)" 
                [disabled]="!checkAddMoreGuests(1, adultsSelected)"
                (click)="counterGuests('adults', 1)" 
                class="btn-disabled-1 btn-icon btn-md-48 gd-xs-3-3 gd-sm-3-3 gd-md-3-3 gd-lg-3-3 gd-xl-3-3" type="button">
                <mat-icon>add</mat-icon>
            </button>
        </div>
    </div>
    <div class="counter-container">
        <div class="h3 bold col-xs-5 col-sm-5">
            Crianças<br>
            <span class="h4"> Idade 2 - 12 </span>
        </div>
        <div class="counter grid-xs-3 grid-sm-3 grid-md-3 grid-lg-3 grid-xl-3 col-xs-7 col-sm-7">
            <button 
                [class.btn-disabled-1]="!checkAddMoreGuests(-1, childrenSelected)" 
                [class.btn-disabled-2]="checkAddMoreGuests(-1, childrenSelected)" 
                [disabled]="!checkAddMoreGuests(-1, childrenSelected)" 
                (click)="counterGuests('children', -1)" 
                class="btn-disabled-1 btn-icon btn-md-48 gd-xs-1-1 gd-sm-1-1 gd-md-1-1 gd-lg-1-1 gd-xl-1-1" type="button">
                <mat-icon>remove</mat-icon>
            </button>
            <span class="gd-xs-2-2 gd-sm-2-2 gd-md-2-2 gd-lg-2-2 gd-xl-2-2">{{ childrenSelected }}</span>
            <button
                [class.btn-disabled-1]="!checkAddMoreGuests(1, childrenSelected)" 
                [class.btn-disabled-2]="checkAddMoreGuests(1, childrenSelected)" 
                [disabled]="!checkAddMoreGuests(1, childrenSelected)" 
                (click)="counterGuests('children', 1)" 
                class="btn-disabled-1 btn-icon btn-md-48 gd-xs-3-3 gd-sm-3-3 gd-md-3-3 gd-lg-3-3 gd-xl-3-3" type="button">
                <mat-icon>add</mat-icon>
            </button>
        </div>
    </div>
    <hr>
    <div class="options-counter">
        <span (click)="clearGuests()" class="bold clear">{{ 'Limpar' | uppercase }}</span>
        <button (click)="applyChanges()" class="bold btn btn-md btn-disabled-2 col-xs-6 col-sm-6">{{ 'Aplicar' | uppercase }}</button>
    </div>
</section>