import { Directive, ElementRef, OnInit } from '@angular/core';

@Directive({
    selector: '[imobziImgSize]'
})
export class ImgSizeDirective implements OnInit {

    originalWidth = this.elementoRef.nativeElement.naturalWidth;
    originalHeight = this.elementoRef.nativeElement.naturalHeight;

    constructor(private elementoRef: ElementRef) { }

    ngOnInit() {
        this.getImgSize();
    }

    getImgSize() {
        this.originalWidth = this.elementoRef.nativeElement.naturalWidth;
        this.originalHeight = this.elementoRef.nativeElement.naturalHeight;

        (this.originalWidth > this.originalHeight)
            ? this.elementoRef.nativeElement.style.setProperty('--object-fit', 'fill')
            : this.elementoRef.nativeElement.style.setProperty('--object-fit', 'contain');
    }
}
