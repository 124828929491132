import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

@Component({
    selector: 'imobzi-contact-broker-modal',
    templateUrl: './contact-broker-modal.component.html',
    styleUrls: ['./contact-broker-modal.component.scss']
})
export class ContactBrokerModalComponent implements OnInit {

    public stepVisit: boolean;
    private breakpoint: any;
    public isSmallScreen: boolean;
    public dates: Array<string>;

    constructor(
        public breakpointObserver: BreakpointObserver,
        public dialogRef: MatDialogRef<ContactBrokerModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) { }

    ngOnInit() {
        this.isPhone();
        this.stepVisit = this.data.stepVisit;
        if (this.data.dates) {
            (this.data.dates.length === 2)
                ? this.dates = this.data.dates
                : this.dates = this.data.dates[0];
        }
    }

    onStepNext(value: boolean) {
        this.stepVisit = value;
    }

    closeDialog(value: boolean): void {
        this.dialogRef.close();
    }

    isPhone() {
        this.breakpoint = this.breakpointObserver.observe(['(max-width: 1026px)']);
        this.breakpoint.subscribe(result => {
            this.isSmallScreen = result.matches;
        });
    }



}
