import { Injectable } from '@angular/core';
import {
    UntypedFormArray,
    UntypedFormBuilder,
    UntypedFormGroup,
    Validators
} from '@angular/forms';
import { FileUploader } from 'ng2-file-upload';
import { Observable } from 'rxjs';
import { SiteContact } from '../interface/contact';
import { IPropertyAdvert } from '../interface/propertyadvert';
import { AppService } from './app.service';
import { RequestService } from './request.service';

@Injectable({
    providedIn: 'root'
})
export class FormService {

    activeStepIndex: number;
    currentFormContent: Array<any>;
    formData: any;
    formFields: Array<Array<string>>;
    masterFormFields: Array<string>;
    masterForm: Array<UntypedFormGroup>;
    stepItems: Array<any>;

    constructor(
        private readonly formBuilder: UntypedFormBuilder,
        private appService: AppService,
        private requestService: RequestService,
    ) { }

    initForm(formContent: any) {
        this.activeStepIndex = 0;
        this.masterForm = [];
        this.currentFormContent = [];
        this.formFields = [];
        this.stepItems = formContent;

        this.stepItems.forEach((data, index) => {
            this.currentFormContent.push(this.stepItems[index].data);
            this.formFields.push(Object.keys(this.currentFormContent[index]));
            this.masterForm.push(this.buildForm(this.currentFormContent[index]));
        });
    }

    buildForm(currentFormContent: any): UntypedFormGroup {
        const formDetails = Object.keys(currentFormContent).reduce(
            (obj, key) => {
                (currentFormContent[key].value)
                    ? obj[key] = [currentFormContent[key].value, this.getValidators(currentFormContent[key])]
                    : obj[key] = ['', this.getValidators(currentFormContent[key])];
                return obj;
            },
            {}
        );

        return this.formBuilder.group(formDetails);
    }

    getValidators(formField: any): Validators {
        const fieldValidators = Object.keys(formField.validations).map(validator => {
            if (validator === 'required' || validator === 'email') {
                return Validators[validator];
            } else {
                return Validators[validator](formField.validations[validator]);
            }
        });
        return fieldValidators;
    }

    getValidationMessage(formIndex: number, formFieldName: string): string {
        const formErrors = this.masterForm[formIndex].get(formFieldName).errors as UntypedFormArray;
        const errorMessages = this.currentFormContent[formIndex][formFieldName].errors;
        if (formErrors) {
            return errorMessages[Object.keys(formErrors)[0]];
        } else {
            return '';
        }
    }

    getSelectValue(index: number, field: string, value: string) {
        this.masterForm[index].patchValue({ [field]: value });
    }

    goToStep(step: string): number {
        return this.activeStepIndex = step === 'prev' ? this.activeStepIndex - 1 : this.activeStepIndex + 1;
    }

    setFormPreview(): string[] {
        this.formData = this.masterForm.reduce(
            (masterForm, currentForm) => ({ ...masterForm, ...currentForm.value }),
            {}
        );
        return this.masterFormFields = Object.keys(this.formData);
    }

    contact(contact: SiteContact): Observable<boolean> {
        const formData: FormData = new FormData();

        for (const contactKey in contact) {
            if (contact[contactKey]) {
                if (contactKey === 'profile') {
                    for (const contactProfileKey in contact.profile) {
                        if (contact.profile[contactProfileKey]) {
                            formData.append(contactProfileKey, contact.profile[contactProfileKey]);
                        }
                    }
                } else {
                    formData.append(contactKey, contact[contactKey]);
                }
            }
        }
        return new Observable<boolean>(observer => {
            this.requestService.postForm(`${this.appService.apiUrl()}/${this.appService.namespace()}/site/form-contact`, formData).subscribe(
                () => observer.next(true),
                error => {
                    observer.error(error);
                    observer.complete();
                },
                () => {
                    observer.complete();
                }
            );
        });
    }

    propertyAdvert(propertyAdvert: IPropertyAdvert, propertyPhotos: FileUploader): Observable<boolean> {
        const formData: FormData = new FormData();
        for (const propertyAdvertKey in propertyAdvert) {
            if (propertyAdvert[propertyAdvertKey]) {
                formData.append(propertyAdvertKey, propertyAdvert[propertyAdvertKey]);
            }
        }
        return new Observable<boolean>(observer => {
            this.requestService.postForm<{ db_key: string, code: string }>(
                `${this.appService.apiUrl()}/${this.appService.namespace()}/site/form-property`, formData).subscribe(
                    response => {
                        propertyPhotos.setOptions({ url: `${this.appService.apiUrl()}/${this.appService.namespace()}/site/form-property/${response.code}/photos` });
                        propertyPhotos.onBeforeUploadItem = (item) => {
                            item.withCredentials = false;
                        };
                        if (propertyPhotos.queue.length > 0) {
                            propertyPhotos.uploadAll();
                            propertyPhotos.onCompleteAll = () => {
                                observer.next(true);
                                observer.complete();
                            };
                        } else {
                            observer.next(true);
                            observer.complete();
                        }
                    },
                    error => {
                        observer.error(error);
                        observer.complete();
                    }
                );
        });
    }

    sendCustomForm(customFormData: SiteContact, customFormFiles?: FileUploader): Observable<string> {
        const formData: FormData = new FormData();
        for (const customer of Object.keys(customFormData)) {
            formData.append(customer, customFormData[customer]);
            // for (const value of Object.keys(customFormData[customer])) {
            //     if (customFormData[customer][value]) {
            //         formData.append(value, customFormData[customer][value]);
            //     }
            // }
        }
        if (customFormFiles) {
            for (const fileItem of Object.keys(customFormFiles.queue)) {
                formData.append('attachments', customFormFiles.queue[fileItem]._file, customFormFiles.queue[fileItem]._file.name);
            }
        }
        return new Observable<string>(observer => {
            this.requestService.postForm(`${this.appService.apiUrl()}/${this.appService.namespace()}/site/custom-form`, formData).subscribe(
                () => observer.next('form_sent'),
                error => observer.error(error),
                () => observer.complete()
            );
        });
    }

}
