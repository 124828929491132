import { isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, ChangeDetectorRef, EventEmitter, Inject, Input, OnInit, Output, PLATFORM_ID, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatExpansionPanel, MAT_EXPANSION_PANEL_DEFAULT_OPTIONS } from '@angular/material/expansion';
import { FileUploader } from 'ng2-file-upload';
import { Property } from 'src/app/interface/property';
import { IPropertyAdvert } from 'src/app/interface/propertyadvert';
import { AppService } from 'src/app/service/app.service';
import { FormService } from 'src/app/service/form.service';
import { convertDateStringToFullDate } from 'src/app/utils/utils';

@Component({
    selector: 'imobzi-step-form',
    templateUrl: './step-form.component.html',
    styleUrls: ['./step-form.component.scss'],
    providers: [
        {
            provide: MAT_EXPANSION_PANEL_DEFAULT_OPTIONS,
            useValue: {
                expandedHeight: '48px',
                collapsedHeight: '48px',
            }
        }
    ]
})
export class StepFormComponent implements OnInit {

    @ViewChild('mapanel') mapanel: MatExpansionPanel;
    @Input() formContent: any;
    @Input() buttonClass: any;
    @Input() spinnerStep: number;
    @Input() buttonTitle: string;
    @Input() loading: boolean;
    @Input() property: Property;
    @Input() dates: Array<string>;
    @Output() readonly selectEmitter: EventEmitter<{ field: string, value: string, masterForm: UntypedFormGroup }> = new EventEmitter<{ field: string, value: string, masterForm: UntypedFormGroup }>();
    @Output() readonly formSubmit: EventEmitter<any> = new EventEmitter<any>();
    @Output() readonly formUploader: EventEmitter<any> = new EventEmitter<any>();
    @Output() readonly step: EventEmitter<any> = new EventEmitter<any>();
    @Output() private exportDates: EventEmitter<any> = new EventEmitter<string[]>();
    public clientBucketPath: string = this.appService.clientBucketPath();
    public layoutBucketPath: string = this.appService.layoutBucketPath();

    public formData: IPropertyAdvert;
    public panelOpenState = false;
    public activeStepIndex: number;
    public currentFormContent: Array<any>;
    public formFields: Array<Array<string>>;
    public masterFormFields: Array<string>;
    public masterForm: Array<UntypedFormGroup>;
    public stepItems: Array<any>;
    public singleCalendar = true;
    public calendarDates: Array<string> = [];
    public calendarDatesExtensive = [];
    public calendarVisible = '';

    public uploader: FileUploader = new FileUploader({
        maxFileSize: 251658240,
        queueLimit: 50,
        method: 'POST',
        allowedFileType: ['image'],
        url: ''
    });

    constructor(
        private formService: FormService,
        private appService: AppService,
        private httpClient: HttpClient,
        private changeDetectorRef: ChangeDetectorRef,
        @Inject(PLATFORM_ID) private platformId: object,
    ) { }

    ngOnInit() {
        this.formService.initForm(this.formContent);
        this.activeStepIndex = this.formService.activeStepIndex;
        this.masterForm = this.formService.masterForm;
        this.currentFormContent = this.formService.currentFormContent;
        this.formFields = this.formService.formFields;
        this.stepItems = this.formService.stepItems;
        this.masterFormFields = this.formService.masterFormFields;
        if (this.dates) {
            this.onChangedDate(this.dates);
        }
    }

    public emitSelectValue(index: number, field: string, value: string, emit: boolean) {
        if (emit) {
            this.selectEmitter.emit({ field, value, masterForm: this.masterForm[index] });
        }
    }

    public getSelectValue(index: number, field: string, value: string) {
        return this.formService.getSelectValue(index, field, value);
    }

    public getValidationMessage(formIndex: number, formFieldName: string): string {
        return this.formService.getValidationMessage(formIndex, formFieldName);
    }

    public goToStep(step: string) {
        if (step === 'next') {
            if (this.stepItems.length <= 1 || this.activeStepIndex === this.stepItems.length - 1) {
                this.masterFormFields = this.formService.setFormPreview();
            } else {
                this.activeStepIndex = this.formService.goToStep(step);
                this.masterFormFields = this.formService.setFormPreview();
                this.onStep();
            }
        } else {
            this.activeStepIndex = this.formService.goToStep(step);
            this.masterFormFields = this.formService.setFormPreview();
            this.onStep();
        }
    }

    public openUploader(): void {
        if (isPlatformBrowser(this.platformId)) {
            document.getElementById('uploader').click();
        }
    }

    private onStep() {
        this.spinnerStep = this.activeStepIndex + 1;
        this.step.emit(this.spinnerStep);
    }

    getAddressByZipCode(zipCode: string) {
        if (zipCode) {
            this.httpClient.get('https://viacep.com.br/ws/' + zipCode + '/json').subscribe(
                address => {
                    if (!address['erro']) {
                        this.getSelectValue(1, 'address', address['logradouro']);
                        this.getSelectValue(1, 'neighborhood', address['bairro']);
                        this.getSelectValue(1, 'city', address['localidade']);
                        this.getSelectValue(1, 'state', address['uf']);
                    }
                },
            );
        }
    }

    onChangeValue(event) {
        const inputValue = event.target;
        if (inputValue.id === 'zipcode') {
            this.getAddressByZipCode(inputValue.value);
        }
    }

    showCalendar(calendarAvailability: string) {
        this.calendarVisible = calendarAvailability;
    }

    onChangedDate(dates: Array<string>) {
        this.calendarDates = dates;
        const start = this.convertDateStringToFullDate(this.calendarDates[0], 'extensiveDate');
        const end = this.convertDateStringToFullDate(this.calendarDates[1], 'extensiveDate');

        this.calendarDatesExtensive = [];
        this.calendarDatesExtensive.push(start);
        this.calendarDatesExtensive.push(end);
        this.calendarVisible = '';
        this.changeDetectorRef.detectChanges();
        this.exportDates.emit(this.calendarDates);
    }

    closeCalendar() {
        this.calendarVisible = '';
    }

    public onFormSubmit(): void {
        if (this.uploader) {
            this.formUploader.emit(this.uploader);
        }
        this.formSubmit.emit(this.formService.formData);
    }

    private closePanel() {
        this.mapanel.close();
    }

    public trackByFn(index: number): number {
        return index;
    }

    public convertDateStringToFullDate(date: string, dateFormat: string) {
        if (date) {
            return convertDateStringToFullDate(date, dateFormat);
        } else {
            return '';
        }
    }
}
