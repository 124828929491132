import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'imobzi-financing-simulator-dialog',
    templateUrl: './financing-simulator-dialog.component.html',
    styleUrls: ['./financing-simulator-dialog.component.scss']
})
export class FinancingSimulatorDialogComponent {

    constructor(
        public dialogRef: MatDialogRef<FinancingSimulatorDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) { }

    closeDialog(value: string) {
        if (value === 'close') {
            this.dialogRef.close();
        }
    }
}
