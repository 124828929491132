<div class="contact-phone-container" *ngIf="parameters">
    <ng-container *ngFor="let phone of parameters.phone; let index = index; let last = last">
        <a class="contact-phone" (click)="onPhoneClicked(phone)"
            target="_blank"
            [title]="phone.number" aria-label="Telefones"
            rel="noopener">
            <ng-container *ngIf="phone.type && phone.type.toLowerCase().indexOf('whatsapp') >= 0">
                <fa-icon class="phone-icon" [icon]="['fab', 'whatsapp']"></fa-icon>
            </ng-container>
            {{phone.number}}
        </a>
    </ng-container>
</div>
