import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Property } from 'src/app/interface/property';
import { AppService } from 'src/app/service/app.service';

@Component({
  selector: 'imobzi-step-first',
  templateUrl: './step-first.component.html',
  styleUrls: ['./step-first.component.scss']
})
export class StepFirstComponent implements OnInit {

  @Input() property: Property;
  @Input() stepVisit: boolean;
  @Input() msgContactSuccess: string;
  @Input() formType: string;
  @Input() intervalDays: number;
  @Output() btnAction = new EventEmitter();

  title: string;
  subtitle = ``;

  displaySuccess: boolean;

  constructor(
    private appService: AppService
  ) { }

  ngOnInit() {
    (this.formType === 'reserve' || (this.property.vacation_rental && this.formType !== 'schedule_visit'))
      ? this.title = 'Reservar'
      : this.title = 'Agendar uma Visita';
  }

  btnChanged(value: boolean) {
    this.btnAction.emit(value);
  }

  public imgErrorHandler(event) {
    event.target.onerror = null;
    event.target.src = `${this.appService.layoutBucketPath()}image-not-found.png`;
  }

}
