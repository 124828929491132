import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Parameters, SiteConfiguration } from 'src/app/interface/parameters';
import { AppService } from 'src/app/service/app.service';
import { PropertyService } from 'src/app/service/property.service';

@Component({
    selector: 'imobzi-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy, OnChanges {

    @Input() sidenav: MatSidenav;
    @Input() parameters: Parameters;
    @Input() calledFromHome: boolean;
    @Input() siteConfiguration: SiteConfiguration;
    public backgroundType: string;
    public logoUrl: string;
    public internalLogoUrl: string;
    public searchButtons: string;
    public darkMode: boolean;
    private breakpoint: any;
    public isSmallScreen: boolean;
    public clientBucketPath: string = this.appService.clientBucketPath();
    public layoutBucketPath: string = this.appService.layoutBucketPath();

    constructor(
        public appService: AppService,
        private breakpointObserver: BreakpointObserver,
        private propertyService: PropertyService
    ) { }

    ngOnInit() {
        this.getBreakpoint();
        if (this.siteConfiguration) {
            this.setHeaderConfiguration();
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.siteConfiguration && this.siteConfiguration) {
            this.setHeaderConfiguration();
        }
    }

    ngOnDestroy(): void {
        this.breakpoint.unsubscribe();
    }

    private getBreakpoint() {
        this.breakpoint = this.breakpointObserver.observe([
            Breakpoints.Small,
            Breakpoints.XSmall
        ]).subscribe(result => {
            this.isSmallScreen = result.matches;
        });
    }

    public removeScroll() {
        this.propertyService.scroll = false;
    }

    public close() {
        this.sidenav.toggle();
    }

    private setHeaderConfiguration() {
        this.backgroundType = this.siteConfiguration.theme.header_color;
        this.logoUrl = this.siteConfiguration.logo;
        this.internalLogoUrl = this.siteConfiguration.internal_logo;
        this.searchButtons = this.siteConfiguration.search_type;
        this.darkMode = this.siteConfiguration.dark_mode;
    }
}
