import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'imobzi-button-close-modal',
  templateUrl: './button-close-modal.component.html',
  styleUrls: ['./button-close-modal.component.scss'],
  animations: [
    trigger('fadeInOut', [
      state('void', style({
        opacity: 0
      })),
      transition('void <=> *', animate(2000)),
    ]),
  ]
})
export class ButtonCloseModalComponent {

  @Output() closeChange = new EventEmitter();

  closeChanged(value: string) {
    this.closeChange.emit(value);
  }
}
