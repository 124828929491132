import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class RequestService {

    constructor(
        private httpClient: HttpClient,
        private router: Router
    ) { }

    httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };

    get<T>(url: string, params: string = ''): Observable<T> {
        return new Observable<T>(observer => {
            this.httpClient.get<T>(url + '?' + params).subscribe(
                response => {
                    observer.next(response);
                    observer.complete();
                },
                error => {
                    if (error && error.status === 402) {
                        this.router.navigate(['suspended']);
                    }
                    (error.error)
                        ? console.error(`An error occurred: ${error.error.message}`)
                        : console.error(`Backend returned code ${error.status}, body was: ${error.error.message}`);

                    observer.error(error);
                    observer.complete();
                },
                () => observer.complete()
            );
        });
    }

    post<T>(url: string, params: string = ''): Observable<T> {
        return new Observable<T>(observer => {
            this.httpClient.post<T>(url, params, this.httpOptions).subscribe(
                response => observer.next(response),
                error => {
                    observer.error(error);
                    observer.complete();
                },
                () => observer.complete());
        });
    }

    postForm<T>(url: string, params: FormData): Observable<T> {
        return new Observable<T>(observer => {
            this.httpClient.post<T>(url, params).subscribe(
                response => observer.next(response),
                error => {
                    observer.error(error);
                    observer.complete();
                },
                () => observer.complete()
            );
        });
    }
}
