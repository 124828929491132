<header id="main-header" class="site-header"
  [class.header-border]="!calledFromHome"
  [class.header-relative]="searchButtons === 'banner'"
  [style.--background-color]="backgroundType">
  <section id="grid-menu" [class.container]="!isSmallScreen" class="grid-xs-12 grid-sm-12 grid-md-12 grid-lg-12 grid-xl-12">

    <ng-container *ngIf="!siteConfiguration; else header">
      <div class="logo-content shinny gd-xs-1-9 gd-sm-1-5 gd-md-1-4 gd-lg-1-3 gd-xl-1-2"></div>
      <div class="grid-xs-12-12 grid-sm-5 grid-md-5 grid-lg-7 grid-xl-5 gd-xs-11-12 gd-sm-6-12 gd-md-6-12 gd-lg-8-12 gd-xl-8-12">
        <a class="button-load shinny imobzi-area-cliente btn btn-md gd-xs-3-4 gd-sm-3-4 gd-md-3-4 gd-lg-4-6 gd-xl-3-4 col-xs-9 col-sm-12 col-md-10 col-lg-9-12 col-xl-9"></a>
        <button class="menu-load shinny menu btn-icon gd-xs-12-12 gd-sm-5-5 gd-md-5-5 gd-lg-7-7 gd-xl-5-5" type="button"></button>
      </div>
    </ng-container>

    <ng-template #header>
      <a routerLink="/" (click)="removeScroll()" class="gd-xs-1-9 gd-sm-1-5 gd-md-1-4 gd-lg-1-3 gd-xl-1-2" aria-label="Logo">
        <img *ngIf="calledFromHome"
          [src]="logoUrl" alt="Logotipo de {{parameters?.company_name}}">
        <img *ngIf="!calledFromHome"
          [src]="internalLogoUrl" alt="Logotipo de {{parameters?.company_name}}">
      </a>

      <div *ngIf="parameters"
        class="grid-xs-12-12 grid-sm-5 grid-md-5 grid-lg-7 grid-xl-5 gd-xs-11-12 gd-sm-6-12 gd-md-6-12 gd-lg-8-12 gd-xl-8-12">
        <imobzi-custom-button
          *ngIf="parameters.site_home_button.active && parameters.site_home_button.button_type !== 'property_filter'"
          class="imobzi-area-cliente btn btn-md gd-xs-1-2 gd-sm-1-2 gd-md-1-2 gd-lg-1-3 gd-xl-1-2 col-xs-9 col-sm-12 col-md-10 col-lg-9-12 col-xl-9"
          [buttonHome]="parameters.site_home_button"
          [class.btn-light-1]="((backgroundType === '#ffffff00' || backgroundType !== '#ffffff') && searchButtons !== 'banner')"
          [class.btn-disabled-2]="(backgroundType !== '#ffffff00' && backgroundType === '#ffffff') || (backgroundType === '#ffffff00' && searchButtons === 'banner') || backgroundType === '#ffffff00' && !calledFromHome">
        </imobzi-custom-button>

        <imobzi-area-cliente *ngIf="parameters.client_area"
          class="imobzi-area-cliente btn btn-md gd-xs-3-4 gd-sm-3-4 gd-md-3-4 gd-lg-4-6 gd-xl-3-4 col-xs-9 col-sm-12 col-md-10 col-lg-9-12 col-xl-9"
          [class.btn-light-1]="((backgroundType === '#ffffff00' || backgroundType !== '#ffffff') && searchButtons !== 'banner')"
          [class.btn-disabled-2]="(backgroundType !== '#ffffff00' && backgroundType === '#ffffff') || (backgroundType === '#ffffff00' && searchButtons === 'banner') || backgroundType === '#ffffff00' && !calledFromHome">
        </imobzi-area-cliente>

        <a routerLink="/anuncie-seu-imovel" *ngIf="!parameters.client_area"
          class="bold imobzi-area-cliente btn btn-md gd-xs-3-4 gd-sm-3-4 gd-md-3-4 gd-lg-4-6 gd-xl-3-4 col-xs-9 col-sm-12 col-md-10 col-lg-9-12 col-xl-9"
          [class.btn-light-1]="((backgroundType === '#ffffff00' || backgroundType !== '#ffffff') && searchButtons !== 'banner')"
          [class.btn-disabled-2]="(backgroundType !== '#ffffff00' && backgroundType === '#ffffff') || (backgroundType === '#ffffff00' && searchButtons === 'banner') || backgroundType === '#ffffff00' && !calledFromHome">
          {{ 'Anuncie seu imóvel' | uppercase }}
        </a>

        <button class="menu btn-icon gd-xs-12-12 gd-sm-5-5 gd-md-5-5 gd-lg-7-7 gd-xl-5-5"
          [class.btn-light-1]="calledFromHome || (backgroundType !== '#ffffff00' && backgroundType === '#ffffff') || (backgroundType === '#ffffff00' && searchButtons === 'banner')"
          [class.btn-primary-1]="!calledFromHome && ((backgroundType === '#ffffff00' || backgroundType !== '#ffffff') && searchButtons !== 'banner')"
          type="button" aria-label="Button Menu" (click)="close()">
          <mat-icon class="icon-inverted material-icons-round" aria-label="Menu">notes</mat-icon>
        </button>
      </div>
    </ng-template>

  </section>
</header>

<imobzi-search-bar
  [parameters]="parameters" *ngIf="calledFromHome && siteConfiguration"
  [clientBucketPath]="clientBucketPath"
  [siteConfiguration]="siteConfiguration">
</imobzi-search-bar>
