<section id="calendar-mobile">
    <div class="calendar-container" *ngIf="calendarsList">

        <mat-card class="calendar" *ngFor="let calendarItem of calendarsList; let index = index">
            <h3 fxLayout="row" fxLayoutAlign="center center" class="title-mobile"> {{months[calendarItem.month]}} {{calendarItem.year}}</h3>

            <mat-card-content>
                <table>
                    <tbody id="calendar-mobile-{{index + 1}}"></tbody>
                </table>
            </mat-card-content>
        </mat-card>

        <div class="add-calendars" (click)="addCalendar()"><mat-icon>add</mat-icon></div>

        <div class="calendar-footer">
            <div class="container">
                <span (click)="clearDates()" class="bold clear col-xs-6 col-sm-6">{{ 'Limpar' | uppercase }}</span>
                <button (click)="onSearchDates()" [class.btn-disabled-1]="!selectedDates[0] || !selectedDates[1]" class="bold btn btn-md btn-disabled-2 col-xs-6 col-sm-6">{{ 'Aplicar' | uppercase }}</button>
            </div>
        </div>
    </div>
</section>
