<imobzi-section-title
    [title]="title"
    [subtitle]="subtitle">
</imobzi-section-title>

<div class="property-container">
    <img class="img-rounded" [src]="property.cover_photo.url" (error)="imgErrorHandler($event)"
        alt="Ver mais detalhes de {{property.property_type}} em {{property.neighborhood}} - {{property.city}}/{{property.state}}">

    <div class="property-content">
        <p class="property-city">{{property.city}}</p>
        <h2 class="property-neighborhood">{{property.neighborhood}}</h2>
        <ng-container *ngIf="title === 'Agendar uma Visita' else vacationRentalProperty">
            <h4 class="property-description">
                {{property.property_type }}
                {{(property.tags.indexOf('sale') !== -1)? 'à Venda': ''}}{{(property.tags.length === 2)? ' ou ': ''}}
                {{(property.tags.indexOf('rent') !== -1)? 'para Locação': ''}}
                {{(property.tags.indexOf('vacation_rental') !== -1)? 'para Temporada': ''}}
                <ng-container *ngIf="property.useful_area > 0 else area">
                    , {{ property.useful_area }} {{property.measure_type | lowercase}}
                </ng-container>
                <ng-template #area>
                    <ng-container *ngIf="property.area > 0 else lotArea">, {{ property.area }} {{ property.measure_type | lowercase }}</ng-container>
                </ng-template>
                <ng-template #lotArea>
                    <ng-container *ngIf="property.lot_area > 0">, {{ property.lot_area }} {{ property.measure_type | lowercase }}</ng-container>
                </ng-template>
            </h4>
            <h4 class="highlight-value">
                <ng-container *ngIf="property.site_publish_price else consult">
                    <ng-container *ngIf="property.sale_value > 0 else rental">
                        {{ property.sale_value | currency:'BRL' : 'symbol' : '1.0-0' }}
                    </ng-container>
                    <ng-template #rental>
                        <ng-container *ngIf="property.rental_value > 0">{{ property.rental_value | currency:'BRL' : 'symbol' : '1.0-0' }}</ng-container>
                    </ng-template>
                </ng-container>
                <ng-template #consult>
                    <b>Consulte!</b>
                </ng-template>
            </h4>
        </ng-container>
        <ng-template #vacationRentalProperty>
            <h4 class="property-description">
                {{property.property_type}}
                {{(property.vacation_rental_guests >= 1)? 'para ' + property.vacation_rental_guests : ''}}
                <ng-container *ngIf="property.vacation_rental_guests >= 1">
                    {{ (property.vacation_rental_guests >= 2)? 'hóspedes': 'hóspede'}}
                </ng-container>
            </h4>
            <h4 class="highlight-value">
                <ng-container *ngIf="property.site_publish_price else consult">
                    {{ property.rental_value | currency:'BRL' : 'symbol' : '1.0-0' }}
                    <ng-container *ngIf="intervalDays >= 1">
                        x {{(intervalDays >= 2) ? intervalDays + ' noites' : intervalDays + ' noite'}}
                        = {{ intervalDays * property.rental_value | currency:'BRL' : 'symbol' : '1.0-0' }} / total
                    </ng-container>
                </ng-container>
                <ng-template #consult>
                    <b>Consulte!</b>
                </ng-template>
            </h4>
        </ng-template>

    </div>
</div>

<button
    class="col-xs-8 col-sm-8 col-md-8 col-lg-8 col-xl-8"
    *ngIf="!displaySuccess && !msgContactSuccess"
    [class.btn]="!stepVisit"
    [class.btn-secondary-1]="!stepVisit"
    [class.btn-md]="!stepVisit"
    [class.btn-icon]="stepVisit"
    [class.btn-light-1]="stepVisit"
    [class.btn-lg-64]="stepVisit"
    (click)="btnChanged(stepVisit = !stepVisit)">
    <span *ngIf="!stepVisit">
        {{ (formType === 'reserve' || (property.vacation_rental && formType !== 'schedule_visit')) ? 'Reservar' : 'Agendar uma Visita' | uppercase}}
    </span>
    <mat-icon
        class="link-light material-icons-round"
        aria-label="Previous"
        *ngIf="stepVisit">
        chevron_left
    </mat-icon>
</button>

<h2 class="h2 color-light" *ngIf="msgContactSuccess">{{msgContactSuccess}}</h2>
