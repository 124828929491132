import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Property } from 'src/app/interface/property';

@Component({
  selector: 'imobzi-schedule-visit-mobile',
  templateUrl: './schedule-visit-mobile.component.html',
  styleUrls: ['./schedule-visit-mobile.component.scss']
})
export class ScheduleVisitMobileComponent implements OnInit {

  @Input() data: Property;
  @Input() formType: string;
  @Input() dates: Array<string>;
  @Output() closeDialog = new EventEmitter();

  title = ``;
  subtitle = `Estamos felizes em atendê-lo. Por favor escolha:`;

  displaySuccess: boolean;
  msgSuccessTitle = `<h3 class="display-h3 text-center">A sua reserva foi <br> efetuada com sucesso!<h3>`;
  msgSuccessSubtitle = `<h2 class="text-center normal">Aguarde nosso contato em 24 horas. <br> Após contatá-lo, enviaremos <br> a forma de pagamento.</h2>`;

  btnSuccessActive = true;
  btnSuccessTitle = `Fechar`;

  msgContactSuccess: string;

  constructor() { }

  ngOnInit() {
    if (this.formType === 'reserve') {
      this.msgSuccessTitle = `<h3 class="display-h3 text-center">A sua reserva foi <br> efetuada com sucesso!<h3>`;
      this.msgSuccessSubtitle = `<h2 class="text-center normal">Aguarde nosso contato em 24 horas. <br> Após contatá-lo, enviaremos <br> a forma de pagamento.</h2>`;
    } else {
      this.msgSuccessTitle = `<h3 class="display-h3 text-center">Visita agendada. <br> Aguarde confirmação! <h3>`;
      this.msgSuccessSubtitle = `<h2 class="text-center normal"> Nas próximas horas, um de nossos corretores <br> irá confirmar com você o melhor <br> horário e ponto de encontro.</h2>`;
    }
  }

  onDisplaySuccess(value: boolean) {
    this.displaySuccess = value;
  }

  onBtnChanged(value: boolean) {
    this.closeDialog.emit(value);
  }

  onMsgContactSuccess(value: string) {
    this.msgContactSuccess = value;
  }
}
