<mat-sidenav-container class="sidenav-container">
    <mat-sidenav #sidenav class="sidenav" fixedInViewport [attr.role]="'dialog'" [mode]="'over'" [opened]="false"
        position="end">
        <button class="btn-icon color-light btn-light-1 sidenav-close" type="button" aria-label="Toggle sidenav"
            (click)="sidenav.toggle()">
            <mat-icon aria-label="Close menu">close</mat-icon>
        </button>
        <imobzi-menu fxLayout="column" fxLayoutAlign="space-between flex-start" [parameters]="parameters"
            [sidenav]="sidenav"></imobzi-menu>
    </mat-sidenav>
    <mat-sidenav-content>
        <imobzi-header *ngIf="((calledFromDetail && !calledFromContent) || (calledFromContent && !landingPage))" [parameters]="parameters" [sidenav]="sidenav"
            [calledFromHome]="calledFromHome"
            [siteConfiguration]="siteConfiguration">
        </imobzi-header>
        <!-- <button *ngIf="calledFromHome" class="button-demo shadow absolute btn btn-whatsapp-1 btn-md"
            aria-label="Demo Modelos" (click)="layoutType()">
            <mat-icon class="margin-right-10" aria-label="demo">settings</mat-icon>
            MODELO {{ config }}
        </button> -->
        <router-outlet></router-outlet>
        <imobzi-footer *ngIf="((calledFromDetail && !calledFromContent) || (calledFromContent && !landingPage))"
            [parameters]="parameters"
            [siteConfiguration]="siteConfiguration">
        </imobzi-footer>
    </mat-sidenav-content>
</mat-sidenav-container>
