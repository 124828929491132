<footer id="footer" class="container site-footer">

  <section class="first-row" fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-between center"
    *ngIf="parameters; else footerLoad">
    <div id="grid" class="container">
      <a routerLink="/" class="company-logo" aria-label="Logo">
        <img *ngIf="siteConfiguration" [src]="siteConfiguration.footer_logo" alt="Logotipo de {{parameters?.company_name}}">
      </a>
      <div class="company-description" *ngIf="parameters.description">
        <h4 class="description">{{parameters.description | slice:0:200}}</h4>
        <a routerLink="/quem-somos">Saiba mais</a>
      </div>

      <imobzi-address class="company-address" [parameters]="parameters"></imobzi-address>
      <div class="h4 legal-identification company-address">{{parameters.company_name}} - CRECI: {{parameters.creci}}
      </div>
      <imobzi-menu-list [menuType]="'footer-menu'" [parameters]="parameters"
        class="company-menu"></imobzi-menu-list>
      <imobzi-contact class="company-contact" [parameters]="parameters"></imobzi-contact>

      <div class="company-certificates">
        <a [href]="googleCertificate" rel="noopener" target="_blank">
          <img class="logo-google" [src]="layoutBucketPath + 'logo-google.png'" alt="Logo Certificado Google">
        </a>
        <div class="image-ssl">
          <a [href]="sslCertificate" rel="noopener" target="_blank">
            <img class="logo-ssl" [src]="layoutBucketPath + 'logo-ssl.png'" alt="Logo Certificado SSL">
          </a>
        </div>
      </div>
    </div>
  </section>

  <ng-template #footerLoad>
    <section class="first-row" fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-between center">
      <div id="grid" class="container footer-load">
        <div class="shinny company-logo company-logo-load"></div>
        <div class="company-description">
          <p class="shinny info__text info__text_one title"></p>
          <p class="shinny info__text info__text_two"></p>
          <p class="shinny info__text info__text_three"></p>
        </div>
        <div class="company-menu company-menu-load">
          <div class="company-menu-item" *ngFor="let item of [1, 2, 3]">
            <p class="shinny info__text info__text_one title"></p>
            <p class="shinny info__text info__text_two"></p>
            <p class="shinny info__text info__text_two"></p>
            <p class="shinny info__text info__text_two"></p>
          </div>
        </div>
        <div class="company-contact">
          <p class="shinny info__text info__text_one title"></p>
          <p class="shinny info__text info__text_one title"></p>
          <p class="shinny info__text info__text_three"></p>
          <p class="shinny info__text info__text_three"></p>
          <div class="social-media-load-container">
            <div class="shinny social-media-load" *ngFor="let item of [1, 2, 3, 4]"></div>
          </div>
        </div>
      </div>
    </section>
  </ng-template>


  <section class="second-row" fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="center center">
    <a id="logo_marca"
      href="https://www.imobzi.com/?utm_source=template&utm_medium=logo-footer&utm_campaign=footer-template&utm_content=logo-footer"
      target="_blank" rel="noopener" aria-label="Imobzi">
      <p class="logo-imobzi">Tecnologia</p>
      <img class="logo-imobzi" [src]="layoutBucketPath + 'logotipo-Imobzi.png'" alt="Logotipo Quickfast">
    </a>
  </section>

  <imobzi-fab-button [parameters]="parameters"></imobzi-fab-button>
</footer>
<imobzi-privacy-policy></imobzi-privacy-policy>
