<imobzi-step-first
    class="info-container col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6"
    [class.visit-success]="msgContactSuccess"
    [msgContactSuccess]="msgContactSuccess"
    [property]="data"
    [stepVisit]="stepVisit"
    [formType]="formType"
    [intervalDays]="intervalDays"
    (btnAction)="onScheduleVisitChanged($event)">
</imobzi-step-first>

<imobzi-step-second
    class="info-container col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6"
    *ngIf="!displaySuccess && stepVisit"
    [property]="data"
    [formType]="formType"
    [dates]="dates"
    (btnSuccessActive)="onBtnSuccessActive($event)"
    (displaySuccess)="onDisplaySuccess($event)"
    (msgContactSuccess)="onMsgContactSuccess($event)"
    (exportIntervalDays)="onIntervalDays($event)">
</imobzi-step-second>

<imobzi-success-form
    fxLayout="column" fxLayoutAlign="center center"
    class="info-container col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6"
    *ngIf="displaySuccess"
    [msgSuccessTitle]="msgSuccessTitle"
    [msgSuccessSubtitle]="msgSuccessSubtitle"
    [btnSuccessActive]="btnSuccessActive"
    [btnSuccessTitle]="btnSuccessTitle"
    (btnAction)="onBtnChanged($event)">
</imobzi-success-form>
