
<mat-dialog-content>
    <div class="dialog-header">
        <imobzi-button-close-modal id="closeFilter" (closeChange)='closeModal($event)'></imobzi-button-close-modal>
        <table>
            <tbody>
                <tr>
                    <td *ngFor="let day of days">{{day}}</td>
                </tr>
            </tbody>
        </table>
    </div>

    <section id="calendar-dialog" class="mat-dialog-content">
        <imobzi-calendar-mobile
            *ngIf="!isSmallScreen"
            [isSmallScreen]="isSmallScreen"
            [startDateSelected]="startDateSelected"
            [endDateSelected]="endDateSelected"
            [propertyCalendar]="propertyCalendar"
            (searchDates)="onSearchDates($event)">
        </imobzi-calendar-mobile>
    </section>

</mat-dialog-content>