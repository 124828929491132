import { MatDialog } from '@angular/material/dialog';
import { CurrencyPipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import { DialogWhatsapp, Parameters, Phone } from 'src/app/interface/parameters';
import { Property } from 'src/app/interface/property';
import { Realtor } from 'src/app/interface/realtor';
import { onPhoneClicked } from 'src/app/utils/utils';

@Component({
    selector: 'imobzi-phone-numbers',
    templateUrl: './phone-numbers.component.html',
    styleUrls: ['./phone-numbers.component.scss'],
    providers: [CurrencyPipe]
})
export class PhoneNumbersComponent implements OnInit {

    @Input() parameters: Parameters;
    @Input() property: Property;
    @Input() showWhatsapp = false;
    @Input() showPhone = false;
    public listingBroker: Realtor;

    public numberWhatsapp: Phone;
    public numberPhone: Phone;
    public phoneTypes: string[] = ['phone', 'whatsapp'];

    constructor(
        public library: FaIconLibrary,
        private currencyPipe: CurrencyPipe,
        private dialog: MatDialog
    ) {
        library.addIcons(faWhatsapp, faPhone);
    }

    ngOnInit(): void {
        this.listingBroker = this.property.listing_broker;
        this.getPhoneNumber();
    }

    findPhone({ type }: Phone, searchPhone: string) {
        return type.toLowerCase() === searchPhone;
    }

    getPhoneNumber() {
        this.phoneTypes.forEach((phoneType) => {
            const searchPhone = (phoneType !== 'phone') ? phoneType : 'mobile';
            if (this.parameters.realtor[`show_realtor_${phoneType}`]) {
                if (this.listingBroker && this.listingBroker.phones) {
                    const phoneFound = this.listingBroker.phones.find(phone => this.findPhone(phone, searchPhone));
                    if (phoneFound) {
                        (phoneType === 'whatsapp') ? this.numberWhatsapp = phoneFound : this.numberPhone = phoneFound;
                    }
                } else if (this.parameters.realtor[`show_real_estate_${phoneType}_if_not_realtor`] && this.parameters.phone) {
                    const realEstatePhone = this.parameters.phone.find(phone => this.findPhone(phone, searchPhone));
                    (phoneType === 'whatsapp') ? this.numberWhatsapp = realEstatePhone : this.numberPhone = realEstatePhone;
                }
            }
            else if (this.parameters.realtor[`show_real_estate_${phoneType}`] && this.parameters.phone) {
                const realEstatePhone = this.parameters.phone.find(phone => this.findPhone(phone, searchPhone));
                (phoneType === 'whatsapp') ? this.numberWhatsapp = realEstatePhone : this.numberPhone = realEstatePhone;
            }
        });
    }

    getWhatsappMessage() {
        return `Olá, Gostaria de ter mais informações do imóvel de Cód. ${this.property.code}; \n` +
            `${this.property.property_type} ${(this.property.tags.indexOf('sale') !== -1) ? `à Venda de ` +
                `${this.currencyPipe.transform(Number(this.property.sale_value), 'BRL', 'symbol', '1.0-0')}` : ''} ${(this.property.tags.length === 2) ? ' ou ' : ''}` +
            `${(this.property.tags.indexOf('rent') !== -1) ? `para Locação de ` +
                `${this.currencyPipe.transform(Number(this.property.rental_value), 'BRL', 'symbol', '1.0-0')}` : ''}` +
            `${(this.property.tags.indexOf('vacation_rental') !== -1) ? `para Temporada de ` +
                `${this.currencyPipe.transform(Number(this.property.rental_value), 'BRL', 'symbol', '1.0-0')}` : ''}` +
            `em ${this.property.neighborhood}, que encontrei no seu site. \nAguardo seu contato. Obrigado!`;
    }

    onPhoneClicked(phone: Phone) {
        if (phone.type.toLocaleLowerCase() === 'whatsapp') {
            const dialogData: DialogWhatsapp = {
                message: this.getWhatsappMessage(),
                formMessage: `Contato via Whatsapp ${(this.listingBroker && this.listingBroker.name) ? `- Corretor: ${this.listingBroker.name}` : ''}\n${(this.property.code)
                    ? 'Imóvel de código: ' + this.property.code : ''}\n*Certifique-se que a opção “Ignorar a distribuição, caso o imóvel tenha um corretor captador”, está habilitada. <a href="https://help.imobzi.com/pt-br/article/como-fazer-o-rodizio-e-redistribuicao-de-leads-de-forma-automatica-es0i2k/">Saiba mais</a>`,
                code: this.property.code
            };
            return onPhoneClicked(phone, this.dialog, this.parameters.realtor.show_whatsapp_modal, phone.type, dialogData);
        }
        return onPhoneClicked(phone, this.dialog, this.parameters.realtor.show_whatsapp_modal);
    }
}
