<section id="success_page" fxLayout="column" fxLayoutAlign="space-between center">
    <div class="success-checkmark">
        <div class="check-icon">
            <span class="icon-line line-tip"></span>
            <span class="icon-line line-long"></span>
            <div class="icon-circle"></div>
            <div class="icon-fix"></div>
        </div>
    </div>
    <div [innerHtml]="( msgSuccessTitle | sanitizedHtml)"></div>
    <div [innerHtml]="( msgSuccessSubtitle | sanitizedHtml)"></div>

    <button 
    class="btn btn-primary-1 btn-md col-xs-2 col-sm-2 col-md-2 col-lg-2 col-xl-2"
    type="button"
    *ngIf="btnSuccessActive" 
    (click)="btnChanged(true)">
        {{ btnSuccessTitle || "Retornar para tela inicial" }}
    </button>
</section>