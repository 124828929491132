<imobzi-button-close-modal (closeChange)="closeDialog($event)"></imobzi-button-close-modal>

<section id="whatsapp-dialog">
    <imobzi-section-title 
        [title]="title"
        [subtitle]="''">
    </imobzi-section-title>

    <imobzi-step-form  
        [formContent]="formContent" 
        [buttonTitle]="buttonTitle"
        [buttonClass]="buttonClass"
        [loading]="loading"
        (formSubmit)="onFormSubmit($event)">
    </imobzi-step-form>
</section>