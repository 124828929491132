import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { AppService } from './app.service';
import { RequestService } from './request.service';
import { SiteConfiguration } from 'src/app/interface/parameters';

@Injectable({
    providedIn: 'root'
})
export class HomeService {

    private siteHomeConfigurationSubject = new Subject<SiteConfiguration>();
    public siteHomeConfiguration$ = this.siteHomeConfigurationSubject.asObservable();
    public siteHomeConfiguration: SiteConfiguration;

    constructor(
        private appService: AppService,
        private requestService: RequestService
    ) {
        this.siteHomeConfiguration$.subscribe(siteHomeConfiguration => {
            this.siteHomeConfiguration = siteHomeConfiguration;
        });
    }

    public getSiteConfiguration(): Observable<SiteConfiguration> {
        return new Observable<SiteConfiguration>(observer => {
            this.requestService.get<SiteConfiguration>(
                `${this.appService.apiUrl()}/${this.appService.namespace()}/site2/site-pages-configuration`).subscribe(
                    response => {
                        this.siteHomeConfigurationSubject.next(response);
                        observer.next(response);
                    },
                    error => observer.error('Erro: ' + error)
                );
        });
    }
}
