import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';

@Component({
    selector: 'imobzi-area-cliente',
    templateUrl: './area-cliente.component.html',
    styleUrls: ['./area-cliente.component.scss']
})
export class AreaClienteComponent implements OnInit {

    hostname: string;
    url: string;

    constructor(
        @Inject(PLATFORM_ID) private platformId: Object
    ) { }

    ngOnInit() {
        if (isPlatformBrowser(this.platformId)) {
            this.hostname = document.location.hostname.replace('www.', '');
            this.url = `https://app.${this.hostname}`;
        }
    }
}
